var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-app", [
    _c("div", { staticClass: "page-container d-md-flex mt-4" }, [
      _c(
        "div",
        {
          staticClass:
            "inner-container d-flex justify-content-center gap-lg-2 align-items-center flex-xl-row",
        },
        [
          _c(
            "div",
            { staticClass: "info-pane col-12 col-md-5" },
            [_c("EddLeftInfo", { attrs: { textDisplay: _vm.textDisplay } })],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "login-pane col-12 col-md-7 d-flex flex-column" },
            [
              _c("div", { staticClass: "d-md-none text-center" }, [
                _c("img", {
                  staticClass: "treasury-logo",
                  attrs: { alt: "Treasury Logo", src: _vm.treasuryLogo },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "h4 mt-4" }, [
                  _vm._v("\n            Access account with "),
                  _c("img", {
                    staticClass: "socure-logo",
                    attrs: { alt: "Socure Logo", src: _vm.socureLogo },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "page-content" }, [
                _c("div", { staticClass: "m-4 d-none d-md-flex" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center flex-wrap justify-content-start",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass:
                            "btn-socure btn-socure-primary d-none d-md-block",
                          on: { click: _vm.backToIrs },
                        },
                        [_vm._v("Back to IRS.gov")]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm.approved
                  ? _c("div", { staticClass: "m-4" }, [
                      _c(
                        "h2",
                        {
                          staticClass:
                            "fw-light brand-text-primary text-center",
                        },
                        [_vm._v("Thanks for completing your")]
                      ),
                      _vm._v(" "),
                      _c(
                        "h1",
                        {
                          staticClass: "fw-bold brand-text-primary text-center",
                        },
                        [_vm._v("Identity Verification Appointment")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-3 text-center" }, [
                          _c("i", {
                            staticClass:
                              "far fa-circle-check fa-4x text-success",
                            attrs: { "aria-hidden": "true" },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-8 text-start" }, [
                          _vm._v(
                            "\n                Please check your email for further information on your registration completion\n              "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mx-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "my-3 btn-socure large btn-socure-secondary",
                              on: { click: _vm.backToIrs },
                            },
                            [_vm._v("Back to IRS.gov")]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _c("div", { staticClass: "m-4" }, [
                      _c("h1", { staticClass: "fw-bold brand-text-primary" }, [
                        _vm._v("Unable to Verify Your Identity"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-start" }, [
                        _vm._v(
                          "We cannot complete your account registration at this time."
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mx-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "my-3 btn-socure large btn-socure-secondary",
                              on: { click: _vm.backToIrs },
                            },
                            [_vm._v("Back to IRS.gov")]
                          ),
                        ],
                        1
                      ),
                    ]),
              ]),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-app", [
    _c(
      "div",
      { staticClass: "page-container d-md-flex mt-4" },
      [
        _c(
          "div",
          {
            staticClass:
              "inner-container d-flex justify-content-center gap-lg-2 align-items-center flex-xl-row",
          },
          [
            _c(
              "div",
              { staticClass: "info-pane col-12 col-md-5" },
              [_c("EddLeftInfo", { attrs: { textDisplay: _vm.textDisplay } })],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "login-pane col-12 col-md-7 d-flex flex-column" },
              [
                _c("div", { staticClass: "d-md-none text-center" }, [
                  _c("img", {
                    staticClass: "treasury-logo",
                    attrs: { alt: "Treasury Logo", src: _vm.treasuryLogo },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "h4 mt-4" }, [
                    _vm._v("\n            Access account with "),
                    _c("img", {
                      staticClass: "socure-logo",
                      attrs: { alt: "Socure Logo", src: _vm.socureLogo },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "page-content" }, [
                  _c("div", { staticClass: "m-4 d-none d-md-flex" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center flex-wrap justify-content-start",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "btn-socure btn-socure-primary d-none d-md-block",
                            on: { click: _vm.backToIrs },
                          },
                          [_vm._v("Back to IRS.gov")]
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.step === 1
                    ? _c("div", { staticClass: "m-4" }, [
                        _c(
                          "h1",
                          { staticClass: "fw-bold brand-text-primary" },
                          [_vm._v("Schedule ID Verification Appointment")]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "text-start" }, [
                          _vm._v(
                            "Please select a day and time for an ID Verification appointment appointment."
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-start d-flex flex-column gap-3 py-5",
                          },
                          [
                            _c("div", { staticClass: "appointment-label" }, [
                              _c("span", { staticClass: "text-danger" }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(" "),
                              _c("b", [_vm._v("Date for Appointment")]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c("date-field", {
                                  attrs: {
                                    min_date: _vm.today,
                                    max_date: _vm.addDaysToDate(_vm.today, 7),
                                    disallowWeekends: true,
                                  },
                                  on: {
                                    "date-field-selected": _vm.setSelectedDate,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-start d-flex flex-column gap-3 py-5",
                          },
                          [
                            _c("div", { staticClass: "appointment-label" }, [
                              _c("span", { staticClass: "text-danger" }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(" "),
                              _c("b", [_vm._v("Appointment Time of Day")]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "time-div" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "time-btn font-primary",
                                    class:
                                      _vm.selectedTimeOfDay === "AM"
                                        ? "time-btn-selected"
                                        : "",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setSelectedTimeOfDay("AM")
                                      },
                                    },
                                  },
                                  [_vm._v("AM")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "time-btn font-primary",
                                    class:
                                      _vm.selectedTimeOfDay === "PM"
                                        ? "time-btn-selected"
                                        : "",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setSelectedTimeOfDay("PM")
                                      },
                                    },
                                  },
                                  [_vm._v("PM")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-start d-flex flex-column gap-3 py-5",
                          },
                          [
                            _c("div", { staticClass: "appointment-label" }, [
                              _c("span", { staticClass: "text-danger" }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(" "),
                              _c("b", [_vm._v("Appointment Time")]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  "Times are scheduled in Eastern Standard Time(EST)"
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "time-div" },
                              _vm._l(_vm.timeOptions, function (time, index) {
                                return _c(
                                  "v-btn",
                                  {
                                    key: index,
                                    staticClass: "time-btn font-primary",
                                    class:
                                      time === _vm.selectedTime
                                        ? "time-btn-selected"
                                        : "",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setSelectedTime(time)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(time))]
                                )
                              }),
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "text-center" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass:
                                  "my-3 btn-socure large btn-socure-secondary",
                                attrs: {
                                  disabled:
                                    _vm.selectedTime === "" ||
                                    _vm.selectedDate === null,
                                },
                                on: { click: _vm.setMeetingDate },
                              },
                              [_vm._v("Schedule Appointment")]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                staticClass: "btn-socure btn-socure-primary",
                                on: { click: _vm.goToLanding },
                              },
                              [_vm._v("Back a Step")]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.step === 2
                    ? _c(
                        "div",
                        { staticClass: "m-4" },
                        [
                          _c(
                            "h1",
                            { staticClass: "fw-bold brand-text-primary" },
                            [_vm._v("Schedule ID Verification Appointment")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-3" }, [
                              _c("i", {
                                staticClass:
                                  "far fa-circle-check fa-4x text-success",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-8 text-start" }, [
                              _vm._v(
                                "You have successfully scheduled an ID verification appointment for:"
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "text-start py-2" }, [
                            _c("div", [
                              _c(
                                "h2",
                                { staticClass: "fw-bold brand-text-primary" },
                                [_vm._v(_vm._s(_vm.appointmentTime))]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "\n                We will email an invite with details to "
                              ),
                              _c("strong", [
                                _vm._v(" " + _vm._s(_vm.email) + " "),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "my-3 btn-socure large btn-socure-secondary",
                              on: {
                                click: function ($event) {
                                  _vm.step = 3
                                },
                              },
                            },
                            [_vm._v("Manage Appointment")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.step === 3
                    ? _c(
                        "div",
                        { staticClass: "m-4" },
                        [
                          _c(
                            "h1",
                            { staticClass: "fw-bold brand-text-primary" },
                            [_vm._v("Manage ID Verification Appointment")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "text-start" }, [
                            _vm._v(
                              "You have ID verification appointment scheduled on:"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "text-start py-2" }, [
                            _c("div", [
                              _vm.loading
                                ? _c(
                                    "h2",
                                    {
                                      staticClass: "fw-bold brand-text-primary",
                                    },
                                    [_vm._v("Loading ...")]
                                  )
                                : _c(
                                    "h2",
                                    {
                                      staticClass: "fw-bold brand-text-primary",
                                    },
                                    [_vm._v(_vm._s(_vm.appointmentTime))]
                                  ),
                            ]),
                            _vm._v(" "),
                            !_vm.loading
                              ? _c("div", [
                                  _vm._v(
                                    "\n                For the account with : "
                                  ),
                                  _c("strong", [
                                    _vm._v(" " + _vm._s(_vm.email) + " "),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "my-3 btn-socure large btn-socure-secondary",
                              on: {
                                click: function ($event) {
                                  _vm.step = 1
                                },
                              },
                            },
                            [_vm._v("Reschedule Appointment")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "my-3 btn-socure large btn-socure-secondary",
                              on: {
                                click: function ($event) {
                                  _vm.showCancelDialog = true
                                },
                              },
                            },
                            [_vm._v("Cancel Appointment")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "my-3 btn-socure large btn-socure-secondary",
                              on: { click: _vm.goMeeting },
                            },
                            [_vm._v("Join a Waiting Room Now")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.step === 4
                    ? _c(
                        "div",
                        { staticClass: "m-4" },
                        [
                          _c(
                            "h1",
                            { staticClass: "fw-bold brand-text-primary" },
                            [_vm._v("Canceled Appointment")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "text-start" }, [
                            _vm._v("You have "),
                            _c("b", [_vm._v("canceled")]),
                            _vm._v(
                              " your ID verification appointment scheduled on:"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "text-start py-2" }, [
                            _c("div", [
                              _c(
                                "h2",
                                { staticClass: "fw-bold brand-text-primary" },
                                [_vm._v(_vm._s(_vm.appointmentTime))]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "This wil impact your ability to create an account for:"
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "my-3 btn-socure large btn-socure-secondary",
                              on: {
                                click: function ($event) {
                                  _vm.step = 1
                                },
                              },
                            },
                            [_vm._v("Reschedule Appointment")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "my-3 btn-socure large btn-socure-secondary",
                              on: { click: _vm.goMeeting },
                            },
                            [_vm._v("Join a Waiting Room Now")]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn-socure btn-socure-primary d-none d-md-block",
                              on: { click: _vm.backToIrs },
                            },
                            [_vm._v("Back to IRS.gov")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "v-dialog",
          {
            attrs: { width: "500px" },
            model: {
              value: _vm.showCancelDialog,
              callback: function ($$v) {
                _vm.showCancelDialog = $$v
              },
              expression: "showCancelDialog",
            },
          },
          [
            _c("div", { staticClass: "dialog-header" }, [
              _c("div", { staticClass: "fs-3" }, [
                _vm._v("Confirm Cancelation"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dialog-text" }, [
              _c("div", [
                _vm._v(
                  "\n          Please confirm that you would like to cancel the appointment for "
                ),
                _c("b"),
                _vm._v(" on "),
                _c("b", [_vm._v(_vm._s(_vm.appointmentTime))]),
                _vm._v(".\n        "),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "dialog-buttons text-center" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "my-3 btn-socure large btn-socure-secondary",
                    on: { click: _vm.cancelAppointment },
                  },
                  [_vm._v("Cancel Appointment")]
                ),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    staticClass: "btn-socure btn-socure-primary",
                    on: {
                      click: function ($event) {
                        _vm.showCancelDialog = false
                      },
                    },
                  },
                  [_vm._v("No, I do not wish to cancel")]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.currentStep != 1
        ? _c("div", { staticClass: "info-box text-center" }, [
            _c("div", { staticClass: "small" }, [
              _vm._v(_vm._s(_vm.$t("property"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "brand-text-primary data-val" }, [
              _vm._v("8099 East Vale Street Sunnyvale, CA 94087"),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-stepper",
        {
          staticClass: "loss-stepper-overflow",
          model: {
            value: _vm.currentStep,
            callback: function ($$v) {
              _vm.currentStep = $$v
            },
            expression: "currentStep",
          },
        },
        [
          _c(
            "v-stepper-items",
            [
              _c(
                "v-form",
                { ref: "lossVerificationForm" },
                [
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "1" } },
                    [
                      _c("loss-verification-step1", {
                        attrs: { step1Obj: _vm.step1Obj },
                        on: {
                          "update:step1Obj": function ($event) {
                            _vm.step1Obj = $event
                          },
                          "update:step1-obj": function ($event) {
                            _vm.step1Obj = $event
                          },
                          isSelfAssesment: _vm.selfAssesment,
                          isPropertyDamage: _vm.propertyDamage,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "2" } },
                    [
                      _c("loss-verification-step2", {
                        attrs: { step2Obj: _vm.step2Obj },
                        on: {
                          "update:step2Obj": function ($event) {
                            _vm.step2Obj = $event
                          },
                          "update:step2-obj": function ($event) {
                            _vm.step2Obj = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "3" } },
                    [
                      _c("loss-verification-step3", {
                        attrs: { step3Obj: _vm.step3Obj },
                        on: {
                          "update:step3Obj": function ($event) {
                            _vm.step3Obj = $event
                          },
                          "update:step3-obj": function ($event) {
                            _vm.step3Obj = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "4" } },
                    [
                      _c("loss-verification-step4", {
                        attrs: { step4Obj: _vm.step4Obj },
                        on: {
                          "update:step4Obj": function ($event) {
                            _vm.step4Obj = $event
                          },
                          "update:step4-obj": function ($event) {
                            _vm.step4Obj = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "5" } },
                    [
                      _c("loss-verification-step5", {
                        attrs: { step5Obj: _vm.step5Obj },
                        on: {
                          "update:step5Obj": function ($event) {
                            _vm.step5Obj = $event
                          },
                          "update:step5-obj": function ($event) {
                            _vm.step5Obj = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "6" } },
                    [
                      _c("loss-verification-step6", {
                        attrs: { step6Obj: _vm.step6Obj },
                        on: {
                          "update:step6Obj": function ($event) {
                            _vm.step6Obj = $event
                          },
                          "update:step6-obj": function ($event) {
                            _vm.step6Obj = $event
                          },
                          autoDamageCount: _vm.getAutoDamageCount,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "7" } },
                    [
                      _c("loss-verification-step7", {
                        attrs: { allData: _vm.allData },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.autoDamageCount, function (n) {
                    return _c(
                      "v-stepper-content",
                      {
                        key: `${n}-content`,
                        attrs: { step: _vm.initialStepCount + n },
                      },
                      [
                        _c("loss-verification-stepCustom", {
                          attrs: {
                            stepCustomObj: _vm.stepCustomObj,
                            autoMobileNumber: n,
                            ref_count: _vm.initialStepCount + n,
                          },
                          on: {
                            "update:stepCustomObj": function ($event) {
                              _vm.stepCustomObj = $event
                            },
                            "update:step-custom-obj": function ($event) {
                              _vm.stepCustomObj = $event
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex justify-content-between pt-2" }, [
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "btn btn-white",
                  on: {
                    click: function ($event) {
                      return _vm.goToBackStep()
                    },
                  },
                },
                [_c("b", [_vm._v(_vm._s(_vm.backButtonLabel))])]
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function ($event) {
                      return _vm.goToNextStep()
                    },
                  },
                },
                [
                  _c("span", { staticClass: "fw-semi-bold" }, [
                    _vm._v(_vm._s(_vm.nextButtonLabel)),
                  ]),
                ]
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <div class="logo-header text-center">
      <img alt="Treasury Logo" :src="treasuryLogo" class="treasury-logo" />
      <div class="h4 mt-4">Access account with <img alt="Socure Logo" :src="socureLogo" class="socure-logo" /></div>
    </div>
    <div class="mt-5 mb-5 row flex-nowrap flex-column align-items-center flex-md-row">
      <div v-if="!isEmail" class="left-pane" v-html="textDisplay"></div>
      <div
        v-else
        role="group"
        aria-label="progress"
        class="stepper-container d-flex align-items-center align-items-xl-baseline justify-content-center flex-xl-column"
      >
        <div class="steps-container">
          <div :class="step === 2 ? 'timeline-bar__dot timeline-dot timeline-dot-1 full' : ''">
            <div v-if="step === 1" class="d-flex align-items-center step-number">
              <div>
                <div class="col-2 circle-border p-0">
                  <div class="step-div">
                    <span class="stepnum step step5 h1">1</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="timeline-bar__connector" :class="step === 2 ? 'line-connected' : ''"></div>
          <div
            class="step-container timeline-step-3 d-flex flex-column align-items-xl-center w-md-100 justify-content-md-center ms-xl-3"
          >
            <span class="step-text h3">Email</span>
            <span v-if="step === 1" class="small step step1"> Please provide the username/email for your account </span>
          </div>
        </div>
        <div class="steps-container">
          <div class="timeline-dot timeline-dot-2" :class="step === 2 ? 'timeline-dot__active' : 'timeline-bar__dot '">
            <div v-if="step === 2" class="d-flex align-items-center step-number">
              <div>
                <div class="col-2 circle-border p-0">
                  <div class="step-div">
                    <span class="stepnum step step5 h1">2</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="step-container timeline-step-5 d-flex flex-column w-md-100 justify-content-md-center ms-xl-3">
            <span class="step-text h3">Email Verification</span>
            <span v-if="step === 2" class="small step step2"> Confirm your email with security code </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      socureLogo: require('../../../img/socure-logo.png'),
      treasuryLogo: require('../../../img/USDOT-Crest-Dark.png'),
      userEmail: '',
    };
  },
  props: {
    textDisplay: '',
    isEmail: { type: Boolean, default: false },
    step: {
      type: Number,
      default: 1,
    },
  },
  methods: {},
};
</script>
<style>
@import '../../../css/stepper.css';
body {
  --primary-font-color: #383838;
  --primary-text-color: #383838;
  --primary-font-family: Bree Serif, Public Sans, Helvetica, Arial, sans-serif;
  --primary: #002e6d;
  --v-primary-base: #296d97;
  --secondary: #fdb71a;
  --v-secondary-base: #fdb71a;
  --tertiary: #66b3ff;
  --light: #f4f8fa;
  --grey: #e9f1f5;
  --text-light: #f0f0f0;
  --success: #1be632;
  width: 100%;
  height: 100%;
  background: #002e6d;
  border-bottom: none;
}
</style>
<style scoped>
.page-container {
  min-height: calc(100vh - 20px);
  justify-content: center !important;
  align-items: flex-end;
  width: 100%;
}
.inner-container {
  max-width: 1400px;
}
.header {
  background-color: #296d97 !important;
}
:deep(.v-toolbar__content) {
  align-items: end;
}
.footer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  min-height: 100px;
  background-color: #222222 !important;
  color: #d6d3d3;
}
.page-content {
  flex: 100;
  background-color: #f4f8fa;
  text-align: center;
  min-height: 800px;
}
.page-hr {
  border-top: 4px solid #f7b63a;
  opacity: 1;
}
.font-primary {
  font-family: Bree Serif;
}
.font-secondary {
  font-family: Public Sans;
}
.edd-btn,
.time-btn {
  text-transform: capitalize;
  border-radius: 4px;
  box-shadow: none !important;
  font-weight: 100 !important;
  font-size: 16px !important;
}

.time-btn {
  background-color: #fff !important;
  border: 4px solid var(--lc-background-color) !important;
  margin: -2px 0;
}
.time-btn-selected {
  background-color: var(--lc-primary) !important;
  border: 3px solid var(--primary) !important;
  color: var(--lc-text-light) !important;
}

.edd-btn-secondary {
  all: unset;
  color: #296d97;
  text-decoration: underline;
  text-decoration-color: #2c759c5f;
  text-decoration-style: dashed;
}
.language-dropdown {
  color: #fff;
}
.edd-logo {
  height: 50px;
  margin-top: 12px;
  margin-left: 12px;
}
.appointment-label {
  margin: -10px 0;
}
.date-input {
  border: 1px solid #b5b4b4;
  width: 85%;
  padding: 0 12px;
  height: 44px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border: 1px solid #b5b4b4;
  border-left-width: 2px;
  color: var(--primary);
  background-color: #e4e4e4;
  height: 44px !important;
  width: 52px !important;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
.date-picker {
  margin-bottom: -14px;
  width: 100%;
}
.ca-logo {
  height: 36px;
}
.w-85 {
  width: 85%;
}
.w-95 {
  width: 95%;
}
.treasury-logo {
  width: 70%;
}
:deep(.v-picker__title.primary) {
  background-color: var(--lc-primary) !important;
}
.btn-socure-primary {
  box-shadow: none;
  border-radius: 0px;
  background: none !important;
}
.dialog-header {
  display: flex;
  background-color: #fafafa;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
}
.dialog-header > div {
  font-weight: 600;
}
.dialog-text {
  display: flex;
  background-color: #fff;
  padding: 8px 16px;
  border-bottom: 1px solid #e8e8e8;
}
.dialog-buttons {
  padding: 10px 16px;
  background-color: #fafafa;
}

.login-pane {
  min-height: 800px;
  margin-left: auto;
}

.left-pane {
  min-height: 500px;
  margin-left: 5rem;
}
@media screen and (min-width: 769px) {
  .page-content {
    text-align: left;
    padding: 2rem;
  }
  .icon-div {
    width: 50%;
  }
  .page-container {
    margin: -12px;
  }
  .time-div {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .info-pane {
    display: none;
  }
  .logo-header {
    display: none;
  }
  .time-div {
    display: contents;
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "logo-header text-center" }, [
      _c("img", {
        staticClass: "treasury-logo",
        attrs: { alt: "Treasury Logo", src: _vm.treasuryLogo },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "h4 mt-4" }, [
        _vm._v("Access account with "),
        _c("img", {
          staticClass: "socure-logo",
          attrs: { alt: "Socure Logo", src: _vm.socureLogo },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "mt-5 mb-5 row flex-nowrap flex-column align-items-center flex-md-row",
      },
      [
        !_vm.isEmail
          ? _c("div", {
              staticClass: "left-pane",
              domProps: { innerHTML: _vm._s(_vm.textDisplay) },
            })
          : _c(
              "div",
              {
                staticClass:
                  "stepper-container d-flex align-items-center align-items-xl-baseline justify-content-center flex-xl-column",
                attrs: { role: "group", "aria-label": "progress" },
              },
              [
                _c("div", { staticClass: "steps-container" }, [
                  _c(
                    "div",
                    {
                      class:
                        _vm.step === 2
                          ? "timeline-bar__dot timeline-dot timeline-dot-1 full"
                          : "",
                    },
                    [
                      _vm.step === 1
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center step-number",
                            },
                            [_vm._m(0)]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "timeline-bar__connector",
                    class: _vm.step === 2 ? "line-connected" : "",
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "step-container timeline-step-3 d-flex flex-column align-items-xl-center w-md-100 justify-content-md-center ms-xl-3",
                    },
                    [
                      _c("span", { staticClass: "step-text h3" }, [
                        _vm._v("Email"),
                      ]),
                      _vm._v(" "),
                      _vm.step === 1
                        ? _c("span", { staticClass: "small step step1" }, [
                            _vm._v(
                              " Please provide the username/email for your account "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "steps-container" }, [
                  _c(
                    "div",
                    {
                      staticClass: "timeline-dot timeline-dot-2",
                      class:
                        _vm.step === 2
                          ? "timeline-dot__active"
                          : "timeline-bar__dot ",
                    },
                    [
                      _vm.step === 2
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center step-number",
                            },
                            [_vm._m(1)]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "step-container timeline-step-5 d-flex flex-column w-md-100 justify-content-md-center ms-xl-3",
                    },
                    [
                      _c("span", { staticClass: "step-text h3" }, [
                        _vm._v("Email Verification"),
                      ]),
                      _vm._v(" "),
                      _vm.step === 2
                        ? _c("span", { staticClass: "small step step2" }, [
                            _vm._v(" Confirm your email with security code "),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]
            ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "col-2 circle-border p-0" }, [
        _c("div", { staticClass: "step-div" }, [
          _c("span", { staticClass: "stepnum step step5 h1" }, [_vm._v("1")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "col-2 circle-border p-0" }, [
        _c("div", { staticClass: "step-div" }, [
          _c("span", { staticClass: "stepnum step step5 h1" }, [_vm._v("2")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-form :ref="ref_id">
    <div class="p-2">
      <span class="text-primary text-capitalize"> {{ $t('automobile')}} {{stepCustomData.autoMobileNumberStr}}</span>
      <v-row>
        <v-col class="col-12 col-md-6">
          <label class="pb-1 mandatory"><small>{{$t('lossverificationVINquestion')}}</small></label>
          <v-radio-group v-model="stepCustomData.isVinNumber" @change="resetValues()" :rules="mandatoryRuleVINRadio" row>
            <v-radio :label="$t('yes')" v-bind:value="true"></v-radio>
            <v-radio :label="$t('no')" v-bind:value="false"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row v-if="stepCustomData.isVinNumber">
        <v-col class="col-12 col-md-6">
          <label class="pb-1 mandatory"><small>{{ $t('vinNumber')}}</small></label>
          <v-text-field v-model="stepCustomData.vinNumber" solo outlined dense :rules="mandatoryRuleVIN"></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="!stepCustomData.isVinNumber">
        <v-col class="col-12 col-md-3">
          <label class="pb-1 mandatory"><small>{{ $t('year')}}</small></label>
           <v-select :items="vehicle_data['years_list']" v-model="stepCustomData.selectedYear" @change="getMakeList()" solo outlined dense :rules="mandatoryRule"></v-select>
        </v-col>
        <v-col class="col-12 col-md-3">
          <label class="pb-1 mandatory"><small>{{ $t('make')}}</small></label>
           <v-select :items="getMakeList()" v-model="stepCustomData.selectedMake" solo outlined dense :rules="mandatoryRule" :disabled="stepCustomData.selectedYear == ''"></v-select>
        </v-col>
        <v-col class="col-12 col-md-3">
          <label class="pb-1 mandatory"><small>{{ $t('model')}}</small></label>
          <v-select :items="getModelList()" v-model="stepCustomData.selectedModel" solo outlined dense :rules="mandatoryRule" :disabled="stepCustomData.selectedMake == ''"></v-select>
        </v-col>
      </v-row>
     
      <v-row>
        <v-col class="col-12 col-md-12">
          <label class="pb-1 mandatory">{{ $t('lossverificationStepCustomQuestion')}}</label>
          <v-radio-group v-model="stepCustomData.damageRepair" row :rules="mandatoryRadioRule">
            <v-radio :label="$t('repair')"  v-bind:value="true"></v-radio>
            <v-radio :label="$t('replacement')"  v-bind:value="false"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>

export default {

  mixins: [],
  components: {
  },
  props: {
    autoMobileNumber: Number,
    ref_count: Number,
    stepCustomObj:Object
  },
  data() {
    return {
      stepCustomData:{
          isVinNumber : true,
          vinNumber:'',
          damageRepair: null,
          selectedYear:'',
          selectedMake:'',
          selectedModel:'',
          ref_num:this.ref_count,
          autoMobileNumberStr:''
      },
      ref_id: '',
      mandatoryRule: [(v) => (v !== undefined && v !== null && v !== '') || this.$t('requiredField')],
      mandatoryRuleVIN:[
        (v) => (v !== undefined && v !== null && v !== '') || this.$t('requiredField'),
        (v) => v.length <= 12 || this.$t('maxChars').replace('{}', 12)],
      

      vehicle_data:{
        years_list:[
          "2022","2021","2020"
        ],
        make:{
          "2022":[
            "Alfa Romeo", "BMW" 
          ],
          "2021":[
             "BMW" ,"Audi","Buick"
          ],
          "2020":[
            "Alfa Romeo","Dodge","Honda"
          ]
        },
        model:{
          "2022":{
            "Alfa Romeo": ["Stelvio", "Giulia"] , 
            "BMW":["i4","iX","M3"] ,
          },
          "2021":{
             "BMW":["i4","iX","M3"] ,
             "Audi":["A4","A5"],
             "Buick":["Enclave", "Encore"]
          },
          "2020":{
            "Alfa Romeo":["4C Spider"],
            "Dodge":["Charger", "Challenger"],
            "Honda":["Accord", "Civic"]
          }
        }
      }
    };
  },
  computed: {
    mandatoryRadioRule() {
      return [
        this.stepCustomData.damageRepair != null || this.$t('requiredField'),
      ];
    },
    mandatoryRuleVINRadio() {
      return [
        this.stepCustomData.isVinNumber != null || this.$t('requiredField'),
      ];
    },
  },
  methods: {
    getMakeList(){
      return this.stepCustomData.selectedYear !== '' ? this.vehicle_data.make[this.stepCustomData.selectedYear]: [];
    },
    getModelList(){
      return this.stepCustomData.selectedMake !== '' ? this.vehicle_data.model[this.stepCustomData.selectedYear][this.stepCustomData.selectedMake]: [];
    },
    resetValues(){
      this.stepCustomData.selectedYear='',
      this.stepCustomData.selectedMake='',
      this.stepCustomData.selectedModel='',
      this.stepCustomData.vinNumber='';
    }
  },
  created() {
    var converter = require('number-to-words');
    this.stepCustomData.autoMobileNumberStr = converter.toWords(this.autoMobileNumber);

    this.ref_id = "step" + this.ref_count + "form";
  },
  watch: {
     stepCustomData: {
        handler() {
           this.$emit('update:stepCustomObj' , this.stepCustomData);
        },
        deep: true,
      },
  },
 
};
</script>

<template>
  <v-form ref="step6form">
    <div class="p-2">
      <div><label class="pb-1">{{$t('lossverificationStep6Question')}}</label> </div>
      <v-row>
        <v-col class="col-12 col-md-6">
          <label class="pb-1 mandatory"><small>{{$t('lossverificationStep6SubQuestion1')}}</small></label>
          <v-radio-group v-model="step6Data.automobileDamage" @change="autoDamageUpdate()" :rules="mandatoryRuleRadio" row>
            <v-radio :label="$t('yes')" v-bind:value="true"></v-radio>
            <v-radio :label="$t('no')" v-bind:value="false"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col class="col-12 col-md-6">
          <label class="pb-1 mandatory"><small>{{$t('lossverificationStep6SubQuestion2')}}</small></label>
          <v-text-field type="number" :disabled="!step6Data.automobileDamage" v-model="step6Data.autoDamageCount" @change="autoDamageCountChange()" :rules="step6Data.automobileDamage ? mandatoryRule : []" solo outlined dense></v-text-field>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>

export default {

  mixins: [],
  components: {
  },
  props: {
     step6Obj: Object,
  },
  data() {
    return {
      step6Data:{
        automobileDamage: null,
        autoDamageCount: '',
      },
      mandatoryRule: [(v) => (v !== undefined && v !== null && v != '') || this.$t('requiredField')],
    };
  },

  computed: {
    mandatoryRuleRadio() {
      return [
        this.step6Data.automobileDamage != null || this.$t('requiredField')
      ];
    }
  },
  methods: {
    autoDamageCountChange() {
      if (this.step6Data.automobileDamage !== null) {
        if (this.step6Data.automobileDamage && (this.step6Data.autoDamageCount != '0' && this.step6Data.autoDamageCount != null)) {
          this.$emit("autoDamageCount", parseInt(this.step6Data.autoDamageCount));
        }
      }
    },
    autoDamageUpdate() {
      this.step6Data.autoDamageCount = '';
      this.$emit("autoDamageCount", 0);
    }
  },
  watch: {
      step6Data: {
        handler() {
          this.$emit('update:step6Obj', this.step6Data);
        },
        deep: true,
      },
  },
};
</script>

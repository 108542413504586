var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-app", [
    _c("div", { staticClass: "page-container d-md-flex mt-4" }, [
      _c(
        "div",
        {
          staticClass:
            "inner-container d-flex justify-content-center gap-lg-2 align-items-center flex-xl-row",
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "info-pane col-12 col-md-5",
            },
            [_c("EddLeftInfo")],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "login-pane col-12 col-md-7 d-flex flex-column" },
            [
              _c("div", { staticClass: "d-md-none text-center" }, [
                _c("img", {
                  staticClass: "treasury-logo",
                  attrs: { alt: "Treasury Logo", src: _vm.treasuryLogo },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "h4 mt-4" }, [
                  _vm._v("\n            Access account with "),
                  _c("img", {
                    staticClass: "socure-logo",
                    attrs: { alt: "Socure Logo", src: _vm.socureLogo },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "page-content",
                },
                [
                  _c(
                    "div",
                    { staticClass: "app d-flex justify-content-center" },
                    [
                      _c(
                        "div",
                        { staticClass: "w-85 d-flex flex-column gap-3" },
                        [
                          _c("div", { staticClass: "fs-3 fw-bold w-85" }, [
                            _vm._v(
                              "Please wait, the meeting is being setup now"
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-center" },
                            [
                              _c("v-progress-circular", {
                                attrs: { indeterminate: "", color: "primary" },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.loading,
                    expression: "!loading",
                  },
                ],
                attrs: { id: "zmmtg-root" },
              }),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
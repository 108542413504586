<template>
  <div>
    <v-dialog v-if="requestDialog && showDialog" v-model="requestDialog" persistent
      :max-width="!sentSuccess ? '880px' : '420px'">
      <div id="borrower-inbox-msg">
        <v-card class="v2-wrapper" v-if="!sentSuccess">
          <div class="sticky-top">
            <div class="d-flex justify-content-end p-3">
              <i type="button" class="fas fa-times-circle fa-lg close-btn"
                @click.prevent="closeDialog(requestDetails)"></i>
            </div>
            <div class="v2-header p-3 p-md-5 py-1">
              <div class="d-flex flex-column justify-content-center align-center gap-2">
                <i aria-hidden="true" class="fa-regular fa-envelope-open-text fa-2x brand-text-primary"></i>
                <span id="msg-type">{{ requestDetails.name }}</span>
                <div class="d-flex align-items-center gap-1 brand-text-primary small p-2">
                  <span>{{ $t('subject') }}: {{ requestDetails.subject }}</span>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <div class="info-box" v-if="requestDetails.entity != null">
                  <span class="small">{{ $t('applicationNumber') }}</span>
                  <span class="brand-text-primary data-val">{{ requestDetails.application_number }}</span>
                </div>
                <div class="info-box" v-if="requestDetails.borrower">
                  <span class="small">{{ $t('borrower') }}</span>
                  <span class="brand-text-primary data-val">{{ requestDetails.borrower }}</span>
                </div>
              </div>
              <v-divider />
            </div>
          </div>
          <component v-on="$listeners" :is="isComponent" :closeInboxDialog="closeInboxDialog"
            :requestDetails="requestDetails" @onSubmit="performBorrowerAction" @unLockMessage="unLockMessage"
            :loan_id="taskId" :successCallback="successCallback" />
        </v-card>
        <v-overlay :value="requestDialogLoading">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-overlay>
      </div>
    </v-dialog>
    <div v-if="!showDialog">
      <div v-if="isDocumentRequest && !fetchLoading">
        <span v-if="allowedToUpload" class="d-block">{{ $t('verifyIdentity') }}</span>
        <span v-if="allowedToUpload" class="mb-3">{{ $t('accountLockText') }}</span>
        <span v-if="!allowedToUpload" class="d-block mb-3">{{ $t('docVerificationPending') }}</span>
        <div class="info-box" style="width: 100%;">
          <div class="zebra-stripes-reverse">
            <div v-for="id_type in allowedIDTypes" :key="id_type.name"
              class="zebra-stripe-row d-flex align-items-center justify-content-between flex-column flex-sm-row">
              <div class="d-flex align-center">
                <i aria-hidden="true" class="fa-regular fa-id-card brand-text-primary me-2"></i>
                <div class="d-flex flex-column">
                  <span class="d-flex flex-column flex-sm-row align-center">
                    {{ id_type.text }}
                  </span>
                  <span v-if="uploadedDocsByType[id_type.name]">{{
                    uploadedDocsByType[id_type.name].uploadedDocName
                  }}</span>
                </div>
              </div>
              <button v-if="!uploadedDocsByType[id_type.name] && allowedToUpload" class="btn btn-white"
                @click.prevent="handleShowUploadDocument(id_type)"><span>{{ $t('uploadDocument') }}</span></button>
              <div v-else>
                <button v-if="allowedToUpload" class="btn btn-white ms-2"
                  @click.prevent="onDocumentDelete(id_type.name)"><span>{{
                    $t('delete')
                  }}</span></button>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-center justify-content-sm-between flex-wrap mt-5">
          <span v-if="displayVerificationSkip && messages.length > 0" class="btn btn-white ms-2"
            @click="incrementSkipCount"><span>{{ $t('skipAndContinue') }}</span></span>
          <button class="btn btn-secondary submit-btn my-3" @click="replyCall"
            :disabled="disableSave || !allowedToUpload">
            Submit Document
          </button>
        </div>
        <div v-if="isUserReadOnly || skippedCount > skippedCountThreshold" class="container container-error">
          <div class="d-flex align-items-center justify-content-center justify-content-sm-between flex-wrap bg-danger">
            <i aria-hidden="true" class="fa-regular fa-exclamation-triangle fa-2x text-white pl-3"></i>
          </div>
          <div class="pt-2 pl-2 pb-2 text-primary fs-6"><span class="fw-bold">Note:</span> You have limited access of the application due to pending document requests.</div>
        </div>
        <div v-else class="container container-error">
          <div class="d-flex align-items-center justify-content-center justify-content-sm-between flex-wrap bg-danger">
            <i aria-hidden="true" class="fa-regular fa-exclamation-triangle fa-2x text-white pl-3"></i>
          </div>
          <div class="pt-2 pl-2 pb-2 text-primary">
            <div class="fs-7"><span class="fw-bold">Note:</span> You may only select &ldquo;Skip and Continue&rdquo; a
              maximum of <span class="fw-bold">{{ skippedCountThreshold }} times</span> before your account
              will have limited access to your application.</div>
            <div class="pt-2 fs-6 fw-bold">Documents due in: {{ daysTillDue }} day(s)</div>
          </div>
        </div>
      </div>
      <div v-else-if="isDocumentRequest && fetchLoading">
        <v-skeleton-loader class="v2-card-light" type="list-item-avatar-three-line@3"></v-skeleton-loader>
      </div>
      <upload-dialog v-if="dialogUpload" key="upload" :docType="selectedDocType" :dialog="dialogUpload" :loan-id="taskId"
        :details="{ thread: currentItem.id }" @doc-uploaded="handleUploadDocument"></upload-dialog>
      <v-dialog v-model="dialogSuccess" max-width="900px">
        <v-card class="p-4">
          <div class="p-2 d-flex flex-column align-center">
            <i aria-hidden="true" class="fa-regular fa-check-circle me-2 fa-3x mb-6" style="color: var(--success)"></i>
            <h1 class="brand-text-primary mt-2 h5">Thank you for uploading your document</h1>
            <p>Our Team will review your ID to verify your account</p>
            <a :href="postInterceptUrl" class="btn btn-secondary mt-3 text-white">Continue</a>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<style scoped>
.submit-btn:disabled {
  background-color: #99A2AA !important;
  border: #99A2AA !important;
}

.container {
  margin-top: 4em;
  display: grid;
  grid-template-columns: 10% 80%;
  border-radius: 5px;
  padding: 0%;
}

.container-info {
  border: 4px solid var(--primary)
}

.container-error {
  border: 4px solid var(--lc-danger)
}
</style>
<script>
import inboxPageMap from '../plugins/inboxPageMap';
import CounterOffer from './CounterOfferMessage.vue';
import LossVerification from './LossVerificationMessage.vue';
import UploadDialog from './UploadDialog.vue';

export default {
  components: {
    LossVerification,
    CounterOffer,
    UploadDialog,
  },
  props: {
    requestDialog: Boolean,
    showDialog: Boolean,
    thread: Object,
    postInterceptUrl: String,
    showVerificationSkip: Boolean,
    messages: Array,
  },
  data() {
    return {
      sentSuccess: false,
      requestDetails: {},
      requestDialogLoading: false,
      fetchLoading: false,
      loadingDialogText: '',
      product_id: null,
      loan: {},
      isComponent: '',
      closeInboxDialog: {},
      isDocumentRequest: false,
      apiErrors: [],
      successCallback: {
        isSuccess: false,
        detail: {},
      },
      uploaded_docs: [],
      doc_type_rule: [(v) => !!v || 'required field'],
      selectedFile: null,
      selectedDocName: null,
      selectedDocType: null,
      dialogUpload: false,
      dialogSuccess: false,
      taskId: '',
      documentAttached: false,
      documentTypes: [],
      allowedToUpload: true,
      skippedCount: 0,
      daysTillDue: 0,
      skippedCountThreshold: 0,
      isUserReadOnly: false,
      displayVerificationSkip: false,
    };
  },
  async created() {
    const docTypesResponse = await axios.get('/api/document-types/?bucket=documentation_request&is_public=true');
    this.displayVerificationSkip = this.showVerificationSkip;
    if (docTypesResponse.data) {
      this.documentTypes = docTypesResponse.data.map((docType) => ({
        text: docType.label,
        name: docType.name,
      }));
    }
  },
  methods:
  {
    incrementSkipCount() {
      this.displayVerificationSkip = false;
      axios.post(`/api/post-login-actions`).then((res) => {
        // If we successfully update the skip count and set `skip_and_continue_pressed` on the request session,
        //  we will redirect the browser to the postInterceptUrl.
        if (res.status === 200) {
          window.location.href = this.postInterceptUrl;
        }
        this.displayVerificationSkip = this.showVerificationSkip;
      }).catch((err) => {
        this.displayVerificationSkip = this.showVerificationSkip;
        const snackObj = {
          err,
          snackMsg: 'There was an error updating the skipped count.',
        };
        this.$emit('snack', snackObj);
      });
    },
    handleShowUploadDocument(type) {
      this.dialogUpload = true;
      this.selectedDocType = type;
    },

    handleUploadDocument(event, action) {
      if (!action) {
        this.uploaded_docs.push({
          name: event.uploaded_doc_name,
          type: event.uploaded_doc_type,
          uploadedDocName: event.name,
          id: event.id,
        });
      }
      this.dialogUpload = false;
      this.selectedDocName = null;
      this.selectedFile = null;
      this.selectedDocType = null;
    },
    closeDialog(item = null) {
      if (!this.sentSuccess) {
        this.$set(this.closeInboxDialog, 'closeConfirmMessage', true);
        this.closeInboxDialog = { ...this.closeInboxDialog };
      } else {
        if (item.id) this.unLockMessage(item.id);
        this.$emit('closemessage');
      }
    },
    lockMessage(id) {
      // eslint-disable-next-line no-undef
      axios.post(`/api/message_center/v1/inbox/${id}/lock`, {}).catch((err) => {
        const snackObj = {
          err,
          snackMsg: this.$t('lockMessageError'),
        };
        this.$emit('snack', snackObj);
      });
    },
    unLockMessage(id) {
      // eslint-disable-next-line no-undef
      axios.post(`/api/message_center/v1/inbox/${id}/unlock`, {}).catch((err) => {
        const snackObj = {
          err,
          snackMsg: this.$t('unlockMessageError'),
        };
        this.$emit('snack', snackObj);
      });
    },
    markAsRead(id) {
      // eslint-disable-next-line no-undef
      axios.post(`/api/message_center/v1/inbox/${id}/mark_as_read`, {}).then((res) => {
        const { unreadMessagesCount } = this.$store.state.me;
        this.$store.commit('setUnreadMessageCount', { unreadMessagesCount: unreadMessagesCount - 1 });
      }).catch((err) => {
        const snackObj = {
          err,
          snackMsg: this.$t('markReadError'),
        };
        this.$emit('snack', snackObj);
      });
    },
    async onMessageReply() {
      if (this.isDocumentRequest) {
        if (this.validateSubmit()) {
          this.replyCall();
        } else {
          const snackObj = {
            snackType: 'error',
            snackMsg: this.$t('uploadAllDocuments'),
          };
          this.$emit('snack', snackObj);
        }
      }
    },
    replyCall() {
      this.requestDialogLoading = true;
      const message = 'Documents Uploaded.';
      // eslint-disable-next-line no-undef
      axios
        .post(`/api/message_center/v1/inbox/${this.requestDetails.id}/reply/`, {
          message,
          is_complete: true,
        })
        .then(
          async (res) => {
            this.closeDialog(this.requestDetails);
            this.requestDialogLoading = false;
            this.dialogSuccess = true;
          },
          (err) => {
            this.requestDialogLoading = false;
            let snackMsg = this.$t('sendMessageError');
            if (err?.response?.data && typeof (err.response.data) === 'string') snackMsg = err.response.data;
            const snackObj = {
              err,
              snackMsg,
            };
            this.$emit('snack', snackObj);
          },
        );
    },
    performBorrowerAction(data) {
      this.requestDialogLoading = true;
      let message = '';
      let { detail } = data;
      let selectedOffer = {};
      if (this.isDocumentRequest) {
        data.action = 'accept';
        this.dialogSuccess = true;
        this.documentAttached = true;
      }
      if (data.action === 'accept') {
        if (this.isComponent === 'CounterOffer') {
          message = `${this.requestDetails.borrower} accepted the following counter offer: ${data.detail.selectedCounterOffer.product.name} - $${data.detail.selectedCounterOffer.loan_amount} for ${data.detail.selectedCounterOffer.loan_tenure} months at ${data.detail.selectedCounterOffer.rate}% interest rate.`;
          detail = data.detail.selectedCounterOffer.id;
          selectedOffer = data.detail.selectedCounterOffer;
        }
      }
      const thread = this.currentItem.id;
      if (((this.taskId !== undefined) && (this.taskId != null) && (this.taskId !== ''))) {
        // eslint-disable-next-line no-undef
        axios
          .post(`/api/tasks/${this.taskId}/execute`, {
            action: data.action,
            detail,
            thread,
            message,
          })
          .then((res) => {
            this.sentSuccess = true;
            this.requestDialogLoading = false;
            this.loadingDialogText = '';
            this.$set(this.successCallback, 'isSuccess', true);
            this.$set(this.successCallback, 'detail', selectedOffer);
            this.successCallback = { ...this.successCallback };
          })
          .catch((err) => {
            this.apiErrors = [];
            let snackMsg = this.$t('genericError');
            this.apiErrors = [];
            if (err?.response?.data) {
              const errors = (Array.isArray(err.response.data)) ? err.response.data : [err.response.data];
              errors.forEach((e) => {
                if (typeof (e) === 'string') {
                  this.apiErrors.push(`${e}`);
                } else {
                  this.getApiErrors(e, '');
                }
              });
            }
            if (this.apiErrors.length) {
              snackMsg = this.apiErrors.join(',');
            }
            this.requestDialogLoading = false;
            this.loadingDialogText = '';
            const snackObj = {
              err,
              snackMsg,
            };
            this.$emit('snack', snackObj);
          });
      } else this.$emit('snack', { snackMsg: this.$t('genericError') });
    },

    onDocumentDelete(type) {
      const doc = this.uploaded_docs.find((document) => document.type === type);
      const url = `/api/tasks/${this.taskId}/documents/${doc.id}`;
      if (url) {
        // eslint-disable-next-line no-undef
        axios.delete(url)
          .then((res) => {
            this.uploaded_docs = this.uploaded_docs.filter((file) => file.type !== type);
            const snackObj = {
              snackType: 'success',
              snackMsg: this.$t('docDeleteSuccess'),
            };
            this.$bubble('snack', snackObj);
            this.cardLoading = false;
            // this.validateSubmit();
          })
          .catch((err) => {
            this.cardLoading = false;
            const snackObj = {
              err,
              snackMsg: this.$t('docDeleteFailure'),
            };
            this.$bubble('snack', snackObj);
          });
      }
    },
    async axiosFetch(url, params = {}) {
      // eslint-disable-next-line no-undef
      const resp = await axios.get(url, {
        params,
      });
      return resp.data;
    },
    async openRequest(item) {
      this.currentItem = item;
      this.sentSuccess = false;
      this.requestDialogLoading = true;
      this.fetchLoading = true;
      this.loadingDialogText = this.$t('loadingMessage');

      const response = await Promise.all([
        this.axiosFetch('/api/document-types'),
        this.axiosFetch(`/api/message_center/v1/inbox/${item.id}`),
      ]);

      // eslint-disable-next-line prefer-destructuring
      this.requestDetails = response[1];
      const { skip_count, days_till_due, skip_count_threshold, is_user_read_only } = await this.axiosFetch(`/api/post-login-actions`);
      this.skippedCount = skip_count;
      this.daysTillDue = days_till_due;
      this.skippedCountThreshold = skip_count_threshold
      this.isUserReadOnly = is_user_read_only
      this.requestDetails.name = item.message_type;
      this.taskId = this.requestDetails?.task?.slug ? this.requestDetails?.task?.slug : '';
      this.isComponent = inboxPageMap[this.requestDetails.message_type.name.replace(/\s/g, '_').toUpperCase()];
      this.isDocumentRequest = (this.requestDetails.message_type.name === 'Documentation Request') || (this.requestDetails.message_type.name === 'Conditional Approval');
      this.allowedToUpload = this.requestDetails.allow_responses;
      // this.lockMessage(item.id);

      // Mark read only if new
      if (item?.is_new) this.markAsRead(item.id);

      this.loadingDialogText = '';

      if (item.is_complete && item.is_new) {
        this.$emit('new-message-submit');
      }
      item.is_new = false;
    },
    getAllDocuments() {
      const bucket = 'documentation_request';
      const url = `/api/tasks/${this.taskId}/documents`;
      if (url) {
        // eslint-disable-next-line no-undef
        axios.get(url).then(
          (response) => {
            if (response.data && response.data.length) {
              const docData = response.data
                .filter((d) => d.is_removed !== true)
                .reverse();
              let upldDocs = [];
              docData.forEach((e) => {
                upldDocs.push({
                  id: e.id,
                  uploaded_document_id: e.id,
                  name: e.name,
                  type: e.document_type.label,
                  doc_type_name: e.document_type.name,
                  uploaded_doc_bucket: e.document_type.bucket,
                  uploaded_document_status_display:
                    e.status.charAt(0).toUpperCase() + e.status.slice(1),
                  uploaded_document_created_by: e.created_by,
                  uploaded_document_tags: e.tags,
                  document_file: e.document,
                  details: e.details,
                });
                upldDocs = upldDocs.filter((doc) => doc.uploaded_doc_bucket === bucket && doc?.details?.thread == this.thread.id);

                // filter documents of the requested type only
                if (this.requestDetails.document_types && this.requestDetails.document_types.length) {
                  upldDocs = upldDocs.filter((d) => this.requestDetails.document_types.includes(d.doc_type_name));
                }
              });
              this.uploaded_docs = upldDocs;
              this.requestDialogLoading = false;
              this.fetchLoading = false;
            } else {
              this.requestDialogLoading = false;
              this.fetchLoading = false;
            }
          },
          (err) => {
            this.loading = false;
            const snackObj = {
              err,
              snackMsg: this.$t('fetchDocumentsError'),
            };
            this.$emit('snack', snackObj);
          },
        );
      } else this.$emit('snack', { snackMsg: this.$t('genericError') });
    },
    getApiErrors(element, labelPath) {
      // recursive call to flatten API errors;
      Object.entries(element).forEach(([key, value]) => {
        if (['string', 'boolean', 'number'].includes(typeof element[key])) {
          let label = labelPath;
          if (label !== '') { label += ':'; }
          this.apiErrors.push(`${label} ${value}`);
        } else {
          let labelTmp = labelPath;
          labelTmp += (labelPath === '') ? key : (Array.isArray(element) ? '' : `.${key}`);
          this.getApiErrors(element[key], labelTmp);
        }
      });
    },
  },
  watch: {
    async requestDialog(val) {
      if (val) {
        await this.openRequest(this.thread);
        this.getAllDocuments();
      }
    },
  },
  computed: {
    disableSave() {
      return this.uploaded_docs.length < this.allowedIDTypes.length || this.requestDialogLoading;
    },
    allowedIDTypes() {
      const filteredTypes = this.documentTypes.filter((type) => this.requestDetails?.document_types?.includes(type?.name));
      return filteredTypes;
    },
    taskUser() {
      const user = this.requestDetails?.task?.user;
      return user;
    },
    uploadedDocsByType() {
      const uploadedDocs = {};
      // eslint-disable-next-line object-curly-newline
      this.uploaded_docs?.forEach(({ type, doc, name, details, uploadedDocName }) => {
        uploadedDocs[`${type}`] = { uploadedDocName: details?.filename ? details?.filename : uploadedDocName, name, doc };
      });
      return uploadedDocs;
    },
  },
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-app", [
    _c(
      "div",
      { staticClass: "page-container" },
      [
        _c("v-toolbar", { staticClass: "header" }, [
          _c("div", { staticClass: "d-flex justify-content-between w-100" }, [
            _c("img", {
              staticClass: "irs-logo",
              attrs: { alt: "IRS Logo", src: _vm.irsLogo },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "page-content" },
          [
            _c(
              "v-form",
              {
                model: {
                  value: _vm.isFormValid,
                  callback: function ($$v) {
                    _vm.isFormValid = $$v
                  },
                  expression: "isFormValid",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "app d-flex justify-content-center" },
                  [
                    _c(
                      "div",
                      { staticClass: "w-85 d-flex flex-column gap-3" },
                      [
                        _c("h1", { staticClass: "fw-bold" }, [
                          _vm._v("Sign In or Create a New Account"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "info-div" }, [
                          _c("div", { staticClass: "d-flex" }, [
                            _c("i", {
                              staticClass:
                                "fas fa-circle-info fs-2 text-dark me-2",
                              attrs: { "aria-hidden": "true" },
                            }),
                            _vm._v(" "),
                            _c("h3", { staticClass: "info-div-heading" }, [
                              _vm._v("You only need one Socure account"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "info-div-text" }, [
                            _vm._v(
                              "\n                If you already have an account, don't create a new one. You can use the same Socure account to sign in\n                to different IRS online services.\n              "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "intro-panel" }, [
                          _c("div", [
                            _c("p", [
                              _vm._v(
                                "\n                  IRS now offers a sign-in option with Socure, which offers access to IRS online services with a\n                  secure account that protects your privacy.\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "Socure is an account created, maintained, and secured by a technology provider."
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "If you don't have an Socure account, you must create a new account."
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "login-panel" }, [
                            _c("h2", { staticClass: "login-btn-header" }, [
                              _c("b", [
                                _vm._v("Sign in with an existing account"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://irs.fedlogin.thesummitgrp.com/login/customized",
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: _vm.socureBtn,
                                    alt: "Sign In with Socure",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "separator" }, [
                              _c("div", { staticClass: "separator-spacer" }, [
                                _c("b", [_vm._v("OR")]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("h2", { staticClass: "login-btn-header" }, [
                              _c("b", [_vm._v("Create a new account")]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                attrs: { href: "/signup/registration/default" },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: _vm.newAccountBtn,
                                    alt: "Sign In with Socure",
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("h2", { staticClass: "panel-heading" }, [
                          _c("span", [_vm._v("Frequently Asked Questions")]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "collapseableTitlePanel",
                            attrs: { id: "faq_title_panel1" },
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "accordion-trigger w-200",
                                attrs: {
                                  id: "faq_plusminus1",
                                  alt: "Expand How do I verify my identity?",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.toggle(
                                      "faq_title_panel1",
                                      "faq_panel1",
                                      "faq_plusminus1"
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "accordion-triggerPlusIcon" },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          fill: "#00599C",
                                          preserveAspectRatio: "xMidYMid meet",
                                          height: "24px",
                                          width: "24px",
                                          viewBox: "0 0 20 20",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d: "M15.8333335,10.8333335 C15.8333335,11.2890625 15.455729,11.6666669 15,11.6666669 L11.6666669,11.6666669 L11.6666669,15 C11.6666669,15.455729 11.2890625,15.8333335 10.8333335,15.8333335 L9.16666654,15.8333335 C8.71093752,15.8333335 8.33333307,15.455729 8.33333307,15 L8.33333307,11.6666669 L5,11.6666669 C4.54427098,11.6666669 4.16666654,11.2890625 4.16666654,10.8333335 L4.16666654,9.16666654 C4.16666654,8.71093752 4.54427098,8.33333307 5,8.33333307 L8.33333307,8.33333307 L8.33333307,5 C8.33333307,4.54427098 8.71093752,4.16666654 9.16666654,4.16666654 L10.8333335,4.16666654 C11.2890625,4.16666654 11.6666669,4.54427098 11.6666669,5 L11.6666669,8.33333307 L15,8.33333307 C15.455729,8.33333307 15.8333335,8.71093752 15.8333335,9.16666654 L15.8333335,10.8333335 Z M10,19 C14.9705627,19 19,14.9705627 19,10 C19,5.02943725 14.9705627,1 10,1 C5.02943725,1 1,5.02943725 1,10 C1,14.9705627 5.02943725,19 10,19 Z M10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 Z",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("span", [
                                  _c("span", [
                                    _vm._v("How do I verify my identity?"),
                                  ]),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "collapseablePanel",
                            staticStyle: { display: "none" },
                            attrs: { id: "faq_panel1" },
                          },
                          [
                            _c("div", { staticClass: "InfoPanel" }, [
                              _c("p", [
                                _vm._v(
                                  "The IRS offers multiple ways to verify your identity with Socure."
                                ),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _c("span", [
                                  _vm._v(
                                    "You can use either a self-service process that requires a photo of a government ID and selfie, or\n                    a live call with an Socure video chat agent that doesn’t require biometric data. Any selfie,\n                    video, and/or biometric data will be deleted automatically, except for suspicious or fraudulent\n                    activity. If you need help verifying your identity or to submit a support ticket, you can visit\n                    the\n                  "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass: "Links-externalLink",
                                    attrs: {
                                      id: "aIdmeHelp",
                                      href: "https://irs.help.Socure/",
                                      target: "_blank",
                                    },
                                  },
                                  [_c("span", [_vm._v("Socure IRS Help Site")])]
                                ),
                                _c("span", [_vm._v(".")]),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _c("span", [
                                  _vm._v(
                                    "If you use assistive technology such as a screen reader or have trouble taking photos, you may\n                    need assistance to complete the process. Find more information in our"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "https://www.irs.gov/sadiaccessibility",
                                    },
                                  },
                                  [_c("span", [_vm._v("accessibility guide")])]
                                ),
                                _c("span", [_vm._v(".")]),
                              ]),
                              _vm._v(" "),
                              _c("p", [_c("span")]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "collapseableTitlePanel",
                            attrs: { id: "faq_title_panel2" },
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "accordion-trigger",
                                attrs: {
                                  id: "faq_plusminus2",
                                  alt: "Expand What if I can't verify my identity?",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.toggle(
                                      "faq_title_panel2",
                                      "faq_panel2",
                                      "faq_plusminus2"
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "accordion-triggerPlusIcon" },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          alt: "",
                                          fill: "#00599C",
                                          preserveAspectRatio: "xMidYMid meet",
                                          height: "24px",
                                          width: "24px",
                                          viewBox: "0 0 20 20",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d: "M15.8333335,10.8333335 C15.8333335,11.2890625 15.455729,11.6666669 15,11.6666669 L11.6666669,11.6666669 L11.6666669,15 C11.6666669,15.455729 11.2890625,15.8333335 10.8333335,15.8333335 L9.16666654,15.8333335 C8.71093752,15.8333335 8.33333307,15.455729 8.33333307,15 L8.33333307,11.6666669 L5,11.6666669 C4.54427098,11.6666669 4.16666654,11.2890625 4.16666654,10.8333335 L4.16666654,9.16666654 C4.16666654,8.71093752 4.54427098,8.33333307 5,8.33333307 L8.33333307,8.33333307 L8.33333307,5 C8.33333307,4.54427098 8.71093752,4.16666654 9.16666654,4.16666654 L10.8333335,4.16666654 C11.2890625,4.16666654 11.6666669,4.54427098 11.6666669,5 L11.6666669,8.33333307 L15,8.33333307 C15.455729,8.33333307 15.8333335,8.71093752 15.8333335,9.16666654 L15.8333335,10.8333335 Z M10,19 C14.9705627,19 19,14.9705627 19,10 C19,5.02943725 14.9705627,1 10,1 C5.02943725,1 1,5.02943725 1,10 C1,14.9705627 5.02943725,19 10,19 Z M10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 Z",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "collapseablePanelTitle" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "What if I can't verify my identity?"
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "collapseablePanel",
                            staticStyle: { display: "none" },
                            attrs: { id: "faq_panel2" },
                          },
                          [
                            _c("div", { staticClass: "InfoPanel" }, [
                              _c("p", [
                                _c("span", [
                                  _vm._v(
                                    "If you need help verifying your identity or to submit a support ticket, you can visit the\n                  "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass: "Links-externalLink",
                                    attrs: {
                                      id: "aIdmeHelp",
                                      href: "https://irs.help.Socure/",
                                      target: "_blank",
                                    },
                                  },
                                  [_c("span", [_vm._v("Socure IRS Help Site")])]
                                ),
                                _c("span", [_vm._v(".")]),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _c("span", [
                                  _vm._v(
                                    "If you can't verify your identity online, please see our "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "https://www.irs.gov/accountalt",
                                    },
                                  },
                                  [_c("span", [_vm._v("alternative options")])]
                                ),
                                _c("span", [_vm._v(".")]),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _c("span", [
                                  _vm._v(
                                    "If you're under 18 years old, the system doesn't allow access."
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _c("span", [
                                  _vm._v(
                                    'If you get a message that says "A condition has been identified that\'s preventing your\n                    access to this service," this means you won’t be able to use the online service at this\n                    time.'
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("p", [_c("span")]),
                              _vm._v(" "),
                              _c("p", [_c("span")]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "collapseableTitlePanel",
                              attrs: { id: "faq_title_panel3" },
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "accordion-trigger",
                                  attrs: {
                                    id: "faq_plusminus3",
                                    alt: "Expand What is Socure?",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggle(
                                        "faq_title_panel3",
                                        "faq_panel3",
                                        "faq_plusminus3"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "accordion-triggerPlusIcon",
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          attrs: {
                                            alt: "",
                                            fill: "#00599C",
                                            preserveAspectRatio:
                                              "xMidYMid meet",
                                            height: "24px",
                                            width: "24px",
                                            viewBox: "0 0 20 20",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d: "M15.8333335,10.8333335 C15.8333335,11.2890625 15.455729,11.6666669 15,11.6666669 L11.6666669,11.6666669 L11.6666669,15 C11.6666669,15.455729 11.2890625,15.8333335 10.8333335,15.8333335 L9.16666654,15.8333335 C8.71093752,15.8333335 8.33333307,15.455729 8.33333307,15 L8.33333307,11.6666669 L5,11.6666669 C4.54427098,11.6666669 4.16666654,11.2890625 4.16666654,10.8333335 L4.16666654,9.16666654 C4.16666654,8.71093752 4.54427098,8.33333307 5,8.33333307 L8.33333307,8.33333307 L8.33333307,5 C8.33333307,4.54427098 8.71093752,4.16666654 9.16666654,4.16666654 L10.8333335,4.16666654 C11.2890625,4.16666654 11.6666669,4.54427098 11.6666669,5 L11.6666669,8.33333307 L15,8.33333307 C15.455729,8.33333307 15.8333335,8.71093752 15.8333335,9.16666654 L15.8333335,10.8333335 Z M10,19 C14.9705627,19 19,14.9705627 19,10 C19,5.02943725 14.9705627,1 10,1 C5.02943725,1 1,5.02943725 1,10 C1,14.9705627 5.02943725,19 10,19 Z M10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 Z",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "collapseablePanelTitle" },
                                    [_c("span", [_vm._v("What is Socure?")])]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "collapseablePanel",
                              staticStyle: { display: "none" },
                              attrs: { id: "faq_panel3" },
                            },
                            [
                              _c("div", { staticClass: "InfoPanel" }, [
                                _c("p", [
                                  _c("span", [
                                    _vm._v(
                                      "IRS now offers a sign-in option with Socure, which offers access to IRS online services with a\n                      secure account that protects your privacy. Socure is an account created, maintained, and secured\n                      by a technology provider. With an Socure account, you can access other government partners who\n                      also use this sign-in option."
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("p", [_c("span")]),
                                _vm._v(" "),
                                _c("p", [_c("span")]),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("div", { staticClass: "disclaimerPanel" }, [
                          _c("span", { staticClass: "tiny-text" }, [
                            _c("b", [
                              _c("span", [
                                _vm._v(
                                  "This U.S. Government system is for authorized use only."
                                ),
                              ]),
                            ]),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                "Warning: This system may contain private tax information. By using this system, you consent to the\n                  monitoring, recording, and reviewing of your activities in this system. You may only access this\n                  system using your own personal information. Any other use of this system is an unauthorized use and\n                  is prohibited."
                              ),
                            ]),
                            _c("br"),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                "Unauthorized use violates Federal law and may result in criminal or civil penalties under these\n                  laws. Examples are penalties for knowingly or intentionally accessing a computer without\n                  authorization or exceeding authorized access under 18 U.S.C. 1030, and penalties for the willful\n                  unauthorized access or inspection of taxpayer records under 26 U.S.C. 7213A and 26 U.S.C.\n                  7431."
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-form", { ref: "step6form" }, [
    _c(
      "div",
      { staticClass: "p-2" },
      [
        _c("div", [
          _c("label", { staticClass: "pb-1" }, [
            _vm._v(_vm._s(_vm.$t("lossverificationStep6Question"))),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { staticClass: "col-12 col-md-6" },
              [
                _c("label", { staticClass: "pb-1 mandatory" }, [
                  _c("small", [
                    _vm._v(_vm._s(_vm.$t("lossverificationStep6SubQuestion1"))),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "v-radio-group",
                  {
                    attrs: { rules: _vm.mandatoryRuleRadio, row: "" },
                    on: {
                      change: function ($event) {
                        return _vm.autoDamageUpdate()
                      },
                    },
                    model: {
                      value: _vm.step6Data.automobileDamage,
                      callback: function ($$v) {
                        _vm.$set(_vm.step6Data, "automobileDamage", $$v)
                      },
                      expression: "step6Data.automobileDamage",
                    },
                  },
                  [
                    _c("v-radio", {
                      attrs: { label: _vm.$t("yes"), value: true },
                    }),
                    _vm._v(" "),
                    _c("v-radio", {
                      attrs: { label: _vm.$t("no"), value: false },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-col",
              { staticClass: "col-12 col-md-6" },
              [
                _c("label", { staticClass: "pb-1 mandatory" }, [
                  _c("small", [
                    _vm._v(_vm._s(_vm.$t("lossverificationStep6SubQuestion2"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("v-text-field", {
                  attrs: {
                    type: "number",
                    disabled: !_vm.step6Data.automobileDamage,
                    rules: _vm.step6Data.automobileDamage
                      ? _vm.mandatoryRule
                      : [],
                    solo: "",
                    outlined: "",
                    dense: "",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.autoDamageCountChange()
                    },
                  },
                  model: {
                    value: _vm.step6Data.autoDamageCount,
                    callback: function ($$v) {
                      _vm.$set(_vm.step6Data, "autoDamageCount", $$v)
                    },
                    expression: "step6Data.autoDamageCount",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import es from 'vuetify/lib/locale/es';
import en from 'vuetify/lib/locale/en';

Vue.use(Vuetify);

const opts = {
  theme: {
    options: { customProperties: true },
    dark: false,
    themes: {
      light: {
        primary: {
          base: '#002e6d',
          lighten1: '#003B89',
        },
        secondary: '#d90811',
        accent: '#8c9eff',
        error: '#ab1a1a',
      },
    },
  },
  lang: {
    locales: { es, en },
    current: 'en',
  },
};

export default new Vuetify(opts);

<template>


  <v-form ref="step2form">
    <span class="brand-text-primary data-val">Explore Your Options</span>
    <div> <label class="pb-1 mandatory">
      <small>If you are interested in one of the below options, please select and follow the required prompts.</small></label></div>
    <div class="p-2">
      <v-radio-group v-model="step2Data.selectedCounterOffer" :rules="mandatoryRule">
        <v-radio v-for="(item,index) in counter_offers" :key="index" :value="item" class="card-bg-light pl-4 py-3"> 
           <template v-slot:label>
           <v-container class="card-bg-light">
             Option {{index + 1}}
             <div><span class="brand-text-primary data-val">{{item.product.name}}</span></div>
             <div class="d-flex flex-row flex-wrap justify-content-start">
              <div class="info-box col-md-3">
                <label class="pb-1 mandatory">{{$t('loanAmount')}}</label>
                <div class="pb-1 brand-text-primary">${{item.loan_amount}}</div>
              </div>
              <div class="info-box col-md-3">
                <label class="pb-1 mandatory">{{$t('loanTerm')}}</label>
                <div class="pb-1 text-primary">{{item.loan_tenure}} Months</div>
              </div>
               <div class="info-box col-md-3">
                <label class="pb-1 mandatory">{{$t('interestRate')}}</label>
                <div class="pb-1 text-primary">{{item.rate}}%</div>
              </div>
             </div>
            </v-container>
           </template>
        </v-radio>
      </v-radio-group>
    </div>
     
  </v-form>
</template>

<script>

export default {

  mixins: [],
  components: {
  },
  props: {
     step2Obj: Object,
     counter_offer_details: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      step2Data: {
        selectedCounterOffer: null
      },
      counter_offers:[]
    };
  },
  computed: {
    mandatoryRule() {
      return [
        this.step2Data.selectedCounterOffer != null || this.$t('requiredField'),
      ];
    },
  },
  methods: {
  },
  watch: {
    step2Data: {
      handler() {
        this.$emit('update:step2Obj', this.step2Data);
      },
      deep: true,
    },
    counter_offer_details: function(){
      const offers = [];
      if (this.counter_offer_details && this.counter_offer_details.length) {
        for (const key in this.counter_offer_details[0]) {
          const data = this.counter_offer_details[0][key];
          offers.push({
            id: key,
            ...data,
          });
        }
      }
      this.counter_offers = offers;

      console.log(this.counter_offers)
    }
  },
};
</script>

<template>
  <v-form ref="step1form">
    <div class="p-2">
      <label class="pb-1">{{$t('lossverificationStep1Question')}}</label>
      <v-radio-group v-model="step1Data.isSelfAssesment" @change="validateNext()" row :rules="mandatoryRule">
        <v-radio :label="$t('yes')" v-bind:value="true"></v-radio>
        <v-radio :label="$t('no')" v-bind:value="false"></v-radio>
      </v-radio-group>
    </div>
     <div v-if="step1Data.isSelfAssesment" class="p-2">
      <label class="pb-1">{{$t('lossverificationStep1QuestionRenters')}}</label>
      <v-radio-group v-model="step1Data.isPropertyDamage" @change="checkPropertyDamage()" row :rules="mandatoryRuleProperty">
        <v-radio :label="$t('yes')" v-bind:value="true"></v-radio>
        <v-radio :label="$t('no')" v-bind:value="false"></v-radio>
      </v-radio-group>
    </div>
  </v-form>
</template>

<script>

export default {

  mixins: [],
  components: {
  },
  props: {
     step1Obj: Object,
  },
  data() {
    return {
      step1Data: {
        isSelfAssesment: null,
        isPropertyDamage:null
      }
     
    };
  },

  computed: {
    mandatoryRule() {
      return [
        this.step1Data.isSelfAssesment != null || this.$t('requiredField'),
      ];
    },
    mandatoryRuleProperty() {
      return [
        this.step1Data.isPropertyDamage != null || this.$t('requiredField'),
      ];
    }

  },
  methods: {
    validateNext() {
      this.$emit("isSelfAssesment", this.step1Data.isSelfAssesment);
    },
    checkPropertyDamage(){
       this.$emit("isPropertyDamage", this.step1Data.isPropertyDamage);
    }
  },
  watch: {
    step1Data: {
      handler() {
        this.$emit('update:step1Obj', this.step1Data);
      },
      deep: true,
    },
  },
};
</script>

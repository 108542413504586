var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-form", { ref: "step5form" }, [
    _c(
      "div",
      { staticClass: "loss-slider-container" },
      [
        _c("div", [
          _c("label", { staticClass: "pb-1" }, [
            _vm._v(_vm._s(_vm.$t("lossverificationStep5Question"))),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("small", { staticClass: "pb-1" }, [
            _c("i", { staticClass: "fal fa-info-circle" }),
            _vm._v(_vm._s(_vm.$t("lossverificationStep5ToolTip"))),
          ]),
        ]),
        _vm._v(" "),
        _c("v-slider", {
          staticClass: "loss-slider",
          attrs: {
            "tick-labels": _vm.ticksLabels,
            max: 4,
            step: "1",
            ticks: "always",
            "tick-size": "5",
          },
          scopedSlots: _vm._u([
            {
              key: "thumb-label",
              fn: function (props) {
                return [
                  _c("div", { staticClass: "loss-tooltip" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.getTooltipTitle(props.value))),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(_vm._s(_vm.getTooltipText(props.value))),
                    ]),
                  ]),
                ]
              },
            },
          ]),
          model: {
            value: _vm.step5Data.personalDamageLevel,
            callback: function ($$v) {
              _vm.$set(_vm.step5Data, "personalDamageLevel", $$v)
            },
            expression: "step5Data.personalDamageLevel",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
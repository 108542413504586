<template>
  <v-app>
    <div class="page-container">
      <v-toolbar class="header">
        <div class="d-flex justify-content-between w-100">
          <img alt="IRS Logo" :src="irsLogo" class="irs-logo" />
        </div>
      </v-toolbar>
      <div class="page-content">
        <v-form v-model="isFormValid">
          <div class="app d-flex justify-content-center">
            <div class="w-85 d-flex flex-column gap-3">
              <h1 class="fw-bold">Sign In or Create a New Account</h1>
              <div class="info-div">
                <div class="d-flex">
                  <i aria-hidden="true" class="fas fa-circle-info fs-2 text-dark me-2"></i>
                  <h3 class="info-div-heading">You only need one Socure account</h3>
                </div>
                <div class="info-div-text">
                  If you already have an account, don't create a new one. You can use the same Socure account to sign in
                  to different IRS online services.
                </div>
              </div>

              <div class="intro-panel">
                <div>
                  <p>
                    IRS now offers a sign-in option with Socure, which offers access to IRS online services with a
                    secure account that protects your privacy.
                  </p>
                  <p>Socure is an account created, maintained, and secured by a technology provider.</p>
                  <p>If you don't have an Socure account, you must create a new account.</p>
                </div>
                <div class="login-panel">
                  <h2 class="login-btn-header"><b>Sign in with an existing account</b></h2>
                  <a href="https://irs.fedlogin.thesummitgrp.com/login/customized">
                    <img :src="socureBtn" alt="Sign In with Socure" />
                  </a>
                  <div class="separator">
                    <div class="separator-spacer"><b>OR</b></div>
                  </div>
                  <h2 class="login-btn-header"><b>Create a new account</b></h2>
                  <a href="/signup/registration/default"> <img :src="newAccountBtn" alt="Sign In with Socure" /> </a>
                </div>
              </div>

              <h2 class="panel-heading"><span>Frequently Asked Questions</span></h2>
              <div id="faq_title_panel1" class="collapseableTitlePanel">
                <button
                  id="faq_plusminus1"
                  class="accordion-trigger w-200"
                  @click="toggle('faq_title_panel1', 'faq_panel1', 'faq_plusminus1')"
                  alt="Expand How do I verify my identity?"
                >
                  <span class="accordion-triggerPlusIcon">
                    <svg
                      fill="#00599C"
                      preserveAspectRatio="xMidYMid meet"
                      height="24px"
                      width="24px"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M15.8333335,10.8333335 C15.8333335,11.2890625 15.455729,11.6666669 15,11.6666669 L11.6666669,11.6666669 L11.6666669,15 C11.6666669,15.455729 11.2890625,15.8333335 10.8333335,15.8333335 L9.16666654,15.8333335 C8.71093752,15.8333335 8.33333307,15.455729 8.33333307,15 L8.33333307,11.6666669 L5,11.6666669 C4.54427098,11.6666669 4.16666654,11.2890625 4.16666654,10.8333335 L4.16666654,9.16666654 C4.16666654,8.71093752 4.54427098,8.33333307 5,8.33333307 L8.33333307,8.33333307 L8.33333307,5 C8.33333307,4.54427098 8.71093752,4.16666654 9.16666654,4.16666654 L10.8333335,4.16666654 C11.2890625,4.16666654 11.6666669,4.54427098 11.6666669,5 L11.6666669,8.33333307 L15,8.33333307 C15.455729,8.33333307 15.8333335,8.71093752 15.8333335,9.16666654 L15.8333335,10.8333335 Z M10,19 C14.9705627,19 19,14.9705627 19,10 C19,5.02943725 14.9705627,1 10,1 C5.02943725,1 1,5.02943725 1,10 C1,14.9705627 5.02943725,19 10,19 Z M10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 Z"
                      />
                    </svg>
                  </span>
                  <span><span>How do I verify my identity?</span></span>
                </button>
              </div>
              <div id="faq_panel1" class="collapseablePanel" style="display: none">
                <div class="InfoPanel">
                  <p>The IRS offers multiple ways to verify your identity with Socure.</p>

                  <p>
                    <span
                      >You can use either a self-service process that requires a photo of a government ID and selfie, or
                      a live call with an Socure video chat agent that doesn’t require biometric data. Any selfie,
                      video, and/or biometric data will be deleted automatically, except for suspicious or fraudulent
                      activity. If you need help verifying your identity or to submit a support ticket, you can visit
                      the
                    </span>
                    <a id="aIdmeHelp" href="https://irs.help.Socure/" target="_blank" class="Links-externalLink">
                      <span>Socure IRS Help Site</span></a
                    ><span>.</span>
                  </p>

                  <p>
                    <span
                      >If you use assistive technology such as a screen reader or have trouble taking photos, you may
                      need assistance to complete the process. Find more information in our</span
                    >
                    <a href="https://www.irs.gov/sadiaccessibility"> <span>accessibility guide</span></a
                    ><span>.</span>
                  </p>
                  <p>
                    <span></span>
                  </p>
                </div>
              </div>
              <div id="faq_title_panel2" class="collapseableTitlePanel">
                <button
                  id="faq_plusminus2"
                  class="accordion-trigger"
                  @click="toggle('faq_title_panel2', 'faq_panel2', 'faq_plusminus2')"
                  alt="Expand What if I can&#39;t verify my identity?"
                >
                  <span class="accordion-triggerPlusIcon">
                    <svg
                      alt=""
                      fill="#00599C"
                      preserveAspectRatio="xMidYMid meet"
                      height="24px"
                      width="24px"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M15.8333335,10.8333335 C15.8333335,11.2890625 15.455729,11.6666669 15,11.6666669 L11.6666669,11.6666669 L11.6666669,15 C11.6666669,15.455729 11.2890625,15.8333335 10.8333335,15.8333335 L9.16666654,15.8333335 C8.71093752,15.8333335 8.33333307,15.455729 8.33333307,15 L8.33333307,11.6666669 L5,11.6666669 C4.54427098,11.6666669 4.16666654,11.2890625 4.16666654,10.8333335 L4.16666654,9.16666654 C4.16666654,8.71093752 4.54427098,8.33333307 5,8.33333307 L8.33333307,8.33333307 L8.33333307,5 C8.33333307,4.54427098 8.71093752,4.16666654 9.16666654,4.16666654 L10.8333335,4.16666654 C11.2890625,4.16666654 11.6666669,4.54427098 11.6666669,5 L11.6666669,8.33333307 L15,8.33333307 C15.455729,8.33333307 15.8333335,8.71093752 15.8333335,9.16666654 L15.8333335,10.8333335 Z M10,19 C14.9705627,19 19,14.9705627 19,10 C19,5.02943725 14.9705627,1 10,1 C5.02943725,1 1,5.02943725 1,10 C1,14.9705627 5.02943725,19 10,19 Z M10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 Z"
                      ></path>
                    </svg>
                  </span>
                  <span class="collapseablePanelTitle"><span>What if I can&#39;t verify my identity?</span></span>
                </button>
              </div>
              <div id="faq_panel2" class="collapseablePanel" style="display: none">
                <div class="InfoPanel">
                  <p>
                    <span
                      >If you need help verifying your identity or to submit a support ticket, you can visit the
                    </span>

                    <a id="aIdmeHelp" href="https://irs.help.Socure/" target="_blank" class="Links-externalLink">
                      <span>Socure IRS Help Site</span></a
                    ><span>.</span>
                  </p>
                  <p>
                    <span>If you can&#39;t verify your identity online, please see our </span>

                    <a href="https://www.irs.gov/accountalt"> <span>alternative options</span></a
                    ><span>.</span>
                  </p>
                  <p><span>If you&#39;re under 18 years old, the system doesn&#39;t allow access.</span></p>

                  <p>
                    <span
                      >If you get a message that says &quot;A condition has been identified that&#39;s preventing your
                      access to this service,&quot; this means you won’t be able to use the online service at this
                      time.</span
                    >
                  </p>
                  <p><span></span></p>
                  <p><span></span></p>
                </div>
              </div>
              <div>
                <div id="faq_title_panel3" class="collapseableTitlePanel">
                  <button
                    id="faq_plusminus3"
                    class="accordion-trigger"
                    @click="toggle('faq_title_panel3', 'faq_panel3', 'faq_plusminus3')"
                    alt="Expand What is Socure?"
                  >
                    <span class="accordion-triggerPlusIcon">
                      <svg
                        alt=""
                        fill="#00599C"
                        preserveAspectRatio="xMidYMid meet"
                        height="24px"
                        width="24px"
                        viewBox="0 0 20 20"
                      >
                        <path
                          d="M15.8333335,10.8333335 C15.8333335,11.2890625 15.455729,11.6666669 15,11.6666669 L11.6666669,11.6666669 L11.6666669,15 C11.6666669,15.455729 11.2890625,15.8333335 10.8333335,15.8333335 L9.16666654,15.8333335 C8.71093752,15.8333335 8.33333307,15.455729 8.33333307,15 L8.33333307,11.6666669 L5,11.6666669 C4.54427098,11.6666669 4.16666654,11.2890625 4.16666654,10.8333335 L4.16666654,9.16666654 C4.16666654,8.71093752 4.54427098,8.33333307 5,8.33333307 L8.33333307,8.33333307 L8.33333307,5 C8.33333307,4.54427098 8.71093752,4.16666654 9.16666654,4.16666654 L10.8333335,4.16666654 C11.2890625,4.16666654 11.6666669,4.54427098 11.6666669,5 L11.6666669,8.33333307 L15,8.33333307 C15.455729,8.33333307 15.8333335,8.71093752 15.8333335,9.16666654 L15.8333335,10.8333335 Z M10,19 C14.9705627,19 19,14.9705627 19,10 C19,5.02943725 14.9705627,1 10,1 C5.02943725,1 1,5.02943725 1,10 C1,14.9705627 5.02943725,19 10,19 Z M10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 Z"
                        ></path>
                      </svg>
                    </span>
                    <span class="collapseablePanelTitle">
                      <span>What is Socure?</span>
                    </span>
                  </button>
                </div>
                <div id="faq_panel3" class="collapseablePanel" style="display: none">
                  <div class="InfoPanel">
                    <p>
                      <span
                        >IRS now offers a sign-in option with Socure, which offers access to IRS online services with a
                        secure account that protects your privacy. Socure is an account created, maintained, and secured
                        by a technology provider. With an Socure account, you can access other government partners who
                        also use this sign-in option.</span
                      >
                    </p>
                    <p><span></span></p>
                    <p><span></span></p>
                  </div>
                </div>
              </div>
              <br />
              <div class="disclaimerPanel">
                <span class="tiny-text"
                  ><b><span>This U.S. Government system is for authorized use only.</span></b
                  ><br />
                  <span
                    >Warning: This system may contain private tax information. By using this system, you consent to the
                    monitoring, recording, and reviewing of your activities in this system. You may only access this
                    system using your own personal information. Any other use of this system is an unauthorized use and
                    is prohibited.</span
                  ><br /><br />
                  <span
                    >Unauthorized use violates Federal law and may result in criminal or civil penalties under these
                    laws. Examples are penalties for knowingly or intentionally accessing a computer without
                    authorization or exceeding authorized access under 18 U.S.C. 1030, and penalties for the willful
                    unauthorized access or inspection of taxpayer records under 26 U.S.C. 7213A and 26 U.S.C.
                    7431.</span
                  >
                </span>
              </div>
            </div>
          </div>
        </v-form>
      </div>
    </div>
  </v-app>
</template>
<script>
import RulesMixin from '../../../lender-vue/mixins/RulesMixin';

export default {
  components: {},
  mixins: [RulesMixin],
  data() {
    return {
      isFormValid: false,
      user_details: {
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        username: '',
      },
      irsLogo: require('../../../img/irs-logo-white.png'),
      socureBtn: require('../../../img/sign-in-with-socure.jpg'),
      newAccountBtn: require('../../../img/create-an-account-with-socure.jpg'),
    };
  },
  methods: {
    async onStart() {
      try {
        const data = {
          user_details: this.user_details,
          socure_details: {
            customerUserId: 'customer-1234',
            eventId: 'e1a0fed6-148c-49e5-8e2f-a2df401f33ec',
            status: 'VERIFICATION_COMPLETE',
            verificationLevel: 2,
            key: '671bb150-9a24-4879-9822-bb09a7aef178',
            referenceId: '84be20e5-ffc7-48cd-919f-2c70008357db',
            verifyResult: {
              referenceId: 'c6681d38-bbb4-4530-895b-7ba8a3397f10',
              documentVerification: {
                reasonCodes: ['I856', 'I845', 'R827', 'I820', 'R831', 'I836', 'I838', 'R823', 'R819', 'R822'],
                documentType: { type: 'Drivers License', country: 'USA', state: 'GA' },
                decision: { name: 'standard', value: 'reject' },
                documentData: {
                  firstName: 'JOHN MICHAEL STAR',
                  surName: 'THIBEDEAU',
                  fullName: 'JOHN MICHAEL STAR THIBEDEAU',
                  documentNumber: '920538211',
                  dob: '2002-02-22',
                  expirationDate: '2022-02-22',
                  issueDate: '2018-02-22',
                },
              },
            },
            documentUuid: '7c64e1a4-1d06-443e-a40e-18d1993027dd',
          },
          meeting_preference: { immediate: true, preferred_date_time: '' },
        };
        const res = await axios.post('/api/case/create-case', data);
        this.$router.push({ name: 'EddLandingPage', params: { id: res.data.case_id } });
      } catch (err) {
        console.error(err);
      }
    },
    toggle(title_id, panel_id, plusminus) {
      var pm = document.getElementById(plusminus);
      var div_title = document.getElementById(title_id);
      var div_panel = document.getElementById(panel_id);
      var numFormatted = '';
      let alttext = '';
      let expandText = 'Expand';
      let collapseText = 'Collapse';

      if (panel_id == 'faq_panel1') {
        alttext = 'How do I verify my identity?';
      } else {
        if (panel_id == 'faq_panel2') {
          alttext = "What if I can't verify my identity?";
        } else {
          if (panel_id == 'faq_panel3') {
            alttext = 'What is Socure?';
          }
        }
      }

      if (div_panel.style.display == 'none') {
        div_panel.style.display = 'block';
        div_panel.style = 'border-top-color: white';
        div_title.style = 'border-bottom-color: white';
        pm.innerHTML =
          '<span class="accordion-triggerPlusIcon"><svg fill="#00599C" width="24px" height="24px" viewBox="0 0 16 16"><path fill="#00599C" d="M8 1c3.9 0 7 3.1 7 7s-3.1 7-7 7-7-3.1-7-7 3.1-7 7-7zM8 0c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8v0z"></path><path fill="#00599C" d="M3 7h10v2h-10v-2z"></path></svg></span><font size="5" class="px-2" color="#1b1b1b">' +
          alttext +
          '</font>';
        pm.setAttribute('alt', collapseText + ' ' + alttext);
      } else {
        div_panel.style.display = 'none';
        div_title.style = 'border-bottom-color: #d6d7d9';
        pm.innerHTML =
          '<span class="accordion-triggerPlusIcon"><svg fill="#00599C" preserveAspectRatio="xMidYMid meet" height="24px" width="24px" viewBox="0 0 20 20"><path d="M15.8333335,10.8333335 C15.8333335,11.2890625 15.455729,11.6666669 15,11.6666669 L11.6666669,11.6666669 L11.6666669,15 C11.6666669,15.455729 11.2890625,15.8333335 10.8333335,15.8333335 L9.16666654,15.8333335 C8.71093752,15.8333335 8.33333307,15.455729 8.33333307,15 L8.33333307,11.6666669 L5,11.6666669 C4.54427098,11.6666669 4.16666654,11.2890625 4.16666654,10.8333335 L4.16666654,9.16666654 C4.16666654,8.71093752 4.54427098,8.33333307 5,8.33333307 L8.33333307,8.33333307 L8.33333307,5 C8.33333307,4.54427098 8.71093752,4.16666654 9.16666654,4.16666654 L10.8333335,4.16666654 C11.2890625,4.16666654 11.6666669,4.54427098 11.6666669,5 L11.6666669,8.33333307 L15,8.33333307 C15.455729,8.33333307 15.8333335,8.71093752 15.8333335,9.16666654 L15.8333335,10.8333335 Z M10,19 C14.9705627,19 19,14.9705627 19,10 C19,5.02943725 14.9705627,1 10,1 C5.02943725,1 1,5.02943725 1,10 C1,14.9705627 5.02943725,19 10,19 Z M10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 Z" /></svg></span><font size:"5" class="px-2" color="#1b1b1b">' +
          alttext +
          '</font>';
        pm.setAttribute('alt', expandText + ' ' + alttext);
      }
    },
  },
};
</script>
<style>
body {
  --primary-font-color: #383838;
  --primary-text-color: #383838;
  --primary-font-family: Bree Serif, Public Sans, Helvetica, Arial, sans-serif;
  --primary: #296d97;
  --v-primary-base: #296d97;
  --secondary: #fdb71a;
  --v-secondary-base: #fdb71a;
  --tertiary: #66b3ff;
  --light: #f4f8fa;
  --grey: #e9f1f5;
  --text-light: #f0f0f0;
  --success: #1be632;
  width: 100%;
  height: 100%;
  background: #296d97;
  border-bottom: none;
}
</style>
<style scoped>
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.header {
  background-color: #00599c !important;
}
:deep(.v-toolbar__content) {
  align-items: end;
}
.page-content {
  flex: 100;
  background-color: #fff !important;
  padding: 3rem;
}
.page-hr {
  border-top: 4px solid #f7b63a;
  opacity: 1;
}
.font-primary {
  font-family: Bree Serif;
}
.irs-logo {
  height: 36px;
  margin-left: 9%;
}
.w-85 {
  width: 85%;
}
.info-div {
  border-left: 3px solid #087591;
  background-color: #e7f6f8;
  padding: 24px;
  margin: 16px 0;
  color: #1b1b1b;
}
.info-div-heading {
  text-transform: none;
  color: #1b1b1b;
  line-height: 28px;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 20px;
}
.info-div-text {
  margin: 0 0 0 24px;
  padding: 0 0 0 12px;
}
.intro-panel {
  box-sizing: border-box;
  border: 1px solid #d6d7d9;
  margin-bottom: 0px;
  padding: 16px;
  line-height: 24px;
  background: #fff;
  font-size: 16px;
}

p {
  font-size: 16px;
}
.login-btn-header {
  font: bold;
  font-size: 20px;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  width: 300px;
  padding: 10px 0px;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  flex-basis: auto;
  border-bottom: 2px solid #000;
}

.separator-spacer {
  padding: 0 10px;
}

.collapseableTitlePanel {
  display: flex;
  margin: 0;
  padding: 20px;
  text-decoration: none;
  line-height: 28px;
  border: 1px solid #d6d7d9;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: -15px;
}

.collapseablePanel {
  box-sizing: border-box;
  border: 1px solid #d6d7d9;
  border-top-color: #fff;
  margin-top: 0px;
  padding: 20px;
  padding-top: 0px;
  line-height: 24px;
  background: #fff;
  font-size: 16px;
  margin-bottom: -15px;
}
.panel-heading {
  font-weight: bold;
  font-size: 20px;
}

.tiny-text {
  font-size: 12px;
  line-height: 16px;
}
</style>

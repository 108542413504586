<template>
  <v-app>
    <div class="page-container d-md-flex mt-4">
      <div class="inner-container d-flex justify-content-center gap-lg-2 align-items-center flex-xl-row">
        <div class="info-pane col-12 col-md-5">
          <EddLeftInfo :textDisplay="textDisplay" />
        </div>
        <div class="login-pane col-12 col-md-7 d-flex flex-column">
          <div class="d-md-none text-center">
            <img alt="Treasury Logo" :src="treasuryLogo" class="treasury-logo" />
            <div class="h4 mt-4">
              Access account with <img alt="Socure Logo" :src="socureLogo" class="socure-logo" />
            </div>
          </div>
          <div class="page-content">
            <div class="m-4 d-none d-md-flex">
              <div class="d-flex align-items-center flex-wrap justify-content-start">
                <a class="btn-socure btn-socure-primary d-none d-md-block" @click="backToIrs">Back to IRS.gov</a>
              </div>
            </div>
            <div class="m-4" v-if="step === 1">
              <h1 class="fw-bold brand-text-primary">Schedule ID Verification Appointment</h1>
              <div class="text-start">Please select a day and time for an ID Verification appointment appointment.</div>
              <div class="text-start d-flex flex-column gap-3 py-5">
                <div class="appointment-label">
                  <span class="text-danger">*</span>
                  <b>Date for Appointment</b>
                </div>
                <div>
                  <date-field
                    @date-field-selected="setSelectedDate"
                    :min_date="today"
                    :max_date="addDaysToDate(today, 7)"
                    :disallowWeekends="true"
                  >
                  </date-field>
                </div>
              </div>
              <div class="text-start d-flex flex-column gap-3 py-5">
                <div class="appointment-label">
                  <span class="text-danger">*</span>
                  <b>Appointment Time of Day</b>
                </div>
                <div class="time-div">
                  <v-btn
                    @click="setSelectedTimeOfDay('AM')"
                    class="time-btn font-primary"
                    :class="selectedTimeOfDay === 'AM' ? 'time-btn-selected' : ''"
                    >AM</v-btn
                  >
                  <v-btn
                    @click="setSelectedTimeOfDay('PM')"
                    class="time-btn font-primary"
                    :class="selectedTimeOfDay === 'PM' ? 'time-btn-selected' : ''"
                    >PM</v-btn
                  >
                </div>
              </div>
              <div class="text-start d-flex flex-column gap-3 py-5">
                <div class="appointment-label">
                  <span class="text-danger">*</span>
                  <b>Appointment Time</b>
                  <div>Times are scheduled in Eastern Standard Time(EST)</div>
                </div>
                <div class="time-div">
                  <v-btn
                    v-for="(time, index) in timeOptions"
                    :key="index"
                    @click="setSelectedTime(time)"
                    class="time-btn font-primary"
                    :class="time === selectedTime ? 'time-btn-selected' : ''"
                    >{{ time }}</v-btn
                  >
                </div>
              </div>
              <div class="text-center">
                <v-btn
                  class="my-3 btn-socure large btn-socure-secondary"
                  :disabled="selectedTime === '' || selectedDate === null"
                  @click="setMeetingDate"
                  >Schedule Appointment</v-btn
                >
                <v-btn class="btn-socure btn-socure-primary" @click="goToLanding">Back a Step</v-btn>
              </div>
            </div>
            <div class="m-4" v-if="step === 2">
              <h1 class="fw-bold brand-text-primary">Schedule ID Verification Appointment</h1>
              <div class="row">
                <div class="col-3">
                  <i aria-hidden="true" class="far fa-circle-check fa-4x text-success"></i>
                </div>
                <div class="col-8 text-start">You have successfully scheduled an ID verification appointment for:</div>
              </div>
              <div class="text-start py-2">
                <div>
                  <h2 class="fw-bold brand-text-primary">{{ appointmentTime }}</h2>
                </div>
                <div>
                  We will email an invite with details to <strong> {{ email }} </strong>
                </div>
              </div>
              <v-btn class="my-3 btn-socure large btn-socure-secondary" @click="step = 3">Manage Appointment</v-btn>
            </div>
            <div class="m-4" v-if="step === 3">
              <h1 class="fw-bold brand-text-primary">Manage ID Verification Appointment</h1>
              <div class="text-start">You have ID verification appointment scheduled on:</div>
              <div class="text-start py-2">
                <div>
                  <h2 v-if="loading" class="fw-bold brand-text-primary">Loading ...</h2>
                  <h2 v-else class="fw-bold brand-text-primary">{{ appointmentTime }}</h2>
                </div>
                <div v-if="!loading">
                  For the account with : <strong> {{ email }} </strong>
                </div>
              </div>
              <v-btn class="my-3 btn-socure large btn-socure-secondary" @click="step = 1">Reschedule Appointment</v-btn>
              <v-btn class="my-3 btn-socure large btn-socure-secondary" @click="showCancelDialog = true"
                >Cancel Appointment</v-btn
              >
              <v-btn class="my-3 btn-socure large btn-socure-secondary" @click="goMeeting"
                >Join a Waiting Room Now</v-btn
              >
            </div>
            <div class="m-4" v-if="step === 4">
              <h1 class="fw-bold brand-text-primary">Canceled Appointment</h1>
              <div class="text-start">You have <b>canceled</b> your ID verification appointment scheduled on:</div>
              <div class="text-start py-2">
                <div>
                  <h2 class="fw-bold brand-text-primary">{{ appointmentTime }}</h2>
                </div>
                <div>This wil impact your ability to create an account for:</div>
              </div>
              <v-btn class="my-3 btn-socure large btn-socure-secondary" @click="step = 1">Reschedule Appointment</v-btn>
              <v-btn class="my-3 btn-socure large btn-socure-secondary" @click="goMeeting"
                >Join a Waiting Room Now</v-btn
              >
              <a class="btn-socure btn-socure-primary d-none d-md-block" @click="backToIrs">Back to IRS.gov</a>
            </div>
          </div>
        </div>
      </div>
      <v-dialog v-model="showCancelDialog" width="500px">
        <div class="dialog-header">
          <div class="fs-3">Confirm Cancelation</div>
        </div>
        <div class="dialog-text">
          <div>
            Please confirm that you would like to cancel the appointment for <b></b> on <b>{{ appointmentTime }}</b
            >.
          </div>
        </div>
        <div class="dialog-buttons text-center">
          <v-btn class="my-3 btn-socure large btn-socure-secondary" @click="cancelAppointment"
            >Cancel Appointment</v-btn
          >
          <v-btn class="btn-socure btn-socure-primary" @click="showCancelDialog = false"
            >No, I do not wish to cancel</v-btn
          >
        </div>
      </v-dialog>
    </div>
  </v-app>
</template>
<script>
import DateMixin from '../../mixins/DateMixin';
import DateField from '../../../lender-vue/vue-components/DateField.vue';
import EddLeftInfo from './EddLeftInfo.vue';

export default {
  props: {
    id: String,
  },
  components: { DateField, EddLeftInfo },
  mixins: [DateMixin],
  data() {
    return {
      socureLogo: require('../../../img/socure-logo.png'),
      treasuryLogo: require('../../../img/USDOT-Crest-Dark.png'),
      step: this.$route.params.step ?? 1,
      selectedDate: null,
      selectedTime: '',
      timeOptions: ['8:00 AM', '8:30 AM', '9:00 AM', '9:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM'],
      selectedTimeOfDay: 'AM',
      showCancelDialog: false,
      email: '',
      setDateOfAppoitment: null,
      loading: false,
    };
  },
  computed: {
    appointmentTime() {
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'America/Los_Angeles',
        hour: 'numeric',
        minute: 'numeric',
      };
      if (this.selectedDate) {
        return this.getSelectedDateAsDateObject().toLocaleString('en-US', options);
      } else {
        return new Date(`${this.setDateOfAppoitment}`).toLocaleString('en-US', options);
      }
    },
    textDisplay() {
      return `Your account for <b>${this.email}</b> is pending identity verification.`;
    },
  },
  methods: {
    backToIrs() {
      window.open('/');
    },
    goMeeting() {
      this.$router.push({ name: 'EddMeetingPage', params: { id: this.id } });
    },
    goToLanding() {
      this.$router.push({ name: 'EddLandingPage' });
    },
    setSelectedTime(time) {
      this.selectedTime = time;
    },
    setSelectedTimeOfDay(val) {
      this.selectedTimeOfDay = val;
    },
    setSelectedDate(val) {
      console.log(val);
      this.selectedDate = val;
    },
    getSelectedDateAsDateObject() {
      let time = this.selectedTime.replace(' AM', '');
      if (time.length == 4) time = `0${time}`;
      const timestr = `${this.selectedDate}T${time}:00.000-07:00`;
      return new Date(timestr);
    },
    async setMeetingDate() {
      this.step++;
      const date = this.getSelectedDateAsDateObject();
      try {
        const data = {
          meeting_date: date.toISOString(),
        };
        axios.post(`/api/id-cases/${this.id}/schedule_case/`, data);
      } catch (err) {
        console.error(err);
      }
    },
    async cancelAppointment() {
      this.step = 4;
      //cancel api here
    },
  },
  async created() {
    try {
      this.loading = true;
      const res = await axios.get(`/api/id-cases/${this.id}`);
      this.caseData = res.data;
      console.log(res.data);
      this.email = res.data.user_email;
      this.setDateOfAppoitment = res.data?.meeting_date;
      this.loading = false;
    } catch (err) {
      console.error(err);
    }
  },
};
</script>
<style>
@import '../../../css/stepper.css';
body {
  --primary-font-color: #383838;
  --primary-text-color: #383838;
  --primary-font-family: Bree Serif, Public Sans, Helvetica, Arial, sans-serif;
  --primary: #002e6d;
  --v-primary-base: #296d97;
  --secondary: #fdb71a;
  --v-secondary-base: #fdb71a;
  --tertiary: #66b3ff;
  --light: #f4f8fa;
  --grey: #e9f1f5;
  --text-light: #f0f0f0;
  --success: #1be632;
  width: 100%;
  height: 100%;
  background: #002e6d;
  border-bottom: none;
}
</style>
<style scoped>
.page-container {
  min-height: calc(100vh - 20px);
  justify-content: center !important;
  align-items: flex-end;
  width: 100%;
}
.inner-container {
  max-width: 1400px;
}
:deep(.v-toolbar__content) {
  align-items: end;
}
.page-content {
  flex: 100;
  background-color: #f4f8fa;
  text-align: center;
  min-height: 800px;
}
.font-primary {
  font-family: Bree Serif;
}
.font-secondary {
  font-family: Public Sans;
}
.time-btn {
  text-transform: capitalize;
  border-radius: 4px;
  box-shadow: none !important;
  font-weight: 100 !important;
  font-size: 16px !important;
}

.time-btn {
  background-color: #fff !important;
  border: 4px solid var(--lc-background-color) !important;
  margin: -2px 0;
}
.time-btn-selected {
  background-color: var(--lc-primary) !important;
  border: 3px solid var(--primary) !important;
  color: var(--lc-text-light) !important;
}
.appointment-label {
  margin: -10px 0;
}
.date-picker {
  margin-bottom: -14px;
  width: 100%;
}
.treasury-logo {
  width: 70%;
}
:deep(.v-picker__title.primary) {
  background-color: var(--lc-primary) !important;
}
.btn-socure-primary {
  box-shadow: none;
  border-radius: 0px;
  background: none !important;
}
.dialog-header {
  display: flex;
  background-color: #fafafa;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
}
.dialog-header > div {
  font-weight: 600;
}
.dialog-text {
  display: flex;
  background-color: #fff;
  padding: 8px 16px;
  border-bottom: 1px solid #e8e8e8;
}
.dialog-buttons {
  padding: 10px 16px;
  background-color: #fafafa;
}

.login-pane {
  min-height: 800px;
  margin-left: auto;
}
.btn-socure {
  text-transform: none !important;
}
@media screen and (min-width: 769px) {
  .page-content {
    text-align: left;
    padding: 2rem;
  }
  .page-container {
    margin: -12px;
  }
  .time-div {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1rem;
  }
  .login-pane {
    max-width: 700px;
  }
}
@media screen and (max-width: 768px) {
  .info-pane {
    display: none;
  }
  .time-div {
    display: contents;
  }
}
</style>

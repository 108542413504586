<template>
  <v-form ref="step7form">
    <div class="p-2">
       <div class="d-flex flex-row flex-wrap justify-content-start">
        <div class="info-box col-md-6" v-if="isPropertyDamage">
           <label class="pb-1 mandatory"><small>{{$t('lossverificationStep7Question1')}}</small></label>
          <div class="pb-1 text-primary">{{property_type}}</div>
        </div>
        <div class="info-box col-md-6" v-if="isPropertyDamage">
          <label class="pb-1 mandatory"><small>{{$t('lossverificationStep7Question2')}}</small></label>
          <div class="pb-1 text-primary">{{property_sq_footage}} square ft</div>
        </div>
        <div class="info-box col-md-6" v-if="isPropertyDamage">
          <label class="pb-1 mandatory"><small>{{$t('lossverificationStep7Question3')}}</small></label>
          <div class="pb-1 text-primary">{{isTotalDestroy}}</div>
        </div>
        <div class="info-box col-md-6" v-if="isPropertyDamage">
          <label class="pb-1 mandatory"><small>{{$t('lossverificationStep7Question4')}}</small></label>
          <div class="pb-1 text-primary">{{getDamageLevel}}</div>
        </div>
        <div class="info-box col-md-6">
          <label class="pb-1 mandatory"><small>{{$t('lossverificationStep7Question5')}}</small></label>
          <div class="pb-1 text-primary">{{isPersonalDamage}}</div>
        </div>
        <div class="info-box col-md-6" v-if="isPersonalDamage == 'Yes'">
          <label class="pb-1 mandatory"><small>{{$t('lossverificationStep7Question5_1')}}</small></label>
          <div class="pb-1 text-primary">{{getPersonalDamageLevel}}</div>
        </div>
        <div class="info-box col-md-6">
          <label class="pb-1 mandatory"><small>{{$t('lossverificationStep7Question9')}}</small></label>
          <div class="pb-1 text-primary">{{flood_type}}</div>
        </div>
        <div class="info-box col-md-6">
          <label class="pb-1 mandatory"><small>{{$t('lossverificationStep7Question6')}}</small></label>
          <div class="pb-1 text-primary">{{automobileDamage}}</div>
        </div>
      </div>
      <div v-if="automobileDamage == 'Yes'"> 
        <div class="d-flex" v-for='item in automobileData' :key='item.ref_num'>
           <div class="info-box col-md-6"  >
              <label class="pb-1 mandatory"><small>Automobile {{item.autoMobileNumberStr}}</small></label>
              <div class="pb-1 text-primary" v-if="item.isVinNumber">Vin# {{item.vinNumber}}</div>
              <div class="pb-1 text-primary" v-if="!item.isVinNumber">{{item.selectedYear}} {{item.selectedMake}} {{item.selectedModel}}</div>
          </div>
          <div class="info-box col-md-6"  >
              <label class="pb-1 mandatory"><small>{{ $t('lossverificationStep7Question7')}} {{item.autoMobileNumberStr}}</small></label>
              <div class="pb-1 text-primary">{{item.damageRepair ? 'Repair' : 'Replacement'}}</div>
          </div>
        </div>
      </div>
    </div>
  </v-form>
</template>

<script>

export default {

  mixins: [],
  components: {
  },
  props: {
    allData:Object
  },
  data() {
    return {
      property_type:'',
      property_sq_footage: '',
      isTotalDestroy:'',
      damageLevel:'',
      personalDamageLevel:'',
      automobileDamage:'',
      flood_type:'',
      isPersonalDamage: '',
      isPropertyDamage:false
    };
  },
  watch: {
    allData: function () {
       this.isPropertyDamage = this.allData?.step1Obj?.isPropertyDamage;
       this.property_type = this.allData?.step2Obj?.property_type;
       this.property_sq_footage = this.allData?.step2Obj?.property_sq_footage;
       this.damageLevel = this.allData?.step3Obj?.damageLevel;
       this.isTotalDestroy = this.allData?.step3Obj?.damageLevel == 3 ? 'Yes' : 'No';
       this.isPersonalDamage = this.allData?.step5Obj?.personalDamageLevel == 0 ? 'No' : 'Yes';
       this.personalDamageLevel = this.allData?.step5Obj?.personalDamageLevel;
       this.automobileDamage = this.allData?.step6Obj?.automobileDamage ? 'Yes' : 'No';
       this.flood_type = this.allData?.step4Obj?.flood_type != null ? this.allData?.step4Obj?.flood_type : 'None Selected';
      
    },
  },
  computed:{
    automobileData(){
      let _data = {};
        Object.keys(this.allData).forEach((key,i) => {
          if(this.allData[key]?.ref_num){
            _data[key] = { ...this.allData[key]};
          }
        });
       return _data;
    },
    getDamageLevel(){
      let _damageLevel = ''
      switch(this.damageLevel){
        case 0: _damageLevel =  'Low';
          break;
        case 1: _damageLevel =  'Medium';
          break;
        case 2: _damageLevel =  'High';
          break;
        case 3: _damageLevel =  'Destroyed';
          break;
      }
      return _damageLevel;
    },
    getPersonalDamageLevel(){
      let _damageLevel = ''
      switch(this.personalDamageLevel){
        case 1: _damageLevel =  'Low';
          break;
        case 2: _damageLevel =  'Medium';
          break;
        case 3: _damageLevel =  'High';
          break;
        case 4: _damageLevel =  'Destroyed';
          break;
      }
      return _damageLevel;
    }
  }
};
</script>

export default {
  data() {
    return {
      accountViewTIN: null,
    };
  },
  computed: {
    isAccountView() {
      return this.accountViewTIN !== null;
    },
    accountViewUrl() {
      return `/user_borrower/?borrower_identifier=${this.accountViewTIN}`;
    },
    accountViewDisableProps() {
      return this.isAccountView ? 'btn-link disabled-link pe-none' : '';
    },
  },
  created() {
    if (typeof (window.accountViewTIN) !== 'undefined' && window.accountViewTIN !== '') {
      this.accountViewTIN = window.accountViewTIN;
    }
  },
};

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "row content-container mt-4 justify-content-center",
      staticStyle: { margin: "0 auto" },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex justify-content-center gap-lg-2 align-items-center flex-column flex-xl-row",
          staticStyle: { "margin-top": "12px" },
        },
        [
          _c("div", { staticClass: "info-pane col-12 col-xl-5 mb-6" }, [
            _c("img", {
              staticClass: "logo-img",
              attrs: {
                alt: "U.S. Small Business Administration",
                src: "/static/img/SBA-Logo-Horizontal-Reverse.png",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "h5 mt-4" }, [
              _vm._v(_vm._s(_vm.$t("safeInfo"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mt-4 mb-2 sba-help-text" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.$t("sbaProtectData")) + "\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "mt-5 row flex-nowrap flex-column align-items-center flex-md-row",
                attrs: { id: "detailsContainer" },
              },
              [
                !_vm.noMessages
                  ? _c("div", { staticClass: "col-2 circle-border mb-2" }, [
                      _c("i", {
                        staticClass:
                          "fa-regular fa-exclamation-triangle fa-2x text-danger",
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.noMessages
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-10 d-flex flex-column justify-content-start flex-shrink-1",
                        staticStyle: { width: "fit-content" },
                      },
                      [
                        _c("div", { staticStyle: { "margin-left": "12px" } }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "w-100 justify-content-center justify-content-lg-start h4 row mb-0",
                            },
                            [_vm._v(_vm._s(_vm.$t("accountNeedstoBeVerified")))]
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "login-pane col-12 col-xl-7 d-flex flex-column" },
            [
              _c(
                "div",
                {
                  staticClass: "mt-5 d-flex flex-column",
                  staticStyle: { flex: "100%" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-between left-nav-header flex-wrap",
                    },
                    [
                      _c("span", { staticClass: "brand-text-primary h2" }, [
                        _vm._v(_vm._s(_vm.$t("accountVerification"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-white brand-text-primary",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.handleSignOut.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("signOut")))]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("dashboard-messages", {
                    on: { "set-no-messages": _vm.handleSetNoMessages },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm._m(0),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      {
        staticClass: "small mb-3 mt-auto",
        staticStyle: { padding: "0 2.4rem" },
      },
      [
        _c(
          "a",
          {
            staticClass: "primary-link",
            attrs: { href: "https://www.sba.gov/" },
          },
          [_vm._v("U.S. Small Business Administration ")]
        ),
        _vm._v(" | 409 3rd St, SW. Washington DC 20416 | Tel - "),
        _c(
          "a",
          {
            staticClass: "text-primary underline-brand-secondary",
            attrs: { href: "tel:+18335720502" },
          },
          [_vm._v("1-833-572-0502")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
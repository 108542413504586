var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-form", { ref: "step3form" }, [
    _c(
      "div",
      { staticClass: "loss-slider-container" },
      [
        _c("div", [
          _c("label", { staticClass: "pb-1" }, [
            _vm._v(_vm._s(_vm.$t("lossverificationStep3Question")) + " "),
          ]),
        ]),
        _vm._v(" "),
        _c("v-slider", {
          staticClass: "loss-slider",
          attrs: {
            "tick-labels": _vm.ticksLabels,
            max: 3,
            step: "1",
            ticks: "always",
            "tick-size": "4",
          },
          scopedSlots: _vm._u([
            {
              key: "thumb-label",
              fn: function (props) {
                return [
                  _c(
                    "div",
                    { staticClass: "loss-tooltip" },
                    [
                      props.value == 0 ? _c("loss-low") : _vm._e(),
                      _vm._v(" "),
                      props.value == 1 ? _c("loss-medium") : _vm._e(),
                      _vm._v(" "),
                      props.value == 2 ? _c("loss-high") : _vm._e(),
                      _vm._v(" "),
                      props.value == 3 ? _c("loss-destroyed") : _vm._e(),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.getTooltipTitle(props.value))),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(_vm._s(_vm.getTooltipText(props.value))),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.step3Data.damageLevel,
            callback: function ($$v) {
              _vm.$set(_vm.step3Data, "damageLevel", $$v)
            },
            expression: "step3Data.damageLevel",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
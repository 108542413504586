var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-form", { ref: "step7form" }, [
    _c("div", { staticClass: "p-2" }, [
      _c(
        "div",
        { staticClass: "d-flex flex-row flex-wrap justify-content-start" },
        [
          _vm.isPropertyDamage
            ? _c("div", { staticClass: "info-box col-md-6" }, [
                _c("label", { staticClass: "pb-1 mandatory" }, [
                  _c("small", [
                    _vm._v(_vm._s(_vm.$t("lossverificationStep7Question1"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "pb-1 text-primary" }, [
                  _vm._v(_vm._s(_vm.property_type)),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isPropertyDamage
            ? _c("div", { staticClass: "info-box col-md-6" }, [
                _c("label", { staticClass: "pb-1 mandatory" }, [
                  _c("small", [
                    _vm._v(_vm._s(_vm.$t("lossverificationStep7Question2"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "pb-1 text-primary" }, [
                  _vm._v(_vm._s(_vm.property_sq_footage) + " square ft"),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isPropertyDamage
            ? _c("div", { staticClass: "info-box col-md-6" }, [
                _c("label", { staticClass: "pb-1 mandatory" }, [
                  _c("small", [
                    _vm._v(_vm._s(_vm.$t("lossverificationStep7Question3"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "pb-1 text-primary" }, [
                  _vm._v(_vm._s(_vm.isTotalDestroy)),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isPropertyDamage
            ? _c("div", { staticClass: "info-box col-md-6" }, [
                _c("label", { staticClass: "pb-1 mandatory" }, [
                  _c("small", [
                    _vm._v(_vm._s(_vm.$t("lossverificationStep7Question4"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "pb-1 text-primary" }, [
                  _vm._v(_vm._s(_vm.getDamageLevel)),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "info-box col-md-6" }, [
            _c("label", { staticClass: "pb-1 mandatory" }, [
              _c("small", [
                _vm._v(_vm._s(_vm.$t("lossverificationStep7Question5"))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pb-1 text-primary" }, [
              _vm._v(_vm._s(_vm.isPersonalDamage)),
            ]),
          ]),
          _vm._v(" "),
          _vm.isPersonalDamage == "Yes"
            ? _c("div", { staticClass: "info-box col-md-6" }, [
                _c("label", { staticClass: "pb-1 mandatory" }, [
                  _c("small", [
                    _vm._v(_vm._s(_vm.$t("lossverificationStep7Question5_1"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "pb-1 text-primary" }, [
                  _vm._v(_vm._s(_vm.getPersonalDamageLevel)),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "info-box col-md-6" }, [
            _c("label", { staticClass: "pb-1 mandatory" }, [
              _c("small", [
                _vm._v(_vm._s(_vm.$t("lossverificationStep7Question9"))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pb-1 text-primary" }, [
              _vm._v(_vm._s(_vm.flood_type)),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "info-box col-md-6" }, [
            _c("label", { staticClass: "pb-1 mandatory" }, [
              _c("small", [
                _vm._v(_vm._s(_vm.$t("lossverificationStep7Question6"))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pb-1 text-primary" }, [
              _vm._v(_vm._s(_vm.automobileDamage)),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.automobileDamage == "Yes"
        ? _c(
            "div",
            _vm._l(_vm.automobileData, function (item) {
              return _c("div", { key: item.ref_num, staticClass: "d-flex" }, [
                _c("div", { staticClass: "info-box col-md-6" }, [
                  _c("label", { staticClass: "pb-1 mandatory" }, [
                    _c("small", [
                      _vm._v("Automobile " + _vm._s(item.autoMobileNumberStr)),
                    ]),
                  ]),
                  _vm._v(" "),
                  item.isVinNumber
                    ? _c("div", { staticClass: "pb-1 text-primary" }, [
                        _vm._v("Vin# " + _vm._s(item.vinNumber)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !item.isVinNumber
                    ? _c("div", { staticClass: "pb-1 text-primary" }, [
                        _vm._v(
                          _vm._s(item.selectedYear) +
                            " " +
                            _vm._s(item.selectedMake) +
                            " " +
                            _vm._s(item.selectedModel)
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "info-box col-md-6" }, [
                  _c("label", { staticClass: "pb-1 mandatory" }, [
                    _c("small", [
                      _vm._v(
                        _vm._s(_vm.$t("lossverificationStep7Question7")) +
                          " " +
                          _vm._s(item.autoMobileNumberStr)
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "pb-1 text-primary" }, [
                    _vm._v(
                      _vm._s(item.damageRepair ? "Repair" : "Replacement")
                    ),
                  ]),
                ]),
              ])
            }),
            0
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
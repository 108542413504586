export default {
  filters: {
    isamount(value, withCents = true) {
      if (value === undefined || value === null) return;
      const nf = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: (withCents ? 2 : 0),
      });
      if (value === 0) return `$${nf.format(0)}`;
      if (Number.isNaN(value)) return;
      return `$${nf.format(value)}`;
    },
    dateTimeExpiry(value, dummy) {
      if (!value) return;
      var today = new Date(value);
      var mm = today.getMonth()+1; 
      var yyyy = today.getFullYear().toString().substr(-2);
      if(mm<10) 
      {
          mm='0'+mm;
      } 
      
      today = mm+'/'+yyyy;
      return today;
    },

    dateTime(value, withTime = true, isUTC = false, withoutTimeZone = false) {
      if (!value) return;
      if (withTime) {
        const timeOptions = {
          year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short',
        };
        if (withoutTimeZone) {
          delete timeOptions.timeZoneName;
        }
        return new Date(value).toLocaleString('en-US', timeOptions);
      }
      if (!withTime && isUTC) return new Date(value).toLocaleString('en-US', { timeZone: 'UTC', dateStyle: 'medium' });
      return new Date(value).toLocaleString('en-US', { dateStyle: 'medium' });
    },
    percentage(value) {
      if (!value) return;
      if (isNaN(value)) return;
      const nf = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
      });
      return `${nf.format(value)}%`;
    },
    boolToString(value) {
      if (value) return 'Yes';
      return 'No';
    },
    capitalize(value) {
      if (!value) return '';
      const tmpValue = value.toString();
      return tmpValue.charAt(0).toUpperCase() + tmpValue.slice(1);
    },
    capitalizeAll(value) {
      if (!value) return '';
      const arr = value.split(' ');
      const arr1 = arr.map((str) => str.charAt(0).toUpperCase() + (['KYC', 'FICO', 'FACTA', 'UCC'].includes(str) ? str.substr(1) : str.substr(1).toLowerCase()));

      const str = arr1.join(' ');
      if (str.includes('/')) {
        const arrSlash = str.split('/');
        const arrSlash1 = arrSlash.map((s) => s.charAt(0).toUpperCase() + (['KYC', 'FICO', 'FACTA', 'UCC'].includes(s) ? s.substr(1) : s.substr(1)));
        return arrSlash1.join('/');
      }

      return str;
    },
    uppercase(value) {
      if (!value) return '';
      const tmpValue = value.toString();
      return tmpValue.toUpperCase();
    },
    printinbrackets(value) {
      if (!value) return '';
      const tmpValue = value.toString();
      return `(${tmpValue.toUpperCase()})`;
    },
    removeUnderscores(value) {
      if (!value) return '';
      return value.replaceAll('_', ' ').trim();
    },
    formatPhoneNumber(value) {
      const cleaned = (`${value}`).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (Array.isArray(match) && match.length >= 4) {
        const [_, areaCode, exchangeCode, subscriberNumber] = match;
        return `(${areaCode}) ${exchangeCode}-${subscriberNumber}`;
      }
      return value;
    },
    yesNo(value) {
      return value
        ? 'yes'
        : 'no';
    },
    ccMask(value) {
      const cleaned = /(X{4})(X{4})(X{4})(\d{4})/i.exec(value);
      if (cleaned && cleaned.length) {
        return cleaned
          .slice(1, 5)
          .map((str) => str.replace(/x/gi, '*'))
          .join(' ');
      }
      return value;
    },
    accountMask(value) {
      if (typeof value === 'string') {
        return value.replace(/x/gi, '*');
      }
      return value;
    },
    toDateFormatENUS(value, options = {}) {
      // Input: Either a date object, or a string that will be converted to a date object
      // Output: The date value in en-US format, ex: "MM/DD/YYYY"
      if (!value) return value;
      let val = value;
      if (!(val instanceof Date)) {
        val = new Date(value);
      }
      return val.toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        ...options,
      });
    },
    maskPhoneNumber(value) {
      const cleaned = `${value}`.replace(/\D/g, '');
      const match = cleaned.match(/^(\d{1})(\d{2})(\d{3})(\d{4})$/);
      if (Array.isArray(match) && match.length >= 4) {
        const [_, areaCodeSingleDigit, remainingAreaCode, exchangeCode, subscriberNumber] = match;
        return `(${areaCodeSingleDigit}**) ***-${subscriberNumber}`;
      }
      return value;
    },
  },
};

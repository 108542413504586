<template>
  <div>
    <v-stepper v-model="currentStep">
      <v-stepper-items>
        <v-form ref="counterOfferForm">
          <v-stepper-content step="2">
            <counter-offer-step2 :step2Obj.sync="step2Obj" :counter_offer_details="counter_offer_details"></counter-offer-step2>
          </v-stepper-content>
        </v-form>
      </v-stepper-items>
      <div class="d-flex justify-content-end gap-3">
        <div>
          <button class="btn btn-white pt-2" @click="goToBackStep()"><b>{{ backButtonLabel}}</b></button>
        </div>
        <div>
          <button class="btn btn-primary" @click="goToNextStep()">
            <span class="fw-semi-bold">{{ nextButtonLabel}}</span>
          </button>
        </div>
      </div>
    </v-stepper>
  </div>
</template>

<script>
import CounterOfferStep2 from "./CounterOfferStep2.vue";

export default {

  mixins: [],
  components: {
    'counter-offer-step2': CounterOfferStep2
  },
  props: {
    counter_offer_details: Array
  },
  data() {
    return {
      currentStep: 1,
      backButtonLabel: this.$t('closeMessage'),
      nextButtonLabel: this.$t('viewCounterOffers'),
      step2Obj: {},
      steps: [
        {
          form: 'step2form',
          skipValidation: false,
          dataObject: 'step2Obj',
        },
      ],
      allCounterOfferData:{}
    };
  },
  computed: {
    
  },
  methods: {
    validateCurrentStep() {
      const stepNo = this.currentStep - 2;
      if (!this.steps[stepNo].skipValidation && this.$refs.lossVerificationForm?.$children[stepNo]?.$children[0]?.$refs[this.steps[stepNo].form]) {
        if (!this.$refs.lossVerificationForm?.$children[stepNo]?.$children[0]?.$refs[this.steps[stepNo].form].validate()) {
          return false;
        }
      }
     let data;
        if (Array.isArray(this[this.steps[stepNo].dataObject])) 
        data = [...this[this.steps[stepNo].dataObject]];
          else data = { ...this[this.steps[stepNo].dataObject] };

          Object.keys(data).forEach((key)=>{
            this.allCounterOfferData[key] = data[key];
          })
          
      return true;
    },
    goToNextStep() {
        if(this.currentStep > 1){
            if (this.validateCurrentStep()) {
                let _dataObj = {
                  detail:this.allCounterOfferData,
                  action:'accept'
                }
                this.$emit("onSubmit", _dataObj);
            }
        }else {
            this.currentStep += 1;
            this.updateButtonLabel();
        }
       this.$emit("onFirstScreen", false)
    },
    goToBackStep() {
        this.$emit("closeMessage");
    },
    updateButtonLabel() {
      this.backButtonLabel = this.currentStep == 1 ? this.$t('closeMessage') : this.$t('cancel');
      this.nextButtonLabel = (this.currentStep == 7) ? this.$t('submit') : this.$t('viewCounterOffers');
    },
  },
  async mounted() {

  },
};
</script>

<style scoped>
.v-stepper {
  box-shadow: none !important;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("div", { staticClass: "page-container d-md-flex mt-4" }, [
        _c(
          "div",
          {
            staticClass:
              "inner-container d-flex justify-content-center gap-lg-2 align-items-center flex-xl-row",
          },
          [
            _c(
              "div",
              { staticClass: "info-pane col-12 col-md-5" },
              [_c("EddLeftInfo", { attrs: { isEmail: true, step: _vm.step } })],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "login-pane col-12 col-md-7 d-flex flex-column" },
              [
                _c("div", { staticClass: "d-md-none text-center" }, [
                  _c("img", {
                    staticClass: "treasury-logo",
                    attrs: { alt: "Treasury Logo", src: _vm.treasuryLogo },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "h4 mt-4" }, [
                    _vm._v("\n            Access account with "),
                    _c("img", {
                      staticClass: "socure-logo",
                      attrs: { alt: "Socure Logo", src: _vm.socureLogo },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "page-content" }, [
                  _c("div", { staticClass: "m-4 d-none d-md-flex" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center flex-wrap justify-content-between w-100",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "btn-socure btn-socure-primary d-none d-md-block",
                            on: { click: _vm.backToIrs },
                          },
                          [_vm._v("Back to IRS.gov")]
                        ),
                        _vm._v(" "),
                        _vm.step === 1
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "btn-socure btn-socure-primary d-none d-md-block",
                              },
                              [_vm._v("Create an account")]
                            )
                          : _c(
                              "a",
                              {
                                staticClass:
                                  "btn-socure btn-socure-primary d-none d-md-block",
                              },
                              [_vm._v("Sign in")]
                            ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "h1",
                    { staticClass: "fw-bold brand-text-primary m-4 mb-2" },
                    [_vm._v("Manage ID Verification Appointent")]
                  ),
                  _vm._v(" "),
                  _vm.step === 1
                    ? _c(
                        "div",
                        { staticClass: "m-4 mt-0" },
                        [
                          _c("div", { staticClass: "text-start" }, [
                            _vm._v(
                              "Please confirm your email to find your appointment."
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "FieldItem",
                            {
                              staticClass: "text-dark field-item mt-3",
                              attrs: { label: "Email", required: true },
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "mt-2",
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  solo: "",
                                  clearable: "",
                                  placeholder: "- - - - - - - - - - - - - - -",
                                },
                                model: {
                                  value: _vm.email,
                                  callback: function ($$v) {
                                    _vm.email = $$v
                                  },
                                  expression: "email",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-checkbox", {
                            attrs: {
                              color: "primary",
                              label: "Keep me signed in",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "my-2 btn-socure large btn-socure-secondary",
                              on: {
                                click: function ($event) {
                                  return _vm.getCode()
                                },
                              },
                            },
                            [_vm._v("Next")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.step === 2
                    ? _c(
                        "div",
                        { staticClass: "m-4 mt-0" },
                        [
                          _c("div", { staticClass: "text-start" }, [
                            _vm._v("Set up your account credentials."),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "brand-text-primary fs-5 lh-sm mt-2",
                            },
                            [
                              _vm._v(
                                "\n              Please provide the 6 digit security code\n              "
                              ),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.isfactorTotp
                                      ? "from your authenticator app "
                                      : "sent to"
                                  )
                                ),
                              ]),
                              _vm._v(" :\n              "),
                              _c(
                                "span",
                                { staticStyle: { "font-weight": "600" } },
                                [_vm._v(_vm._s(_vm.mfaDetail))]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "mt-2" }, [
                            _vm._v("Code "),
                            _c("span", { staticClass: "text-danger" }, [
                              _vm._v("*"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("v-otp-input", {
                            staticClass: "verification-code mt-2",
                            model: {
                              value: _vm.otpCode,
                              callback: function ($$v) {
                                _vm.otpCode = $$v
                              },
                              expression: "otpCode",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "my-3 btn-socure large btn-socure-secondary mt-4",
                              on: {
                                click: function ($event) {
                                  return _vm.verifyCode()
                                },
                              },
                            },
                            [_vm._v("Manage Appointment")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-100 d-flex justify-content-center",
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-socure btn-socure-primary",
                                  on: {
                                    click: function ($event) {
                                      _vm.step = 1
                                    },
                                  },
                                },
                                [_vm._v("Back to Email")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { "multi-line": _vm.multiLine, color: _vm.snackColor },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function ({ attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { text: "" },
                        on: {
                          click: function ($event) {
                            _vm.snackbar = false
                          },
                        },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v(" Close ")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [_vm._v("\n    " + _vm._s(this.snackMsg) + "\n\n    ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
/* eslint-disable no-new */
/* eslint-disable no-undef */
/* eslint-disable indent */
import Vue from 'vue';
import VueMask from 'v-mask';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import IdleVue from 'idle-vue';
import VueI18n from 'vue-i18n';
import VCurrencyField from 'v-currency-field';
import { VTextField } from 'vuetify/lib';
import { ProfilesModule, useAxios } from '@lenders-cooperative/los-app-ui-component-lib';

import store from './store/store';
import vuetify from './plugins/vuetify';
import router from './plugins/router';

import 'tiptap-vuetify/dist/main.css';
// Ready translated locale messages
import literals from './locales/locales';
import axiosAllowCache from '../lender-vue/helpers/axios-allow-cache';

store.registerModule('profiles', ProfilesModule);

Vue.component('v-text-field', VTextField);
const eventsHub = new Vue();

Vue.use(VCurrencyField, {
  locale: 'en-US',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true,
});

// LS 2232 - axios default headers. cache-control and pragma are already part of default.
axios.defaults.headers['Cache-Control'] = 'no-cache';
axios.defaults.headers.Pragma = 'no-cache';
axios.defaults.headers.Expires = 0;

const isEdd = window?.is_edd === true;
if (!isEdd) {
  useAxios(axios);
  store.dispatch('profiles/getMe');
}

if (typeof accountViewTIN !== 'undefined' && accountViewTIN !== '') {
  axios.defaults.headers.AccountViewTIN = accountViewTIN;
}

const controller = new AbortController();

axios.interceptors.request.use(
  (config) => {
    if (
      config.headers.hasOwnProperty('AccountViewTIN') &&
      config.headers?.AccountViewTIN !== 'undefined' &&
      config.headers?.AccountViewTIN !== ''
    ) {
      if (config.method.toLowerCase() !== 'get') {
        controller.abort();
        return new Promise(() => {});
      }
      return config;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

let sessionTimeout = 15;
if (session_timeout) sessionTimeout = session_timeout;
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: sessionTimeout * 60 * 1000, // 15 minutes
  startAtIdle: false,
});

Vue.use((Vue) => {
  Vue.prototype.$bubble = function $bubble(eventName, ...args) {
    // Emit the event on all parent components
    let component = this;
    do {
      component.$emit(eventName, ...args);
      component = component.$parent;
    } while (component);
  };
});

Vue.use(VueMask);
Vue.config.productionTip = false;
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi',
});
//
Vue.use(VueI18n);
// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en', // set locale
  messages: literals, // set locale messages
});

// axios
//   .get('/api/me/profile')
//   .then((res) => {
//     if (res.data) {
//       store.commit('addAboutMe', res.data);
//     }
//   })
//   .catch((err) => {
//     console.log('/api/me/profile err:', err);
//   });
// axios.get('/api/constance').then((res) => {
//   if (res.data) {
//     const data = Array.isArray(res.data) ? res.data : [res.data];
//     store.commit('addConstance', data);
//   }
// }).catch((err) => {
//   console.log('/api/constance err:', err);
// });
// let locale = 'en';
// // eslint-disable-next-line import/no-named-as-default-member
// axiosAllowCache.get('/api/profiles').then((res) => {
//   if (res.data && res.data.length) {
//     if (res.data[0].details && res.data[0].details.locale) locale = res.data[0].details.locale;
//     store.commit('saveLocale', locale);
//   }
// }).catch((err) => {
//   store.commit('saveLocale', locale);
// });
const me = {};

new Vue({
  el: '#app',
  i18n,
  vuetify,
  router,
  store,
  template: '<router-view :me="me" :key="$route.fullPath"></router-view>',
  data: {
    me,
  },
});

function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

if (getCookie('declaration_details')) {
  this.setCookie('declaration_details', '', 0);
}

<template>
  <v-form ref="step2form">
    <div class="p-2">
      <div><label class="pb-1">{{$t('lossverificationStep2Question')}}</label> </div>
      <div> <label class="pb-1 mandatory"><small><i class="fal fa-info-circle">
            </i>{{$t('lossverificationStep2ToolTip1')}}</small></label></div>
      <v-row>
        <v-col class="col-12 col-md-6">
          <label class="pb-1 mandatory"><small>{{$t('lossverificationStep2SubQuestion1')}}</small></label>
          <v-select v-model="step2Data.property_type" :items="property_types" solo outlined dense :placeholder="$t('lossverificationStep2ToolTip2')" :rules="mandatoryRule"></v-select>
        </v-col>
        <v-col class="col-12 col-md-6">
          <label class="pb-1 mandatory"><small>{{$t('lossverificationStep2SubQuestion2')}}</small></label>
          <v-text-field type="number"  v-model="step2Data.property_sq_footage" solo outlined dense :rules="mandatoryRule"></v-text-field>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>

export default {

  mixins: [],
  components: {
  },
  props: {
     step2Obj: Object,
  },
  data() {
    return {
      step2Data:{
        property_type:'',
        property_sq_footage:''
      },
      property_types: [
        this.$t('single_Story'),
        this.$t('multiLevel'),
        this.$t('multiFamilyHome'),
        this.$t('condominium'),
      ],
      mandatoryRule: [(v) => (v !== undefined && v !== null && v !== '') || this.$t('requiredField')],
    };
  },
  watch: {
    step2Data: {
      handler() {
        this.$emit('update:step2Obj', this.step2Data);
      },
      deep: true,
    },
  },
};
</script>

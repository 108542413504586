var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "thread-messages overflow-auto p-3 p-md-5 pt-0" },
        [
          _vm.onAssesmentScreen
            ? _c("div", { attrs: { id: "inbox-item-los" } }, [
                _c(
                  "ul",
                  { staticClass: "message-thread" },
                  _vm._l(
                    _vm.requestDetails.messages,
                    function (message, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          staticClass: "rounded p-2 px-3",
                          class: _vm.me === message.sender ? "my-response" : "",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between gap-3 align-top",
                            },
                            [
                              _c("div", [
                                _c("i", {
                                  staticClass:
                                    "fa-regular fa-comments-alt fa-lg brand-text-primary",
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "flex-grow-1" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-between flex-wrap",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "brand-text-primary fs-9",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(message.sender) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c("i", {
                                        staticClass:
                                          "fa-regular fa-clock fa-sm",
                                      }),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "timestamp" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("dateTime")(message.created)
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass: "pt-2 fs-9",
                                  domProps: {
                                    innerHTML: _vm._s(message.message),
                                  },
                                }),
                              ]),
                            ]
                          ),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "350" },
              model: {
                value: _vm.closeConfirmMessage,
                callback: function ($$v) {
                  _vm.closeConfirmMessage = $$v
                },
                expression: "closeConfirmMessage",
              },
            },
            [
              _c(
                "v-card",
                { staticClass: "confirm-dialog" },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "justify-content-center" },
                    [
                      _c("i", {
                        staticClass:
                          "fal fa-exclamation-circle fa-2x text-warning",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-card-text", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.$t("lossVerificationCloseMessage"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    { staticClass: "justify-content-center gap-3" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-white",
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              _vm.closeConfirmMessage = false
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.confirmLossVerificationClose(
                                _vm.requestDetails
                              )
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("confirm")))]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "350" },
              model: {
                value: _vm.isSuccess,
                callback: function ($$v) {
                  _vm.isSuccess = $$v
                },
                expression: "isSuccess",
              },
            },
            [
              _c(
                "v-card",
                {
                  staticClass:
                    "d-flex flex-column align-items-center p-5 gap-2",
                },
                [
                  _c("v-card-title", [
                    _c("i", {
                      staticClass:
                        "fa-regular fa-paper-plane fa-lg brand-text-primary",
                    }),
                  ]),
                  _vm._v(" "),
                  _c("v-card-text", { staticClass: "text-center" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("lossverificationSubmittedMsg"))),
                    ]),
                    _vm._v(" "),
                    _c("small", { staticClass: "d-block pt-5" }, [
                      _vm._v(_vm._s(_vm.$t("for_loss_at"))),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "brand-text-primary" }, [
                      _vm._v("8099 East Vale Street Sunnyvale, CA 94087"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("v-card-actions", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        on: {
                          click: function ($event) {
                            return _vm.confirmLossVerificationClose(
                              _vm.requestDetails
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("close")) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "px-0 pt-0" },
            [
              _vm.onAssesmentScreen ? _c("v-divider") : _vm._e(),
              _vm._v(" "),
              _c(
                "loss-verifiaction-stepper",
                _vm._g(
                  {
                    on: {
                      completeLater: _vm.getCompleteLater,
                      onAssesmentScreen: _vm.getOnAssesmentScreen,
                    },
                  },
                  _vm.$listeners
                )
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
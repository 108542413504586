var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-form", { ref: "step1form" }, [
    _c(
      "div",
      { staticClass: "p-2" },
      [
        _c("label", { staticClass: "pb-1" }, [
          _vm._v(_vm._s(_vm.$t("lossverificationStep1Question"))),
        ]),
        _vm._v(" "),
        _c(
          "v-radio-group",
          {
            attrs: { row: "", rules: _vm.mandatoryRule },
            on: {
              change: function ($event) {
                return _vm.validateNext()
              },
            },
            model: {
              value: _vm.step1Data.isSelfAssesment,
              callback: function ($$v) {
                _vm.$set(_vm.step1Data, "isSelfAssesment", $$v)
              },
              expression: "step1Data.isSelfAssesment",
            },
          },
          [
            _c("v-radio", { attrs: { label: _vm.$t("yes"), value: true } }),
            _vm._v(" "),
            _c("v-radio", { attrs: { label: _vm.$t("no"), value: false } }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.step1Data.isSelfAssesment
      ? _c(
          "div",
          { staticClass: "p-2" },
          [
            _c("label", { staticClass: "pb-1" }, [
              _vm._v(_vm._s(_vm.$t("lossverificationStep1QuestionRenters"))),
            ]),
            _vm._v(" "),
            _c(
              "v-radio-group",
              {
                attrs: { row: "", rules: _vm.mandatoryRuleProperty },
                on: {
                  change: function ($event) {
                    return _vm.checkPropertyDamage()
                  },
                },
                model: {
                  value: _vm.step1Data.isPropertyDamage,
                  callback: function ($$v) {
                    _vm.$set(_vm.step1Data, "isPropertyDamage", $$v)
                  },
                  expression: "step1Data.isPropertyDamage",
                },
              },
              [
                _c("v-radio", { attrs: { label: _vm.$t("yes"), value: true } }),
                _vm._v(" "),
                _c("v-radio", { attrs: { label: _vm.$t("no"), value: false } }),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "900px" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "p-5", attrs: { loading: _vm.loading } },
        [
          _c(
            "v-card-title",
            { staticClass: "text-h6 justify-center brand-text-primary" },
            [_vm._v(_vm._s(_vm.$t("documentUpload")))]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "m-4" },
            [
              _c(
                "div",
                { staticClass: "d-flex flex-column align-center" },
                [
                  _c("p", {
                    staticClass: "mb-1 attachment-text",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("attachmentTextDocAlt")),
                    },
                  }),
                  _vm._v(" "),
                  _c("p", {
                    staticClass: "mb-1",
                    domProps: { innerHTML: _vm._s(_vm.$t("doNotEncrypt")) },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "mb-1" }, [
                    _vm._v(_vm._s(_vm.$t("validFileTypesText")) + " "),
                    _c("strong", [
                      _vm._v(" pdf, xls, xlsx, csv, doc, docx, jpg, jpeg, png"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("v-divider"),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-form", { ref: "uploadForm" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-12 py-0" }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12 py-0" },
                    [
                      _c("v-file-input", {
                        staticClass: "documentInput",
                        attrs: {
                          label: _vm.$t("selectFile"),
                          "show-size": "",
                          outlined: "",
                          dense: "",
                          solo: "",
                          clearable: "",
                          rules: _vm.extnCheck,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prepend-inner",
                            fn: function () {
                              return [
                                _c(
                                  "v-icon",
                                  { staticStyle: { "z-index": "1" } },
                                  [_vm._v("mdi-folder-open")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.file,
                          callback: function ($$v) {
                            _vm.file = $$v
                          },
                          expression: "file",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-card-actions", [
            _c(
              "div",
              {
                staticClass:
                  "w-100 d-flex justify-content-end align-center gap-3",
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-white d-block",
                    attrs: { disabled: _vm.asyncDisable },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.$emit("doc-uploaded", $event, "cancel")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.$t("cancel")) + "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary text-white",
                    attrs: { disabled: _vm.asyncDisable },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.uploadDocumentDialog.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.$t("upload")) + "\n        "
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
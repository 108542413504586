<template>
  <v-app>
    <div class="page-container d-md-flex mt-4">
      <div class="inner-container d-flex justify-content-center gap-lg-2 align-items-center flex-xl-row">
        <div class="info-pane col-12 col-md-5">
          <EddLeftInfo :textDisplay="textDisplay" />
        </div>
        <div class="login-pane col-12 col-md-7 d-flex flex-column">
          <div class="d-md-none text-center">
            <img alt="Treasury Logo" :src="treasuryLogo" class="treasury-logo" />
            <div class="h4 mt-4">
              Access account with <img alt="Socure Logo" :src="socureLogo" class="socure-logo" />
            </div>
          </div>
          <div class="page-content">
            <div class="m-4 d-none d-md-flex">
              <div class="d-flex align-items-center flex-wrap justify-content-start">
                <a class="btn-socure btn-socure-primary d-none d-md-block" @click="backToIrs">Back to IRS.gov</a>
              </div>
            </div>
            <div class="m-4" v-if="approved">
              <h2 class="fw-light brand-text-primary text-center"">Thanks for completing your</h2>
              <h1 class="fw-bold brand-text-primary text-center"">Identity Verification Appointment</h1>
              <div class="row">
                <div class="col-3 text-center">
                  <i aria-hidden="true" class="far fa-circle-check fa-4x text-success"></i>
                </div>
                <div class="col-8 text-start">
                  Please check your email for further information on your registration completion
                </div>
              </div>
              <div class="mx-3">
                <v-btn class="my-3 btn-socure large btn-socure-secondary" @click="backToIrs">Back to IRS.gov</v-btn>
              </div>
            </div>
            <div class="m-4" v-else>
              <h1 class="fw-bold brand-text-primary">Unable to Verify Your Identity</h1>
              <div class="text-start">We cannot complete your account registration at this time.</div>
              <div class="mx-3">
                <v-btn class="my-3 btn-socure large btn-socure-secondary" @click="backToIrs">Back to IRS.gov</v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import EddLeftInfo from './EddLeftInfo.vue';

export default {
  props: {
    id: String,
  },
  components: { EddLeftInfo },
  data() {
    return {
      caseData: null,
      socureLogo: require('../../../img/socure-logo.png'),
      treasuryLogo: require('../../../img/USDOT-Crest-Dark.png'),
      email: '',
    };
  },
  computed: {
    approved() {
      return this.caseData?.case_details?.status === 'verified';
    },
    textDisplay() {
      return `Your account for <b>${this.email}</b> has been created.`;
    },
  },
  methods: {
    backToIrs() {
      window.open('/');
    },
  },
  async created() {
    try {
      const res = await axios.get(`/api/id-cases/${this.id}`);
      this.caseData = res.data;
      this.email = res.data.user_email;
    } catch (err) {
      console.error(err);
    }
  },
};
</script>
<style>
body {
  --primary-font-color: #383838;
  --primary-text-color: #383838;
  --primary-font-family: Bree Serif, Public Sans, Helvetica, Arial, sans-serif;
  --primary: #002e6d;
  --v-primary-base: #296d97;
  --secondary: #fdb71a;
  --v-secondary-base: #fdb71a;
  --tertiary: #66b3ff;
  --light: #f4f8fa;
  --grey: #e9f1f5;
  --text-light: #f0f0f0;
  --success: #1be632;
  width: 100%;
  height: 100%;
  background: #002e6d;
  border-bottom: none;
}
</style>
<style scoped>
@import '../../../css/stepper.css';
.page-container {
  min-height: calc(100vh - 20px);
  justify-content: center !important;
  align-items: flex-end;
  width: 100%;
}
.inner-container {
  max-width: 1400px;
}
:deep(.v-toolbar__content) {
  align-items: end;
}
.page-content {
  flex: 100;
  background-color: #f4f8fa;
  text-align: center;
  min-height: 800px;
}
.font-primary {
  font-family: Bree Serif;
}
.font-secondary {
  font-family: Public Sans;
}
.edd-btn {
  text-transform: capitalize;
  border-radius: 0.75rem;
  box-shadow: none !important;
  font-weight: 100 !important;
  font-size: 16px !important;
}
.edd-btn-secondary {
  all: unset;
  color: #296d97;
  text-decoration: underline;
  text-decoration-color: #2c759c5f;
  text-decoration-style: dashed;
}
.language-dropdown {
  color: #fff;
}
.edd-logo {
  height: 50px;
  margin-top: 12px;
  margin-left: 12px;
}
.ca-logo {
  height: 36px;
}
.w-85 {
  width: 85%;
}
.w-95 {
  width: 95%;
}
.treasury-logo {
  width: 70%;
}
.login-pane {
  min-height: 800px;
  margin-left: auto;
}

.btn-socure {
  text-transform: none !important;
}
@media screen and (min-width: 769px) {
  .page-content {
    text-align: left;
    padding: 2rem;
  }
  .icon-div {
    width: 50%;
  }
  .page-container {
    margin: -12px;
  }
  .login-pane {
    max-width: 700px;
  }
}
@media screen and (max-width: 768px) {
  .info-pane {
    display: none;
  }
}
</style>

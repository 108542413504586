var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.requestDialog && _vm.showDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                persistent: "",
                "max-width": !_vm.sentSuccess ? "880px" : "420px",
              },
              model: {
                value: _vm.requestDialog,
                callback: function ($$v) {
                  _vm.requestDialog = $$v
                },
                expression: "requestDialog",
              },
            },
            [
              _c(
                "div",
                { attrs: { id: "borrower-inbox-msg" } },
                [
                  !_vm.sentSuccess
                    ? _c(
                        "v-card",
                        { staticClass: "v2-wrapper" },
                        [
                          _c("div", { staticClass: "sticky-top" }, [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-content-end p-3" },
                              [
                                _c("i", {
                                  staticClass:
                                    "fas fa-times-circle fa-lg close-btn",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.closeDialog(_vm.requestDetails)
                                    },
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "v2-header p-3 p-md-5 py-1" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column justify-content-center align-center gap-2",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fa-regular fa-envelope-open-text fa-2x brand-text-primary",
                                      attrs: { "aria-hidden": "true" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { attrs: { id: "msg-type" } }, [
                                      _vm._v(_vm._s(_vm.requestDetails.name)),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center gap-1 brand-text-primary small p-2",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("subject")) +
                                              ": " +
                                              _vm._s(_vm.requestDetails.subject)
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-between",
                                  },
                                  [
                                    _vm.requestDetails.entity != null
                                      ? _c("div", { staticClass: "info-box" }, [
                                          _c("span", { staticClass: "small" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("applicationNumber")
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "brand-text-primary data-val",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.requestDetails
                                                    .application_number
                                                )
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.requestDetails.borrower
                                      ? _c("div", { staticClass: "info-box" }, [
                                          _c("span", { staticClass: "small" }, [
                                            _vm._v(_vm._s(_vm.$t("borrower"))),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "brand-text-primary data-val",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.requestDetails.borrower
                                                )
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("v-divider"),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            _vm.isComponent,
                            _vm._g(
                              {
                                tag: "component",
                                attrs: {
                                  closeInboxDialog: _vm.closeInboxDialog,
                                  requestDetails: _vm.requestDetails,
                                  loan_id: _vm.taskId,
                                  successCallback: _vm.successCallback,
                                },
                                on: {
                                  onSubmit: _vm.performBorrowerAction,
                                  unLockMessage: _vm.unLockMessage,
                                },
                              },
                              _vm.$listeners
                            )
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-overlay",
                    { attrs: { value: _vm.requestDialogLoading } },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "primary" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.showDialog
        ? _c(
            "div",
            [
              _vm.isDocumentRequest && !_vm.fetchLoading
                ? _c("div", [
                    _vm.allowedToUpload
                      ? _c("span", { staticClass: "d-block" }, [
                          _vm._v(_vm._s(_vm.$t("verifyIdentity"))),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allowedToUpload
                      ? _c("span", { staticClass: "mb-3" }, [
                          _vm._v(_vm._s(_vm.$t("accountLockText"))),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.allowedToUpload
                      ? _c("span", { staticClass: "d-block mb-3" }, [
                          _vm._v(_vm._s(_vm.$t("docVerificationPending"))),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "info-box",
                        staticStyle: { width: "100%" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "zebra-stripes-reverse" },
                          _vm._l(_vm.allowedIDTypes, function (id_type) {
                            return _c(
                              "div",
                              {
                                key: id_type.name,
                                staticClass:
                                  "zebra-stripe-row d-flex align-items-center justify-content-between flex-column flex-sm-row",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fa-regular fa-id-card brand-text-primary me-2",
                                      attrs: { "aria-hidden": "true" },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "d-flex flex-column" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "d-flex flex-column flex-sm-row align-center",
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(id_type.text) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.uploadedDocsByType[id_type.name]
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.uploadedDocsByType[
                                                    id_type.name
                                                  ].uploadedDocName
                                                )
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                !_vm.uploadedDocsByType[id_type.name] &&
                                _vm.allowedToUpload
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-white",
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.handleShowUploadDocument(
                                              id_type
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("uploadDocument"))
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _c("div", [
                                      _vm.allowedToUpload
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-white ms-2",
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.onDocumentDelete(
                                                    id_type.name
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("delete"))
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-center justify-content-sm-between flex-wrap mt-5",
                      },
                      [
                        _vm.displayVerificationSkip && _vm.messages.length > 0
                          ? _c(
                              "span",
                              {
                                staticClass: "btn btn-white ms-2",
                                on: { click: _vm.incrementSkipCount },
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("skipAndContinue"))),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary submit-btn my-3",
                            attrs: {
                              disabled: _vm.disableSave || !_vm.allowedToUpload,
                            },
                            on: { click: _vm.replyCall },
                          },
                          [_vm._v("\n          Submit Document\n        ")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.isUserReadOnly ||
                    _vm.skippedCount > _vm.skippedCountThreshold
                      ? _c(
                          "div",
                          { staticClass: "container container-error" },
                          [_vm._m(0), _vm._v(" "), _vm._m(1)]
                        )
                      : _c(
                          "div",
                          { staticClass: "container container-error" },
                          [
                            _vm._m(2),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "pt-2 pl-2 pb-2 text-primary" },
                              [
                                _c("div", { staticClass: "fs-7" }, [
                                  _c("span", { staticClass: "fw-bold" }, [
                                    _vm._v("Note:"),
                                  ]),
                                  _vm._v(
                                    " You may only select “Skip and Continue” a\n            maximum of "
                                  ),
                                  _c("span", { staticClass: "fw-bold" }, [
                                    _vm._v(
                                      _vm._s(_vm.skippedCountThreshold) +
                                        " times"
                                    ),
                                  ]),
                                  _vm._v(
                                    " before your account\n            will have limited access to your application."
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "pt-2 fs-6 fw-bold" },
                                  [
                                    _vm._v(
                                      "Documents due in: " +
                                        _vm._s(_vm.daysTillDue) +
                                        " day(s)"
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                  ])
                : _vm.isDocumentRequest && _vm.fetchLoading
                ? _c(
                    "div",
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "v2-card-light",
                        attrs: { type: "list-item-avatar-three-line@3" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogUpload
                ? _c("upload-dialog", {
                    key: "upload",
                    attrs: {
                      docType: _vm.selectedDocType,
                      dialog: _vm.dialogUpload,
                      "loan-id": _vm.taskId,
                      details: { thread: _vm.currentItem.id },
                    },
                    on: { "doc-uploaded": _vm.handleUploadDocument },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "900px" },
                  model: {
                    value: _vm.dialogSuccess,
                    callback: function ($$v) {
                      _vm.dialogSuccess = $$v
                    },
                    expression: "dialogSuccess",
                  },
                },
                [
                  _c("v-card", { staticClass: "p-4" }, [
                    _c(
                      "div",
                      { staticClass: "p-2 d-flex flex-column align-center" },
                      [
                        _c("i", {
                          staticClass:
                            "fa-regular fa-check-circle me-2 fa-3x mb-6",
                          staticStyle: { color: "var(--success)" },
                          attrs: { "aria-hidden": "true" },
                        }),
                        _vm._v(" "),
                        _c(
                          "h1",
                          { staticClass: "brand-text-primary mt-2 h5" },
                          [_vm._v("Thank you for uploading your document")]
                        ),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "Our Team will review your ID to verify your account"
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-secondary mt-3 text-white",
                            attrs: { href: _vm.postInterceptUrl },
                          },
                          [_vm._v("Continue")]
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "d-flex align-items-center justify-content-center justify-content-sm-between flex-wrap bg-danger",
      },
      [
        _c("i", {
          staticClass:
            "fa-regular fa-exclamation-triangle fa-2x text-white pl-3",
          attrs: { "aria-hidden": "true" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pt-2 pl-2 pb-2 text-primary fs-6" }, [
      _c("span", { staticClass: "fw-bold" }, [_vm._v("Note:")]),
      _vm._v(
        " You have limited access of the application due to pending document requests."
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "d-flex align-items-center justify-content-center justify-content-sm-between flex-wrap bg-danger",
      },
      [
        _c("i", {
          staticClass:
            "fa-regular fa-exclamation-triangle fa-2x text-white pl-3",
          attrs: { "aria-hidden": "true" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
<div>
    <v-card class="thread-messages overflow-auto p-3 p-md-5 pt-0">
        <div id="inbox-item-los" v-if="onAssesmentScreen">
            <ul class="message-thread">
              <li v-for="(message, index) in requestDetails.messages" :key="index" class="rounded p-2 px-3" :class="me === message.sender ? 'my-response' : ''">
                <div class="d-flex justify-content-between gap-3 align-top">
                  <div>
                    <i class="fa-regular fa-comments-alt fa-lg brand-text-primary"></i>
                  </div>
                  <div class="flex-grow-1">
                    <div class="d-flex justify-content-between flex-wrap">
                      <span class="brand-text-primary fs-9">
                        {{message.sender}}
                      </span>
                      <div>
                        <i class="fa-regular fa-clock fa-sm"></i>
                        <span class="timestamp">{{message.created | dateTime}}</span>
                      </div>
                    </div>
                    <span class="pt-2 fs-9" v-html="message.message"></span>
                  </div>
                </div>
              </li>
            </ul>
        </div>
        <v-dialog v-model="closeConfirmMessage" persistent max-width="350">
            <v-card class="confirm-dialog">
              <v-card-title class="justify-content-center"><i class="fal fa-exclamation-circle fa-2x text-warning"></i></v-card-title>
              <v-card-text class="text-center">{{$t('lossVerificationCloseMessage')}}</v-card-text>
              <v-card-actions class="justify-content-center gap-3">
                <button text @click="closeConfirmMessage = false" class="btn btn-white"> {{$t('cancel')}} </button>
                <button text @click="confirmLossVerificationClose(requestDetails)" class="btn btn-primary">{{$t('confirm')}}</button>
              </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="isSuccess" persistent max-width="350">
              <v-card class="d-flex flex-column align-items-center p-5 gap-2">
               <v-card-title>
                    <i class="fa-regular fa-paper-plane fa-lg brand-text-primary"></i>
                </v-card-title>
                 <v-card-text class="text-center">
                    <span>{{$t('lossverificationSubmittedMsg')}}</span>
                    <small class="d-block pt-5">{{$t('for_loss_at')}}</small>
                    <span class="brand-text-primary">8099 East Vale Street Sunnyvale, CA 94087</span>
                </v-card-text>
                 <v-card-actions>
                    <button class="btn btn-primary" @click="confirmLossVerificationClose(requestDetails)">
                        {{$t('close')}}
                    </button>
                </v-card-actions>
          </v-card>
        </v-dialog>
        <v-card-text class="px-0 pt-0">
         <v-divider v-if="onAssesmentScreen"> </v-divider>
            <loss-verifiaction-stepper v-on="$listeners" @completeLater="getCompleteLater" @onAssesmentScreen="getOnAssesmentScreen" 
            ></loss-verifiaction-stepper>
        </v-card-text>
    </v-card>
    
</div>
 
</template>

<script>
import LossVerificationStepper from "./LossVerificationSteps/LossVerificationStepper.vue";
import FilterMixin from '../mixins/FilterMixin';
export default {
    components: {
        "loss-verifiaction-stepper" : LossVerificationStepper
    },
    mixins: [FilterMixin],
    props: {
        thread: Object,
        requestDetails:Object,
        closeInboxDialog:Object,
        successCallback:Object
    },
   
    data() {
        return {
            sentSuccess: false,
            closeConfirmMessage:false,
            onAssesmentScreen: true,
            isSuccess:false,
            isSuccessSelectedOffer : {}
        }
    },
    methods:{
        getCompleteLater(){
            this.closeConfirmMessage = true;
        },
        getOnAssesmentScreen(value){
             this.onAssesmentScreen = value;
        },
        confirmLossVerificationClose(item = null) {
            this.closeConfirmMessage = false;
            if (item.id) this.$emit('unLockMessage',item.id);
            this.$emit('closemessage');
        },
    },
    computed:{
        me() {
            return this.$store.state?.me?.username ?? '';
        },
    },
    watch: {
        closeInboxDialog: function () {
            this.closeConfirmMessage = this.closeInboxDialog?.closeConfirmMessage;
        },
        successCallback: function () {
            this.isSuccess = this.successCallback?.isSuccess;
            this.isSuccessSelectedOffer = this.successCallback?.detail
        },
    },
}
</script>
export default {
  data() {
    return {
      localeforDateFormat: 'sv-SE',
    };
  },
  computed: {
    timeZone() {
      return this.$store?.state?.orgConfig?.time_zone ?? 'America/New_York';
    },
    todayWithTime() {
      return this.calcDateForTZ(new Date());
    },
    today() {
      return this.todayWithTime.slice(0, 10);
    },
    tomorrow() {
      const currentDate = new Date(this.today);
      currentDate.setDate(currentDate.getDate() + 1);
      return currentDate.toISOString().slice(0, 10);
    },
  },
  methods: {
    calcDateForTZ(d) {
      return d.toLocaleString(this.localeforDateFormat, {
        timeZone: this.timeZone,
        month: '2-digit',
        year: 'numeric',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
    },
    checkDateIsPast(inpDate) {
      if (inpDate) {
        return (
          Date.parse(new Date(`${this.today} 00:00:00`)) > Date.parse(new Date(`${inpDate.substring(0, 10)} 00:00:00`))
        );
      }
      return false;
    },
    checkDateIsInFuture(inpDate) {
      if (inpDate) {
        return (
          Date.parse(new Date(`${this.today} 00:00:00`)) < Date.parse(new Date(`${inpDate.substring(0, 10)} 00:00:00`))
        );
      }
      return false;
    },
    addDaysToDate(date, days) {
      const currentDate = new Date(date);
      currentDate.setDate(currentDate.getDate() + days);
      return currentDate.toISOString().slice(0, 10);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    addMonthsToDate(date, months) {
      const currentDate = new Date(date);
      const newDate = new Date(currentDate.setMonth(currentDate.getMonth() + months));
      return newDate.toISOString().slice(0, 10);
    },
  },
};

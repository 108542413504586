<template>
  <div class="row content-container mt-4 justify-content-center" style="margin: 0 auto">
    <div
      class="d-flex justify-content-center gap-lg-2 align-items-center flex-column flex-xl-row"
      style="margin-top: 12px"
    >
      <div class="info-pane col-12 col-xl-5 mb-6">
        <img class="logo-img" alt="U.S. Small Business Administration" src="/static/img/SBA-Logo-Horizontal-Reverse.png" />
        <div class="h5 mt-4">{{$t('safeInfo')}}</div>
        <div class="mt-4 mb-2 sba-help-text">
          {{$t('sbaProtectData')}}
        </div>
        <div class="mt-5 row flex-nowrap flex-column align-items-center flex-md-row" id='detailsContainer'>
    <div v-if="!noMessages" class="col-2 circle-border mb-2">
        <i class="fa-regular fa-exclamation-triangle fa-2x text-danger"></i>
    </div>
    <div v-if="!noMessages" class="col-10 d-flex flex-column justify-content-start flex-shrink-1" style='width: fit-content;'>
        <div style='margin-left: 12px;'>
            <span  class="w-100 justify-content-center justify-content-lg-start h4 row mb-0">{{$t('accountNeedstoBeVerified')}}</span>
        </div>
    </div>
</div>
      </div>
      <div class="login-pane col-12 col-xl-7 d-flex flex-column">
        <div class="mt-5 d-flex flex-column" style="flex: 100%;">
          <div class="d-flex align-items-center justify-content-between left-nav-header flex-wrap">
            <span class="brand-text-primary  h2">{{$t('accountVerification')}}</span>
            <button @click.prevent="handleSignOut" class='btn btn-white brand-text-primary'>{{$t('signOut')}}</button>
          </div>
          <dashboard-messages @set-no-messages="handleSetNoMessages"></dashboard-messages>
        </div>
        <span style='padding: 0 2.4rem;' class="small mb-3 mt-auto "><a class="primary-link " href="https://www.sba.gov/">U.S. Small Business Administration </a> | 409 3rd St, SW. Washington DC 20416 | Tel - <a href="tel:+18335720502" class="text-primary underline-brand-secondary">1-833-572-0502</a></span>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardMessages from '../DashboardMessages.vue';
import {Btn} from '@lenders-cooperative/los-app-ui-component-lib'

export default {
  components: { DashboardMessages, Btn },
  data() {
    return {
      noMessages: false,
    };
  },
  methods: {
    handleSetNoMessages() {
      this.noMessages = true;
    },
    handleSignOut(){
      window.location.href = '/accounts/logout'
    }
  },
};
</script>
<style>
body {
  --primary-font-color: #002e6d;
  --primary-text-color: black;
  --primary-font-family: Noto Sans, Avenir, Helvetica, Arial, sans-serif;
  --primary: #002e6d;
  --secondary: #b8252b;
  --tertiary: #66b3ff;
  --light: #f4f8fa;
  --grey: #e9f1f5;
  --text-light: #f0f0f0;
  --success: #1be632;
  width: 100%;
  height: 100%;
  background: linear-gradient(160deg, #002e6d 65%, #02224d 0%);
  border-bottom: 12px solid var(--secondary);
}
</style>
<style scoped>
.login-pane {
  background-color: #f4f8fa;
  opacity: 1;
  min-height: 700px;
  border-radius: 4px 4px 0px 0px;
}

.login-pane > div > .v-application {
  flex: 100%;
}

.info-pane {
  max-width: 525px;
  color: white;
  min-height: 198px;
  margin-bottom: 24px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.signout-modal-container {
  min-height: calc(100vh - 36px);
}
.signout-modal {
  width: 500px;
  background-color: var(--grey);
  border-radius: 12px;
  padding: 26px;
}

.sign-in-link {
  margin: 12px 0;
}

.btn-lg {
  font-size: 0.8rem;
}
.field-container label {
  font-size: 0.8rem;
}

@media screen and (max-width: 1200px) {
  #detailsContainer{
    display: none;
  }
  .sba-help-text {
    display: none;
  }
}
@media screen and (max-width: 1400px) {
  .password-validation-text {
    padding: 0 !important;
  }
  .info-pane {
    flex: 0 0 auto !important;
  }
  .login-pane {
    flex: 0 0 auto !important;
  }

}

@media screen and (min-width: 768px) {
  .signup-form > div > div div.col-md-6 {
    padding: 0 10px !important;
  }
  .reset-password-help-text {
    width: 320px;
  }
  .login-pane {
    max-width: 700px;
  }
  .sign-in-link {
    margin: 0;
  }
  .signup-form > div > div .address-state-container > div:first-child {
    padding-left: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  body {
    width: 100%;
    height: 100%;
  }
  .content-container {
    min-height: calc(100vh - 36px);
    height: 100%;
    align-items: flex-end;
  }
  .login-pane {
    min-height: 800px;
    margin-left: auto;
  }
  .info-pane {
    margin-bottom: auto !important;
  }
  .address-state-container > div.col-md-6 {
    margin: 0 !important;
  }
  .field-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.login-pane > * {
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}

.login-pane .form-control {
  border: 2px solid var(--primary);
  border-radius: 4px;
  background-color: white;
}

.brand-text-primary  {
  color: var(--primary) !important;
}

.text-secondary {
  color: var(--secondary) !important;
}

.success-text {
  color: var(--success);
}

.btn {
  border: none !important;
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:visited {
  background-color: var(--secondary);
}

.pretty-link {
  color: var(--primary) !important;
  text-decoration: underline;
  text-decoration-color: var(--secondary);
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
  font-size: 0.8rem;
}

.primary-link {
  text-decoration: none;
  color: var(--primary);
}

.required::after {
  content: '*';
  color: var(--secondary);
  padding-left: 4px;
}

.logo-img {
  max-width: 33%;
}

.circle-border {
  border: 6px solid white;
  height: 85px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.stepnum {
  position: relative;
  left: 5px;
}

.signup-form {
  height: calc(100% - 200px);
}

.signup-form > div > div div.field-container.col-md-6 {
  margin: 10px 0 !important;
}

.form-input-field {
  border: 1px solid var(--primary);
}

.password-validation-text {
  font-size: 0.75rem;
}

.errorList {
  list-style: none;
  margin-bottom: 0.5rem;
}

.timeline-bar {
  width: 100%;
  list-style-type: none;
  display: flex;
  padding: 0;
  align-items: center;
  margin-bottom: 3.5em;
  margin-top: 1em;
}

.timeline-bar > li.timeline-bar__dot {
  display: block;
  width: 1em;
  border-radius: 1000em;
  height: 1em;
  background-color: white;
  transition: all 0.5s ease;
}

li.timeline-bar__dot.full {
  background-color: var(--success);
  border: 0.2em solid var(--success);
}

li.timeline-bar__connector {
  display: block;
  width: 5em;
  border-radius: 1000em;
  height: 0.3em;
  background-color: #fff;
  /* margin-top: 0.45em; */
}

li.timeline-bar__connector.line-connected {
  background-color: var(--success);
}
</style>

<template>
  <div>
    <div class="info-box text-center" v-if="currentStep != 1">
      <div class="small">{{$t('property')}}</div>
      <div class="brand-text-primary data-val">8099 East Vale Street Sunnyvale, CA 94087</div>
    </div>
    <v-stepper class="loss-stepper-overflow" v-model="currentStep">
      <v-stepper-items>
        <v-form ref="lossVerificationForm">
          <v-stepper-content step="1">
            <loss-verification-step1 :step1Obj.sync="step1Obj" @isSelfAssesment="selfAssesment" @isPropertyDamage="propertyDamage"></loss-verification-step1>
          </v-stepper-content>
          <v-stepper-content step="2">
            <loss-verification-step2 :step2Obj.sync="step2Obj"></loss-verification-step2>
          </v-stepper-content>
          <v-stepper-content step="3">
            <loss-verification-step3 :step3Obj.sync="step3Obj"></loss-verification-step3>
          </v-stepper-content>
          <v-stepper-content step="4">
            <loss-verification-step4 :step4Obj.sync="step4Obj"></loss-verification-step4>
          </v-stepper-content>
          <v-stepper-content step="5">
            <loss-verification-step5 :step5Obj.sync="step5Obj"></loss-verification-step5>
          </v-stepper-content>
          <v-stepper-content step="6">
            <loss-verification-step6 :step6Obj.sync="step6Obj" @autoDamageCount="getAutoDamageCount"></loss-verification-step6>
          </v-stepper-content>
          <v-stepper-content step="7">
            <loss-verification-step7 :allData='allData'></loss-verification-step7>
          </v-stepper-content>
          <v-stepper-content v-for="n in autoDamageCount" :key="`${n}-content`" :step="initialStepCount + n">
            <loss-verification-stepCustom :stepCustomObj.sync="stepCustomObj" :autoMobileNumber="n" :ref_count="initialStepCount + n"></loss-verification-stepCustom>
          </v-stepper-content>
        </v-form>
      </v-stepper-items>
      <div class="d-flex justify-content-between pt-2">
        <div>
          <button class="btn btn-white" @click="goToBackStep()"><b>{{ backButtonLabel}}</b></button>
        </div>
        <div>
          <button class="btn btn-primary" @click="goToNextStep()">
            <span class="fw-semi-bold">{{ nextButtonLabel}}</span>
          </button>
        </div>
      </div>
    </v-stepper>
  </div>
</template>

<script>
import LossVerificationStep1 from "./LossVerificationStep1.vue";
import LossVerificationStep2 from "./LossVerificationStep2.vue";
import LossVerificationStep3 from "./LossVerificationStep3.vue";
import LossVerificationStep4 from "./LossVerificationStep4.vue";
import LossVerificationStep5 from "./LossVerificationStep5.vue";
import LossVerificationStep6 from "./LossVerificationStep6.vue";
import LossVerificationStepCustom from "./LossVerificationStepCustom.vue";
import LossVerificationStep7 from "./LossVerificationStep7.vue";

export default {

  mixins: [],
  components: {
    'loss-verification-step1': LossVerificationStep1,
    'loss-verification-step2': LossVerificationStep2,
    'loss-verification-step3': LossVerificationStep3,
    'loss-verification-step4': LossVerificationStep4,
    'loss-verification-step5': LossVerificationStep5,
    'loss-verification-step6': LossVerificationStep6,
    'loss-verification-stepCustom': LossVerificationStepCustom,
    'loss-verification-step7': LossVerificationStep7
  },
  props: {
  },
  data() {
    return {
      currentStep: 1,
      isSelfAssesment: false,
      isPropertyDamage:false,
      autoDamageCount: 0,
      backButtonLabel: this.$t('completeLater'),
      nextButtonLabel: this.$t('continue'),
      initialStepCount: 7,
      step1Obj: {},
      step2Obj: {},
      step3Obj: {},
      step4Obj: {},
      step5Obj: {},
      step6Obj: {},
      stepCustomObj: {},
      allData:{},
      lossData:{
        autoMobileDetails:{}
      },
      steps: [
        {
          form: 'step1form',
          skipValidation: false,
          dataObject: 'step1Obj',
        },
        {
          form: 'step2form',
          skipValidation: false,
          dataObject: 'step2Obj',
        },
        {
          form: 'step3form',
          skipValidation: false,
          dataObject: 'step3Obj',
        },
        {
          form: 'step4form',
          skipValidation: false,
          dataObject: 'step4Obj',
        },
        {
          form: 'step5form',
          skipValidation: false,
          dataObject: 'step5Obj',
        },
        {
          form: 'step6form',
          skipValidation: false,
          dataObject: 'step6Obj',
        },
        {
          form: 'step7form',
          skipValidation: false,
        },
      ]
    };
  },

  computed: {
    // validate(){
    //   return this.validateCurrentStep();
    // }
  },
  methods: {
    validateCurrentStep() {
      const stepNo = this.currentStep - 1;
      if (!this.steps[stepNo].skipValidation && this.$refs.lossVerificationForm?.$children[stepNo]?.$children[0]?.$refs[this.steps[stepNo].form]) {
        if (!this.$refs.lossVerificationForm?.$children[stepNo]?.$children[0]?.$refs[this.steps[stepNo].form].validate()) {
          return false;
        }
      }
      if(stepNo < 6){
        let data;
        if (Array.isArray(this[this.steps[stepNo].dataObject])) data = [...this[this.steps[stepNo].dataObject]];
          else data = { ...this[this.steps[stepNo].dataObject] };
          this.$set(this.allData,this.steps[stepNo].dataObject, data )
          Object.keys(data).forEach((key)=>{
              this.lossData[key] = data[key];
          })
      }else if(stepNo > 6){
        this.$set(this.allData,this.steps[this.stepCustomObj.ref_num - 1].dataObject, this.stepCustomObj )
        this.lossData["autoMobileDetails"][this.stepCustomObj.autoMobileNumberStr] = {...this.stepCustomObj}
      }
      
      this.allData = { ...this.allData };
      
      return true;
    },
    goToNextStep() {
      if (this.validateCurrentStep()) {
        if (this.currentStep == 6) {
          this.currentStep = this.autoDamageCount == 0 ? 7 : 8
        } else if (this.currentStep == 7) {
          
          let _dataObj = {
            detail:this.lossData,
            action:'submit'
          }
          this.$emit("onSubmit", _dataObj);
        } else if (this.currentStep == this.steps.length) {
          this.currentStep = 7;
        } else if (this.currentStep == 1 && this.isSelfAssesment && !this.isPropertyDamage){
          this.currentStep = 5;
        }else if (this.currentStep == 1 && !this.isSelfAssesment){
          let _dataObj = {
            detail:{},
            action:'reject'
          }
          this.$emit("onSubmit", _dataObj);
        }else {
          this.currentStep += 1;
        }
        this.$emit("onAssesmentScreen", false)
        this.updateButtonLabel();
      }
    },
    goToBackStep() {
      if (this.currentStep == 7) {
        this.currentStep = this.autoDamageCount == 0 ? 6 : this.steps.length
      } else if (this.currentStep == 8) {
        this.currentStep = 6
      } else if (this.currentStep == 1) {
        this.$emit("completeLater")
      }else if (this.currentStep == 5 && !this.isPropertyDamage){
          this.currentStep = 1;
      } else {
        if (this.currentStep == 2) {
          this.$emit("onAssesmentScreen", true)
        } else {
          this.$emit("onAssesmentScreen", false)
        }
        this.currentStep -= 1;
      }
      this.updateButtonLabel();
    },
    updateButtonLabel() {
      this.backButtonLabel = this.currentStep == 1 ? this.$t('completeLater') : this.$t('back');
      this.nextButtonLabel = (this.currentStep == 7 || (this.currentStep == 1 && !this.isSelfAssesment)) ? this.$t('submit') : this.$t('continue');
    },
    selfAssesment(value) {
      this.isSelfAssesment = value;
      this.updateButtonLabel();
    },
    propertyDamage(value){
      this.isPropertyDamage = value
    },
    getAutoDamageCount(value) {
      this.autoDamageCount = value;
      this.autoUpdateSteps(this.autoDamageCount);
    },
    autoUpdateSteps(n) {
      // let newTotalSteps = this.initialStepCount + n;
      //let updatedSteps = this.steps;
      for (let i = 1; i <= n; i++) {
        let count = this.initialStepCount + i;
        let form_count = count-1;
        this.steps.push({
          form: 'step' + count + 'form',
          skipValidation: false,
          dataObject:'step'+ form_count +'Obj'
        })
      }
    },
    // stepCustomObjData(value){
    //   this.allData[value.ref_num] = value;
    //   console.log(" this.allData[stepNo]",  this.allData);
    // }
  },
  async mounted() {

  },
};
</script>

<style scoped>
.v-stepper {
  box-shadow: none !important;
}
</style>

<template>
  <v-form ref="step3form">
    <div class="loss-slider-container">
      <div><label class="pb-1">{{$t('lossverificationStep3Question')}} </label> </div>
      <v-slider v-model="step3Data.damageLevel" class="loss-slider" :tick-labels="ticksLabels" :max="3" step="1" ticks="always" tick-size="4">
        <template v-slot:thumb-label="props">
          <div class="loss-tooltip">
            <!-- <v-img class="loss-hint-image" :src="getImage(props.value)"></v-img> -->
            <loss-low v-if="props.value == 0"></loss-low>
            <loss-medium v-if="props.value == 1"></loss-medium>
            <loss-high v-if="props.value == 2"></loss-high>
            <loss-destroyed v-if="props.value == 3"></loss-destroyed>
            <span>{{getTooltipTitle(props.value)}}</span>
            <div>{{getTooltipText(props.value)}}</div>
          </div>
        </template>
      </v-slider>

    </div>
  </v-form>
</template>

<script>
import lossLow from '../../svg/LossLow.vue';
import lossDestroyed from '../../svg/LossDestroyed.vue';
import lossHigh from '../../svg/LossHigh.vue';
import lossMedium from '../../svg/LossMedium.vue';
export default {

  mixins: [],
  components: { 
    'loss-low' : lossLow ,
    'loss-destroyed':lossDestroyed,
    'loss-high': lossHigh,
    'loss-medium': lossMedium
  },
  props: {
     step3Obj: Object,
  },
  data() {
    return {
      step3Data:{
        damageLevel: ''
      },
      ticksLabels: [this.$t('low'), this.$t('medium'), this.$t('high'), this.$t('destroyed')],
      tooltipData: [
        {
          title: this.$t('lowLevelDamage'),
          text: this.$t('lossverificationStep3Option1')
        },
        {
          title: this.$t('mediumLevelDamage'),
          text: this.$t('lossverificationStep3Option2')
        },
        {
          title: this.$t('highLevelDamage'),
          text: this.$t('lossverificationStep3Option3')
        },
        {
          title: this.$t('destroyedLevelDamage'),
          text: this.$t('lossverificationStep3Option4')
        }
      ]
    };
  },

  computed: {
  },
  methods: {
    getTooltipTitle(val) {
      return this.tooltipData[val].title;
    },
    getTooltipText(val) {
      return this.tooltipData[val].text;
    }
  },
  watch: {
    step3Data: {
      handler() {
        this.$emit('update:step3Obj', this.step3Data);
      },
      deep: true,
    },
  },
};
</script>

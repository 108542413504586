var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-app", [
    _c(
      "div",
      { staticClass: "page-container d-md-flex mt-4" },
      [
        _c(
          "div",
          {
            staticClass:
              "inner-container d-flex justify-content-center gap-lg-2 align-items-center flex-xl-row",
          },
          [
            _c(
              "div",
              { staticClass: "info-pane col-12 col-md-5" },
              [_c("EddLeftInfo", { attrs: { textDisplay: _vm.textDisplay } })],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "login-pane col-12 col-md-7 d-flex flex-column" },
              [
                _c("div", { staticClass: "d-md-none text-center" }, [
                  _c("img", {
                    staticClass: "treasury-logo",
                    attrs: { alt: "Treasury Logo", src: _vm.treasuryLogo },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "h4 mt-4" }, [
                    _vm._v("\n            Access account with "),
                    _c("img", {
                      staticClass: "socure-logo",
                      attrs: { alt: "Socure Logo", src: _vm.socureLogo },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "page-content" }, [
                  _c("div", { staticClass: "m-4 d-none d-md-flex" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center flex-wrap justify-content-start",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "btn-socure btn-socure-primary d-none d-md-block",
                            on: { click: _vm.backToIrs },
                          },
                          [_vm._v("Back to IRS.gov")]
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "m-4" }, [
                    _c("h1", { staticClass: "fw-bold brand-text-primary" }, [
                      _vm._v("Verify Your Identity with a Live Agent"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-start" }, [
                      _vm._v(
                        "\n              To continue with your account you will be required to verify your identity with a live agent on a video\n              appointment.\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "h3 pt-3 brand-text-primary" }, [
                      _vm._v("You will need the following:"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex flex-column flex-md-row" }, [
                    _c("div", { staticClass: "icon-div" }, [
                      _c("i", {
                        staticClass: "far fa-circle-video fa-2x pb-3 text-info",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("A device with a working video camera"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "icon-div" }, [
                      _c("i", {
                        staticClass: "far fa-id-card fa-2x pb-3 text-info",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" "),
                      _c("div", [_vm._v("A government issued ID")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mx-4 text-start" }, [
                    _vm._v(
                      "\n            You can join the waiting room now or schedule an appointment to verify your identity.\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mx-4" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "btn-socure my-3 large btn-socure-secondary",
                          on: {
                            click: function ($event) {
                              _vm.confirmDialog = true
                            },
                          },
                        },
                        [_vm._v("Join Waiting Room")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-socure large btn-socure-secondary",
                          on: { click: _vm.goScheduling },
                        },
                        [_vm._v("Schedule Appointment")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "v-dialog",
          {
            attrs: { width: "500px" },
            model: {
              value: _vm.confirmDialog,
              callback: function ($$v) {
                _vm.confirmDialog = $$v
              },
              expression: "confirmDialog",
            },
          },
          [
            _c("div", { staticClass: "dialog-header" }, [
              _c("h1", { staticClass: "fs-3" }, [
                _vm._v("Join the Waiting Room"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dialog-text" }, [
              _c("div", [
                _vm._v(
                  "\n          You will be directed to a video waiting room to verify your ID. The avg wait time is "
                ),
                _c("b", [_vm._v("20 mins")]),
                _vm._v(".\n        "),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "dialog-buttons text-center" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "my-3 btn-socure large btn-socure-secondary",
                    on: { click: _vm.goMeeting },
                  },
                  [_vm._v("Join Waiting Room Now")]
                ),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    staticClass: "btn-socure btn-socure-primary",
                    on: {
                      click: function ($event) {
                        _vm.confirmDialog = false
                      },
                    },
                  },
                  [_vm._v("No, I do not wish to join now")]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-app>
    <div class="page-container d-md-flex mt-4">
      <div class="inner-container d-flex justify-content-center gap-lg-2 align-items-center flex-xl-row">
        <div class="info-pane col-12 col-md-5">
          <EddLeftInfo :isEmail="true" :step="step" />
        </div>
        <div class="login-pane col-12 col-md-7 d-flex flex-column">
          <div class="d-md-none text-center">
            <img alt="Treasury Logo" :src="treasuryLogo" class="treasury-logo" />
            <div class="h4 mt-4">
              Access account with <img alt="Socure Logo" :src="socureLogo" class="socure-logo" />
            </div>
          </div>
          <div class="page-content">
            <div class="m-4 d-none d-md-flex">
              <div class="d-flex align-items-center flex-wrap justify-content-between w-100">
                <a class="btn-socure btn-socure-primary d-none d-md-block" @click="backToIrs">Back to IRS.gov</a>
                <a v-if="step === 1" class="btn-socure btn-socure-primary d-none d-md-block">Create an account</a>
                <a v-else class="btn-socure btn-socure-primary d-none d-md-block">Sign in</a>
              </div>
            </div>
            <h1 class="fw-bold brand-text-primary m-4 mb-2">Manage ID Verification Appointent</h1>
            <div class="m-4 mt-0" v-if="step === 1">
              <div class="text-start">Please confirm your email to find your appointment.</div>
              <FieldItem class="text-dark field-item mt-3" label="Email" :required="true">
                <v-text-field
                  v-model="email"
                  class="mt-2"
                  outlined
                  dense
                  solo
                  clearable
                  placeholder="- - - - - - - - - - - - - - -"
                />
              </FieldItem>
              <v-checkbox color="primary" label="Keep me signed in" />
              <v-btn class="my-2 btn-socure large btn-socure-secondary" @click="getCode()">Next</v-btn>
            </div>
            <div class="m-4 mt-0" v-if="step === 2">
              <div class="text-start">Set up your account credentials.</div>
              <div class="brand-text-primary fs-5 lh-sm mt-2">
                Please provide the 6 digit security code
                <span>{{ isfactorTotp ? 'from your authenticator app ' : 'sent to' }}</span> :
                <span style="font-weight: 600">{{ mfaDetail }}</span>
              </div>
              <div class="mt-2">Code <span class="text-danger">*</span></div>
              <v-otp-input class="verification-code mt-2" v-model="otpCode"></v-otp-input>
              <v-btn class="my-3 btn-socure large btn-socure-secondary mt-4" @click="verifyCode()"
                >Manage Appointment</v-btn
              >
              <div class="w-100 d-flex justify-content-center">
                <v-btn class="btn-socure btn-socure-primary" @click="step = 1">Back to Email</v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-snackbar v-model="snackbar" :multi-line="multiLine" :color="snackColor">
      {{ this.snackMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>
<script>
import DateMixin from '../../mixins/DateMixin';
import SnackMixin from '../../mixins/SnackMixin';
import DateField from '../../../lender-vue/vue-components/DateField.vue';
import EddLeftInfo from './EddLeftInfo.vue';
import { FieldItem } from '@lenders-cooperative/los-app-ui-component-lib';

export default {
  props: {
    id: String,
  },
  components: { DateField, EddLeftInfo, FieldItem },
  mixins: [DateMixin, SnackMixin],
  data() {
    return {
      socureLogo: require('../../../img/socure-logo.png'),
      treasuryLogo: require('../../../img/USDOT-Crest-Dark.png'),
      step: 1,
      email: '',
      phone_number: '',
      code: ['', '', '', '', '', ''],
      okta_details: {
        token: '',
        factor: '',
      },
      otpCode: '',
    };
  },
  computed: {
    appointmentTime() {
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'America/Los_Angeles',
        hour: 'numeric',
        minute: 'numeric',
      };
      return new Date(`${this.selectedDate} ${this.selectedTime}`).toLocaleString('en-US', options);
    },
    mfaDetail() {
      switch (this.okta_details.factor) {
        case 'email':
          return this.email;
        case 'sms':
          return this.phone_number;
      }
    },
    isfactorTotp() {
      return this.okta_details.factor === 'totp';
    },
  },
  methods: {
    backToIrs() {
      window.open('/');
    },
    goScheduling() {
      this.$router.push({ name: 'EddSchedulingPage', params: { id: this.id, step: 3 } });
    },
    goToLanding() {
      this.$router.push({ name: 'EddLandingPage' });
    },
    setSelectedTime(time) {
      this.selectedTime = time;
    },
    setSelectedTimeOfDay(val) {
      this.selectedTimeOfDay = val;
    },
    setSelectedDate(val) {
      console.log(val);
      this.selectedDate = val;
    },
    async setMeetingDate() {
      this.step++;
      const date = new Date(`${this.selectedDate} ${this.selectedTime}`);
      try {
        const data = {
          meeting_date: date.toISOString(),
        };
        axios.post(`/api/id-cases/${this.id}/schedule_case/`, data);
      } catch (err) {
        console.error(err);
      }
    },
    async cancelAppointment() {
      this.step = 4;
      //cancel api here
    },
    async verifyCode() {
      const headers = { Authorization: `Bearer ${this.okta_details.token}` };
      const data = {
        action: 'verify',
        factor: this.okta_details.factor,
        code: this.otpCode,
      };
      axios
        .post(`/api/okta/factors/challenge/`, data, { headers })
        .then((res) => {
          if (res.data.success) {
            this.goScheduling();
          } else {
            const snackObj = {
              snackMsg: 'Verification Failed',
            };
            this.handleSnack(snackObj);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    async startAction() {
      const headers = { Authorization: `Bearer ${this.okta_details.token}` };
      try {
        const data = {
          action: 'start',
          factor: this.okta_details.factor,
        };
        axios.post(`/api/okta/factors/challenge/`, data, { headers });
      } catch (err) {
        console.error(err);
      }
    },
    getCode() {
      this.step = 2;
      this.startAction();
    },
  },
  async created() {
    try {
      const res = await axios.get(`/api/id-cases/${this.id}`);
      console.log(res.data);
      this.email = res.data.user_email;
      this.phone_number = res?.data?.socure_details?.mobileNumber ?? '';
      this.okta_details.factor = res?.data?.okta?.factor;
      this.okta_details.token = res?.data?.okta?.token;
    } catch (err) {
      console.error(err);
    }
  },
};
</script>
<style>
@import '../../../css/stepper.css';
body {
  --primary-font-color: #383838;
  --primary-text-color: #383838;
  --primary-font-family: Bree Serif, Public Sans, Helvetica, Arial, sans-serif;
  --primary: #002e6d;
  --v-primary-base: #296d97;
  --secondary: #fdb71a;
  --v-secondary-base: #fdb71a;
  --tertiary: #66b3ff;
  --light: #f4f8fa;
  --grey: #e9f1f5;
  --text-light: #f0f0f0;
  --success: #1be632;
  width: 100%;
  height: 100%;
  background: #002e6d;
  border-bottom: none;
}
</style>
<style scoped>
.page-container {
  min-height: calc(100vh - 20px);
  justify-content: center !important;
  align-items: flex-end;
  width: 100%;
}
.inner-container {
  max-width: 1400px;
}
:deep(.v-toolbar__content) {
  align-items: end;
}
.page-content {
  flex: 100;
  background-color: #f4f8fa;
  text-align: center;
  min-height: 800px;
}
.font-primary {
  font-family: Bree Serif;
}
.font-secondary {
  font-family: Public Sans;
}
.time-btn {
  text-transform: capitalize;
  border-radius: 4px;
  box-shadow: none !important;
  font-weight: 100 !important;
  font-size: 16px !important;
}

.time-btn {
  background-color: #fff !important;
  border: 4px solid var(--lc-background-color) !important;
  margin: -2px 0;
}
.time-btn-selected {
  background-color: var(--lc-primary) !important;
  border: 3px solid var(--primary) !important;
  color: var(--lc-text-light) !important;
}
.appointment-label {
  margin: -10px 0;
}
.date-picker {
  margin-bottom: -14px;
  width: 100%;
}
.treasury-logo {
  width: 70%;
}
:deep(.v-picker__title.primary) {
  background-color: var(--lc-primary) !important;
}
.btn-socure-primary {
  box-shadow: none;
  border-radius: 0px;
  background: none !important;
}
.dialog-header {
  display: flex;
  background-color: #fafafa;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
}
.dialog-header > div {
  font-weight: 600;
}
.dialog-text {
  display: flex;
  background-color: #fff;
  padding: 8px 16px;
  border-bottom: 1px solid #e8e8e8;
}
.dialog-buttons {
  padding: 10px 16px;
  background-color: #fafafa;
}

.login-pane {
  min-height: 800px;
  margin-left: auto;
}
.btn-socure {
  text-transform: none !important;
}
:deep(.v-input--selection-controls) {
  margin: unset;
  padding: unset;
}
:deep(.theme--light.v-otp-input .v-input .v-input__control .v-input__slot) {
  height: 120px !important;
}
@media screen and (min-width: 769px) {
  .page-content {
    text-align: left;
    padding: 2rem;
  }
  .page-container {
    margin: -12px;
  }
  .time-div {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1rem;
  }
  .login-pane {
    max-width: 700px;
  }
}
@media screen and (max-width: 768px) {
  .info-pane {
    display: none;
  }
  .time-div {
    display: contents;
  }
}
</style>

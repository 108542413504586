var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-app", [
    _c(
      "div",
      {
        staticClass: "v2-card-wrapper d-flex flex-column",
        staticStyle: { flex: "100%" },
      },
      [
        _c("div", { staticClass: "header-text-wrapper mb-5" }, [
          _c("div", { staticClass: "d-flex my-3" }, [
            _c("i", {
              staticClass:
                "fa-regular fa-user-circle me-2 fa-3x brand-text-primary d-none d-md-block",
            }),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex flex-column" }, [
              _vm.me
                ? _c("h4", { staticClass: "brand-text-primary mb-0" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(`${_vm.me.first_name} ${_vm.me.last_name}`)
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("small", [_vm._v(_vm._s(_vm.me.username_display))]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [
          !_vm.loading
            ? _c(
                "div",
                [
                  _vm.messages.length > 0
                    ? _c("h4", { staticClass: "brand-text-primary" }, [
                        _vm._v(_vm._s(_vm.$t("accountNotVerified"))),
                      ])
                    : _c("div", { staticClass: "d-flex flex-column" }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "w-100 justify-content-center justify-content-lg-start h4 mb-5",
                          },
                          [_vm._v(_vm._s(_vm.$t("noRequiredActions")))]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-white",
                            attrs: { href: _vm.continueBtnLink },
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("continue")))])]
                        ),
                      ]),
                  _vm._v(" "),
                  _c("alt-inbox-message", {
                    attrs: {
                      postInterceptUrl: _vm.continueBtnLink,
                      thread: _vm.message,
                      "show-dialog": false,
                      "request-dialog": _vm.requestDialog,
                      showVerificationSkip: _vm.showVerificationSkip,
                      messages: _vm.messages,
                    },
                    on: {
                      closemessage: function ($event) {
                        return _vm.closeMessage(_vm.index)
                      },
                      snack: _vm.handleSnack,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "v-snackbar",
          {
            attrs: { "multi-line": _vm.multiLine, color: _vm.snackColor },
            scopedSlots: _vm._u([
              {
                key: "action",
                fn: function ({ attrs }) {
                  return [
                    _c(
                      "v-btn",
                      _vm._b(
                        {
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              _vm.snackbar = false
                            },
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      [_vm._v(" Close ")]
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.snackbar,
              callback: function ($$v) {
                _vm.snackbar = $$v
              },
              expression: "snackbar",
            },
          },
          [_vm._v("\n      " + _vm._s(this.snackMsg) + "\n      ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
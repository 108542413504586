const langs = ['en', 'es'];
const literals = {
  loan: {
    en: 'Loan',
    es: 'Préstamo',
  },
  signIn: {
    en: 'Sign in',
    es: 'Sign in',
  },
  startApplication: {
    en: 'Start New Application',
    es: 'Iniciar nueva aplicación',
  },
  loanApplication: {
    en: 'Loan Application',
    es: 'Solicitud de préstamo',
  },
  selectApplication: {
    en: 'Select Application',
    es: 'Seleccionar aplicación',
  },
  inbox: {
    en: 'Inbox',
    es: 'inbäks',
  },
  applicationNumber: {
    en: 'Application Number',
    es: 'Numero de aplicacion',
  },
  notListed: {
    en: 'Not Listed',
    es: 'No listada',
  },
  productName: {
    en: 'Product Name',
    es: 'Nombre del producto',
  },
  name: {
    en: 'Name',
    es: 'Nombre',
  },
  loanAmount: {
    en: 'Loan Amount',
    es: 'Monto del préstamo',
  },
  status: {
    en: 'Status',
    es: 'Estado',
  },
  options: {
    en: 'Options',
    es: 'Opciones',
  },
  phase: {
    en: 'Phase',
    es: 'Fase',
  },
  startNewApplicationMessage: {
    en: 'Click below to start your Loan application.',
    es: 'Haga clic a continuación para iniciar su solicitud de préstamo.',
  },
  startAppButton: {
    en: 'Start Your Application Now',
    es: 'Comience su aplicación ahora',
  },
  loading: {
    en: 'Loading ...',
    es: 'Loading ...',
  },
  losDashboardError: {
    en: 'Something has gone wrong. Cannot fetch required data.',
    es: 'Something has gone wrong. Cannot fetch required data.',
  },
  hello: {
    en: 'Hello',
    es: 'Hola',
  },
  view: {
    en: 'View',
    es: 'Vista',
  },
  open: {
    en: 'Open',
    es: 'Abierta',
  },
  edit: {
    en: 'Edit',
    es: 'Editar',
  },
  withdraw: {
    en: 'Withdraw',
    es: 'Retirar',
  },
  withdrawLoanApplication: {
    en: 'Withdraw Loan Application',
    es: 'Withdraw Loan Application',
  },
  withdrawalRemarks: {
    en: 'Withdrawal Remarks',
    es: 'Withdrawal Remarks',
  },
  submit: {
    en: 'Submit',
    es: 'Enviar',
  },
  cancel: {
    en: 'Cancel',
    es: 'Cancelar',
  },
  confirm: {
    en: 'Confirm',
    es: 'Confirmar',
  },
  ok: {
    en: 'OK',
    es: 'OK',
  },
  notAcceptingApplicationText: {
    en: 'Sorry, we are not accepting new Loan applications at the moment. Please stop by any branch.',
    es: 'Lo sentimos, no estamos aceptando nuevas solicitudes de préstamo en este momento. Pasa por cualquier sucursal.',
  },
  close: {
    en: 'Close',
    es: 'Cerrar',
  },
  backToDashboard: {
    en: 'Back to Home',
    es: 'Volver al panel',
  },
  backHome: {
    en: 'Back Home',
    es: 'Volver Inicio',
  },
  continueToDashboard: {
    en: 'Continue to Dashboard',
    es: 'Continuar al panel',
  },
  stages: {
    en: 'Stages',
    es: 'Stages',
  },
  applicationInProgress: {
    en: 'Application In Progress',
    es: 'Solicitud en curso',
  },
  back: {
    en: 'Back',
    es: 'Atrás',
  },
  save: {
    en: 'Save',
    es: 'Salvar',
  },
  saveAsDraft: {
    en: 'Save as Draft',
    es: 'Guardar como borrador',
  },
  submitAndEsign: {
    en: 'Submit & Continue to e-Sign',
    es: 'Enviar y continuar e-Sign',
  },
  sendToBorrower: {
    en: 'Send to Borrower',
    es: 'Enviar al prestatario',
  },
  next: {
    en: 'Next',
    es: 'Próxima',
  },
  continueToEsign: {
    en: 'Continue to e-Sign',
    es: 'Continuar a e-Sign',
  },
  disclosureNote: {
    en: '<b>Note: </b>Generally, these will be the steps we follow to process your request.',
    es: '<b>Nota: </b>Por lo general, estos serán los pasos que seguiremos para procesar su solicitud.',
  },
  loanReview: {
    en: 'Loan Review',
    es: 'Revisión de préstamo',
  },
  loanClosing: {
    en: 'Loan Closing',
    es: 'Cierre de préstamo',
  },
  funding: {
    en: 'Funding',
    es: 'Fondos',
  },
  applicationSubmitted: {
    en: 'Application Submitted',
    es: 'Application Submitted',
  },
  backActionConfirmText: {
    en: 'Any unsaved work will be lost. Are you sure you want to go back?',
    es: 'Cualquier trabajo no guardado se perderá. ¿Estás seguro de que quieres volver?',
  },
  copyDataFromProfile: {
    en: 'Copy data from Profile',
    es: 'Copiar datos del perfil',
  },
  copyDataFromProfileText: {
    en: 'Do you want to copy data from your existing profile? You can edit and upload documents to <b>Your Profile</b> by clicking on your username in the top right hand corner of the application.',
    es: '¿Desea copiar datos de su perfil existente? Puede editar y cargar documentos en <b>Su perfil</b> haciendo clic en su nombre de usuario en la esquina superior derecha de la aplicación.',
  },
  yes: {
    en: 'Yes',
    es: 'Sí',
  },
  no: {
    en: 'No',
    es: 'No',
  },
  update: {
    en: 'Update',
    es: 'Actualizar',
  },
  setDataToProfile: {
    en: 'Save for Future Requests',
    es: 'Guardar para futuras solicitudes',
  },
  setDataToProfileText: {
    en: 'Congratulations, you are almost done! To make applying for loans in the future easier, can we save and update this information to your online profile?',
    es: '¡Felicitaciones, casi has terminado! Para facilitar la solicitud de préstamos en el futuro, ¿podemos guardar y actualizar esta información en su perfil en línea?',
  },
  yourUsername: {
    en: 'Your username',
    es: 'Su nombre de usuario',
  },
  emailProfile: {
    en: 'The email associated with your profile',
    es: 'El correo electrónico asociado a tu perfil',
  },
  yourProfile: {
    en: 'Your Profile',
    es: 'Tu perfil',
  },
  profile: {
    en: 'Profile',
    es: 'Perfil',
  },
  checkEmailUpdates: {
    en: 'Please check this email for updates and notifications about your loan applications.',
    es: 'Consulte este correo electrónico para obtener actualizaciones y notificaciones sobre sus solicitudes de préstamo.',
  },
  savingInformation: {
    en: 'You can save time by saving information to <b>Your Profile</b> and reusing it in applications you submit in the future',
    es: 'Puede ahorrar tiempo guardando información en <b>Su perfil</b> y reutilizándola en solicitudes que envíe en el futuro.',
  },
  infoSaveIn: {
    en: 'The information you save in <b>Your Profile</b> can be copied easily into each loan application.<br/>You will be able to upload documents from <b>Your Profile</b> into each application from the <b>Documents Briefcase</b> tab.',
    es: 'La información que guarda en <b>Su perfil</b> se puede copiar fácilmente en cada solicitud de préstamo.<br/>Podrás subir documentos desde <b>Tu Perfil</b> a cada aplicación desde la pestaña <b>Maletín de Documentos</b>.',
  },
  toResetPwd: {
    en: 'To reset your password, please use the <b>Forgot Password</b> function upon sign in.',
    es: 'Para restablecer su contraseña, utilice la función <b>Olvidé mi contraseña</b> al iniciar sesión.',
  },
  langPref: {
    en: 'Language Preference',
    es: 'preferencia de idioma',
  },
  previous: {
    en: 'Previous',
    es: 'Anterior',
  },
  previousStep: {
    en: 'Previous Step',
    es: 'Paso anterior',
  },
  saveProfile: {
    en: 'Save Profile',
    es: 'Guardar perfil',
  },
  applicationSentToBorrower: {
    en: 'Application sent for Borrower Correction Successfully.',
    es: 'Solicitud enviada para la corrección del prestatario con éxito.',
  },
  applicationSaveSuccess: {
    en: 'Application Saved Successfully.',
    es: 'Solicitud guardada con éxito.',
  },
  applicationSaveFail: {
    en: 'Error! This tab may not have saved.',
    es: '¡Error! Es posible que esta pestaña no se haya guardado.',
  },
  fetchTabConfigError: {
    en: 'Error! Failed to fetch Tab config details',
    es: '¡Error! Error al obtener los detalles de configuración de la pestaña',
  },
  fetchFieldConfigError: {
    en: 'Error! Failed to fetch Field config details.',
    es: '¡Error! Error al obtener los detalles de configuración del campo',
  },
  fetchProductConfigError: {
    en: 'Error! Failed to fetch Product details',
    es: '¡Error! Error al obtener los detalles del producto',
  },
  fetchPreAssessmentConfigError: {
    en: 'Error! Pre Assessment Configuration could not be fetched.',
    es: '¡Error! No se pudo obtener la configuración de evaluación previa.',
  },
  noProductsConfigured: {
    en: 'Error! No Products configured for the given criteria',
    es: '¡Error! No hay productos configurados para los criterios dados',
  },
  mandatoryDocNotUploaded: {
    en: 'Mandatory Document {} is not uploaded',
    es: 'El documento obligatorio {} no está cargado',
  },
  requiredCopiesNotUploaded: {
    en: 'Required number of copies of Document {} is not uploaded',
    es: 'No se carga el número requerido de copias del documento {}',
  },
  minNumberOfDocsNotUploaded: {
    en: 'Please upload {} of the following mandatory documents: {}',
    es: 'Cargue {} de los siguientes documentos obligatorios: {}',
  },
  copyingDataFromProfile: {
    en: 'Copying data from profile',
    es: 'Copiando datos del perfil',
  },
  copyDataFromProfileSuccess: {
    en: 'Success! Profile Data is copied to the application.',
    es: '¡Éxito! Los datos del perfil se copian en la aplicación.',
  },
  profileSaveSuccess: {
    en: 'Profile Saved Successfully.',
    es: 'Perfil guardado con éxito.',
  },
  profileSaveFailure: {
    en: 'Error! Profile Save Failed. Create/Update profile manually from header by clicking on your username.',
    es: '¡Error! No se pudo guardar el perfil. Cree/Actualice el perfil manualmente desde el encabezado haciendo clic en su nombre de usuario.',
  },
  fetchOrganizationError: {
    en: 'Error! Organization Details could not be fetched.',
    es: '¡Error! No se pudieron recuperar los detalles de la organización.',
  },
  fetchLoanError: {
    en: 'Error! Loan Details could not be fetched.',
    es: '¡Error! No se pudieron obtener los detalles del préstamo.',
  },
  noActionRequired: {
    en: 'No Action Required',
    es: 'No se requiere accion',
  },
  actionRequired: {
    en: 'Action Required',
    es: 'Acción Requerida',
  },
  draft: {
    en: 'Draft',
    es: 'Sequía',
  },
  withdrawSuccess: {
    en: 'Loan Application Withdrawn Successfully.',
    es: 'Solicitud de préstamo retirada con éxito.',
  },
  operationFailure: {
    en: 'Error! Unable to complete operation.',
    es: '¡Error! No se puede completar la operación.',
  },
  operationSuccess: {
    en: 'Operation completed successfully.',
    es: 'La operación se realizó con éxito.',
  },
  maxChars: {
    en: 'Max {} characters',
    es: 'Máx. {} caracteres',
  },
  zipCodeLimit: {
    en: 'Zip Code should be 5 characters',
    es: 'El código postal debe tener 5 caracteres',
  },
  zipPlus4CodeLimit: {
    en: 'Zip+4 Code should be 4 characters',
    es: 'El código postal+4 debe tener 4 caracteres',
  },
  requiredField: {
    en: 'required field',
    es: 'campo requerido',
  },
  addressLine1: {
    en: 'Address Line 1',
    es: 'Dirección 1',
  },
  addressLine2: {
    en: 'Address Line 2',
    es: 'Dirección 2',
  },
  city: {
    en: 'City',
    es: 'Ciudad',
  },
  state: {
    en: 'State',
    es: 'Estado',
  },
  country: {
    en: 'Country',
    es: 'País',
  },
  zip: {
    en: 'Zip',
    es: 'Código postal',
  },
  zipCode: {
    en: 'Zip Code',
    es: 'Código postal',
  },
  zipPlus4: {
    en: 'Zip + 4',
    es: 'Código postal + 4',
  },
  internationalAddress: {
    en: 'International Address',
    es: 'Dirección Internacional',
  },
  alternateAddress: {
    en: 'Alternate Address',
    es: 'Direccion Alternativa',
  },
  applicationSubmissionCardHeader: {
    en: 'Thank you for Submitting your Application.',
    es: 'Thank you for Submitting your Application.',
  },
  applicationSubmissionCardText1: {
    en: 'Documents are being generated for electronic signature.',
    es: 'Se están generando documentos para firma electrónica..',
  },
  applicationSubmissionCardText2: {
    en: 'Once complete, the documents will be available for review and electronic signature.',
    es: 'Una vez completados, los documentos estarán disponibles para su revisión y firma electrónica.',
  },
  applicationSubmissionCardText3: {
    en: 'Please check your email for additional information.',
    es: 'Por favor revise su correo electrónico para obtener información adicional.',
  },
  applicationSubmissionCardText4: {
    en: 'This may take a few moments.<br/>Please do not exit, refresh or click the back button on your browser while the documents are loading!',
    es: 'Esto puede tomar unos minutos.<br/>No salga, actualice ni haga clic en el botón Atrás de su navegador mientras se cargan los documentos!',
  },
  applicationNumberLabel: {
    en: 'Your Application Number is',
    es: 'Su número de solicitud es',
  },
  whatNext: {
    en: "What's Next?",
    es: '¿Que sigue?',
  },
  selectOne: {
    en: 'At least one must be selected',
    es: 'Al menos uno debe ser seleccionado',
  },
  selectField: {
    en: 'Field must be selected',
    es: 'El campo debe ser seleccionado',
  },
  proceed: {
    en: 'Do you want to proceed?',
    es: 'Quieres proceder?',
  },
  applyLoan: {
    en: 'Apply for Loan.',
    es: 'Solicitar préstamo.',
  },
  portalText: {
    en: 'This portal is made available for Loan applications.',
    es: 'Este portal está disponible para las solicitudes de préstamo.',
  },
  dashboardBrandText: {
    en: 'After registration, you may use this streamlined process to automatically submit your loan request',
    es: 'Después del registro, puede usar este proceso simplificado para enviar automáticamente su solicitud de préstamo',
  },
  to: {
    en: 'to',
    es: 'a',
  },
  errorEsignCardTitle: {
    en: 'Please review the fields within your application and submit for electronic signature below.',
    es: 'Revise los campos dentro de su solicitud y envíelos para su firma electrónica a continuación.',
  },
  errorEsignCardText: {
    en: "<ul> <li> If you need to make changes after reviewing the documents, please click <b>Decline to Sign</b> under the <b>Other Actions</b> dropdown within the document. </li> <li> Selecting <b>Finish Later</b> will <span class='text-danger fw-bold'>not</span> allow you to edit the application. </li> <li> If you've selected <b>Decline to Sign</b>, please wait a few minutes before edit or sign options become available. </li> </ul> If you have other required signers, they will receive an email from <b>DocuSign</b> in their email inbox which should prompt them to electronically sign. <br /><br /> After all required parties have completed the electronic signature, an email confirmation of the completed document will be available to each signer within their email inbox.",
    es: "<ul> <li> Si necesita realizar cambios después de revisar los documentos, haga clic en <b>Rechazar firmar</b> en el menú desplegable <b>Otras acciones</b> dentro del documento. </li> <li> Si selecciona <b>Finalizar más tarde</b>, <span class='text-danger fw-bold'>no</span> le permitirá editar la aplicación. </li> <li> Si seleccionó <b>Rechazar firmar</b>, espere unos minutos antes de que las opciones de edición o firma estén disponibles. </li> </ul> Si tiene otros firmantes requeridos, recibirán un correo electrónico de <b>DocuSign</b> en su bandeja de entrada de correo electrónico que les pedirá que firmen electrónicamente. <br /><br /> Después de que todas las partes requeridas hayan completado la firma electrónica, una confirmación por correo electrónico del documento completo estará disponible para cada firmante en su bandeja de entrada de correo electrónico.",
  },
  eSignDetailsText: {
    en: 'The following phone numbers and email addresses will be used for the electronic signature process. Please confirm the following phone numbers are in service and accessible as they will be used for <u><b>phone call authentication.</b></u>',
    es: 'Los siguientes números de teléfono y direcciones de correo electrónico se utilizarán para el proceso de firma electrónica. Confirme que los siguientes números de teléfono estén en servicio y accesibles, ya que se utilizarán para <u><b>autenticación de llamadas telefónicas.</b></u>',
  },
  authorizedSignerFor: {
    en: 'Authorized Signer for',
    es: 'Firmante autorizado para',
  },
  dismiss: {
    en: 'Dismiss',
    es: 'Descartar',
  },
  sessionExpired: {
    en: 'Your session has expired.',
    es: 'Su sesión ha caducado.',
  },
  sessionExpiredMessage: {
    en: 'Session has expired due to inactivity. Please sign in again.',
    es: 'La sesión ha expirado debido a la inactividad. Inicie sesión de nuevo.',
  },
  entityType: {
    en: 'Entity Type',
    es: 'Tipo de entidad',
  },
  taxIdNumber: {
    en: 'Tax ID Number',
    es: 'Número de identificación tributaria',
  },
  businessOrFullName: {
    en: 'Business/Full Name',
    es: 'Empresa/Nombre completo',
  },
  dbaName: {
    en: 'Doing Business As (DBA)',
    es: 'Hacer negocios como (DBA)',
  },
  taxIdType: {
    en: 'Tax ID Type',
    es: 'Tipo de identificación fiscal',
  },
  businessName: {
    en: 'Business Name',
    es: 'Nombre del Negocio',
  },
  firstName: {
    en: 'First Name',
    es: 'Primer nombre',
  },
  middleName: {
    en: 'Middle Name',
    es: 'Segundo nombre',
  },
  lastName: {
    en: 'Last Name',
    es: 'Apellido',
  },
  suffix: {
    en: 'Suffix',
    es: 'Sufijo',
  },
  phoneNumberText: {
    en: 'Phone Number (we will use this phone number for applicable e-Sign authentication requirements)',
    es: 'Número de teléfono (usaremos este número de teléfono para los requisitos de autenticación de firma electrónica aplicables)',
  },
  mainBusinessPhone: {
    en: 'Main Business Phone Number',
    es: 'Número de teléfono principal de la empresa',
  },
  faxNumberText: {
    en: 'Fax',
    es: 'Fax',
  },
  emailText: {
    en: 'Email Address (we will use this email address for applicable e-Sign notifications)',
    es: 'Dirección de correo electrónico (usaremos esta dirección de correo electrónico para las notificaciones de firma electrónica correspondientes)',
  },
  phoneNumber: {
    en: 'Phone Number',
    es: 'Número de teléfono',
  },
  email: {
    en: 'Email Address',
    es: 'Correo electrónico',
  },
  phoneNumberToolTip: {
    en: 'If applicable, a phone call will be made to this phone number to authenticate your identity during the electronic signature process',
    es: 'Si corresponde, se realizará una llamada telefónica a este número de teléfono para autenticar su identidad durante el proceso de firma electrónica',
  },
  emailToolTip: {
    en: 'If applicable, notifications to complete and confirmation of electronic signature will be sent to this email address',
    es: 'En su caso, las notificaciones para completar y la confirmación de la firma electrónica se enviarán a esta dirección de correo electrónico',
  },
  signerEmailText: {
    en: 'Signer Email Address (we will use this email address for applicable e-Sign notifications)',
    es: 'Dirección de correo electrónico del firmante (usaremos esta dirección de correo electrónico para las notificaciones de firma electrónica correspondientes)',
  },
  dateOfBirth: {
    en: 'Date of Birth',
    es: 'Fecha de nacimiento',
  },
  stateOfEstablishment: {
    en: 'State of Establishment',
    es: 'Estado de establecimiento',
  },
  needAdditionalSigner: {
    en: 'Does your business require an additional authorized signer?',
    es: '¿Su negocio requiere un firmante autorizado adicional?',
  },
  needAdditionalSignerToolTip: {
    en: 'If your business requires more than one individual to sign loan documents on behalf of the company, please select “Yes” and enter the second signer’s information.',
    es: 'Si su empresa requiere que más de una persona firme documentos de préstamo en nombre de la empresa, seleccione "Sí" e ingrese la información del segundo firmante.',
  },
  primarySigner: {
    en: 'Primary Authorized Signer',
    es: 'Firmante principal autorizado',
  },
  secondarySigner: {
    en: 'Secondary Authorized Signer',
    es: 'Firmante autorizado secundario',
  },
  invalidEmailAddress: {
    en: 'invalid email address',
    es: 'dirección de correo electrónico no válida',
  },
  invalidPhoneFormat: {
    en: 'invalid phone number format',
    es: 'formato de número de teléfono no válido',
  },
  invalidITINFormat: {
    en: 'ITIN must start with 9',
    es: 'ITIN debe comenzar con 9',
  },
  invalidTINFormat: {
    en: 'invalid Tax ID Number format',
    es: 'formato de número de identificación fiscal no válido',
  },
  signerToolTip: {
    en: 'An authorized signer is an individual that has the authority to sign loan documents on behalf of the business.',
    es: 'Un firmante autorizado es una persona que tiene la autoridad para firmar documentos de préstamo en nombre de la empresa.',
  },
  signerFirstName: {
    en: 'Signer First Name',
    es: 'Nombre del firmante',
  },
  signerMiddleName: {
    en: 'Signer Middle Name',
    es: 'Segundo nombre del firmante',
  },
  signerLastName: {
    en: 'Signer Last Name',
    es: 'Apellido del firmante',
  },
  signerEmail: {
    en: 'Signer Email Address',
    es: 'Dirección de correo electrónico del firmante',
  },
  position: {
    en: 'Position',
    es: 'Posición',
  },
  signerPosition: {
    en: 'Signer Position',
    es: 'Posición del firmante',
  },
  certifyText: {
    en: ' I certify that the supporting documentation provided is true and accurate in all material respects.',
    es: ' Certifico que la documentación de respaldo proporcionada es verdadera y precisa en todos los aspectos importantes.',
  },
  editUploadDocText: {
    en: 'You can edit and upload documents to your "Documents Briefcase" under Your Profile by clicking on your username in the top right hand corner of the application',
    es: 'Puede editar y cargar documentos en su "Maletín de documentos" en Su perfil haciendo clic en su nombre de usuario en la esquina superior derecha de la aplicación.',
  },
  getDocFromBriefcaseButton: {
    en: 'Get Documents from Briefcase',
    es: 'Obtener documentos del maletín',
  },
  nameFilesText: {
    en: 'Please name your files contextually.',
    es: 'Nombre sus archivos contextualmente.',
  },
  attachmentTextDoc: {
    en: 'Each attachment must be <strong class="text-danger">under 35 megabytes</strong> in size to ensure successful submission. <strong> DO NOT ENCRYPT</strong> your documents, this will result in us being unable to process them.',
    es: 'Cada archivo adjunto debe tener un tamaño <strong class="text-danger">menos de 35 megabytes</strong> para garantizar que se envíe correctamente. <strong> NO CIFRAR</strong> sus documentos, esto hará que no podamos procesarlos.',
  },
  attachmentTextDocAlt: {
    en: 'Each attachment must be <strong>under 35 megabytes</strong> in size to ensure successful submission.',
    es: 'Cada archivo adjunto debe tener un tamaño <strong>menos de 35 megabytes</strong> para garantizar que se envíe correctamente. ',
  },
  doNotEncrypt: {
    en: '<strong> DO NOT ENCRYPT</strong> your documents.',
    es: '<strong> NO CIFRAR</strong> sus documentos.',
  },
  validFileTypesText: {
    en: 'Valid file types:',
    es: 'Tipos de archivos válidos:',
  },
  reqdDocsText: {
    en: 'Required Documents (If applicable)',
    es: 'Documentos requeridos (si corresponde)',
  },
  noDocUpldText: {
    en: 'No documents uploaded',
    es: 'No hay documentos subidos',
  },
  upload: {
    en: 'Upload',
    es: 'Subir',
  },
  docCatText: {
    en: 'Document Category :',
    es: 'Categoría del documento :',
  },
  description: {
    en: 'Description :',
    es: 'Descripción :',
  },
  uploadAtleast: {
    en: 'Upload at least ',
    es: 'Sube al menos ',
  },
  ofText: {
    en: 'of',
    es: 'de',
  },
  optionDocsText: {
    en: 'Optional Documents',
    es: 'Documentos Opcionales',
  },
  upldAddDocText: {
    en: 'Upload Additional Documents',
    es: 'Cargar documentos adicionales',
  },
  upldDocText: {
    en: 'Upload Document',
    es: 'Subir documento',
  },
  docTypeText: {
    en: 'Document Type',
    es: 'Tipo de Documento',
  },
  tagToOwner: {
    en: 'Tag to Owner',
    es: 'Etiqueta al dueño',
  },
  docName: {
    en: 'Document Name',
    es: 'Nombre del documento',
  },
  uploadedDocs: {
    en: 'Uploaded Documents',
    es: 'Documentos cargados',
  },
  sureDelDoc: {
    en: 'Are you sure you want to delete this document?',
    es: '¿Está seguro de que desea eliminar este documento?',
  },
  viewDoc: {
    en: 'View Document',
    es: 'Ver documento',
  },
  editDoc: {
    en: 'Edit Document',
    es: 'Editar documento',
  },
  deleteDoc: {
    en: 'Delete Document',
    es: 'Eliminar documento',
  },
  editUpldDoc: {
    en: 'Edit Uploaded Document',
    es: 'Editar documento cargado',
  },
  selectDocFromBC: {
    en: 'Select the documents which you need to copy from briefcase',
    es: 'Seleccione los documentos que necesita copiar del maletín',
  },
  attachDocToApp: {
    en: 'Attach Documents to Application',
    es: 'Adjuntar documentos a la solicitud',
  },
  verifyEmailHeader: {
    en: 'Email Verification Status',
    es: 'Estado de verificación de correo electrónico ',
  },
  getVerificationStatus: {
    en: 'Get Verification Status',
    es: 'Obtener estado de verificación',
  },
  sendVerificationEmail: {
    en: 'Send Verification Email',
    es: 'Enviar correos electrónicos de verificación',
  },
  verificationStatus: {
    en: 'Verification Status',
    es: 'Estado de verificación',
  },
  verificationSentOn: {
    en: 'Sent On',
    es: 'Enviada el',
  },
  relation: {
    en: 'Relation',
    es: 'Relación',
  },
  relationType: {
    en: 'Relation Type',
    es: 'Tipo de relación',
  },
  newMessage: {
    en: 'New Message',
    es: 'Nuevo mensaje',
  },
  messageType: {
    en: 'Message Type',
    es: 'Tipo de mensaje',
  },
  subject: {
    en: 'Subject',
    es: 'Sujeta',
  },
  message: {
    en: 'Message',
    es: 'Mensaje',
  },
  sendMessage: {
    en: 'Send Message',
    es: 'Enviar mensaje',
  },
  sendAMessage: {
    en: 'Send A Message',
    es: 'Enviar un mensaje',
  },
  messageSentSuccess: {
    en: 'Your message has been sent successfully!',
    es: 'Tu mensaje ha sido enviado exitosamente!',
  },
  messageSent: {
    en: 'Message Sent!',
    es: '¡Mensaje enviado!',
  },
  selectFile: {
    en: 'Select File',
    es: 'Seleccione Archivo',
  },
  documentUpload: {
    en: 'Document Upload',
    es: 'Carga de documentos',
  },
  fileLimitExceeded: {
    en: 'Uploaded file cannot be more than 35 MB',
    es: 'El archivo subido no puede tener más de 35 MB',
  },
  fileExtensionError: {
    en: 'Please upload files having extensions:{} only.',
    es: 'Cargue archivos que tengan extensiones:{} solamente.',
  },
  uploadDocumentFailed: {
    en: 'Error! Unable to upload document',
    es: '¡Error! No se puede cargar el documento',
  },
  withdrawApplication: {
    en: 'Withdraw the Application',
    es: 'Retirar la Solicitud',
  },
  continueToEdit: {
    en: 'Continue to Edit',
    es: 'Continuar para editar',
  },
  esignStatus: {
    en: 'E-Signature Status',
    es: 'Estado de la firma electrónica',
  },
  docusignDeclined: {
    en: 'DocuSign Request was Declined.',
    es: 'La solicitud de DocuSign fue rechazada.',
  },
  applicationReadyForEsign: {
    en: 'Your Application is Ready for Electronic Signature.',
    es: 'Su solicitud está lista para la firma electrónica.',
  },
  readyForESign: {
    en: 'You’re almost there! <br/>To complete your application, sign the electronic signature you have received via email.',
    es: '¡Ya casi estás ahí! <br/>Para completar su solicitud, firme la firma electrónica que ha recibido por correo electrónico.',
  },
  reviewToSign: {
    en: 'Please review and click the button below to sign documents.',
    es: 'Revise y haga clic en el botón a continuación para firmar documentos.',
  },
  reviewToESign1: {
    en: 'All applicable borrowers, owners, and guarantors may either receive an email or be prompted to complete the electronic signature document through the portal. Upon completion, each signer can download the signed application via a confirmation email.',
    es: 'Todos los prestatarios, propietarios y garantes aplicables pueden recibir un correo electrónico o se les puede solicitar que completen el documento de firma electrónica a través del portal. Al finalizar, cada firmante puede descargar la solicitud firmada a través de un correo electrónico de confirmación.',
  },
  reviewToESign2: {
    en: 'The application will update to "Submitted" status when all signers have completed signing. Your lender will then be able to process your loan request.',
    es: 'La solicitud se actualizará al estado "Enviado" cuando todos los firmantes hayan completado la firma. Su prestamista podrá entonces procesar su solicitud de préstamo.',
  },
  reviewToESign3: {
    en: 'Please continue to refresh the page after completing the signing process to update the status.',
    es: 'Continúe actualizando la página después de completar el proceso de firma para actualizar el estado.',
  },
  applicationWithdrawnText: {
    en: 'Your application has been withdrawn. To resubmit an application, please navigate to the home screen and start a new application. Please contact your lender with any questions.',
    es: 'Su solicitud ha sido retirada. Para volver a enviar una solicitud, vaya a la pantalla de inicio e inicie una nueva solicitud. Comuníquese con su prestamista si tiene alguna pregunta.',
  },
  applicationReviewText: {
    en: 'Your application is currently under review. Please check your email for application updates.',
    es: 'Su solicitud se encuentra actualmente en revisión. Por favor revise su correo electrónico para actualizaciones de la aplicación.',
  },
  applicationWithdrawOrEditText: {
    en: 'You can either edit the application or withdraw the application using the buttons below.',
    es: 'Puede editar la solicitud o retirarla utilizando los botones a continuación.',
  },
  contactLenderText: {
    en: 'Please contact your lender if you have further questions about your application.',
    es: 'Comuníquese con su prestamista si tiene más preguntas sobre su solicitud.',
  },
  viewSignedApplication: {
    en: 'View Signed Application',
    es: 'Ver solicitud firmada',
  },
  eSignProcessingText: {
    en: 'Your electronic signature document is processing, please refresh page after a few moments.',
    es: 'Su documento de firma electrónica se está procesando, actualice la página después de unos momentos.',
  },
  eSignProcessText: {
    en: 'All applicable borrowers, owners, and guarantors will receive an email to complete the required e-signatures. Upon e-sign completion, you can download the complete application for your records, and the bank will begin to process your request.',
    es: 'Todos los prestatarios, propietarios y garantes correspondientes recibirán un correo electrónico para completar las firmas electrónicas requeridas. Al completar la firma electrónica, puede descargar la solicitud completa para sus registros y el banco comenzará a procesar su solicitud.',
  },
  identificationDocHeader: {
    en: 'Identification Document Details',
    es: 'Detalles del documento de identificación',
  },
  primaryId: {
    en: 'Primary ID',
    es: 'Identificación principal',
  },
  secondaryId: {
    en: 'Secondary ID',
    es: 'Identificación secundaria',
  },
  idType: {
    en: 'ID Type',
    es: 'tipo de identificaciónID principal',
  },
  idNumber: {
    en: 'ID Number',
    es: 'Número de identificación',
  },
  issuedCountry: {
    en: 'Issued by Country',
    es: 'Emitido por país',
  },
  issuedState: {
    en: 'Issued by State',
    es: 'Emitido por el Estado',
  },
  dateOfIssue: {
    en: 'Date of Issue',
    es: 'Fecha de emisión',
  },
  dateOfExpiry: {
    en: 'Date of Expiry',
    es: 'Fecha de expiración',
  },
  nationality: {
    en: 'Nationality',
    es: 'Nacionalidad',
  },
  fileUploadToolTip: {
    en: 'Please upload a <b>clear photo or scan</b> of your identification listed in this section.',
    es: 'Cargue una <b>foto clara o escaneada</b> de su identificación que figura en esta sección.',
  },
  identificationDocument: {
    en: 'Identification Document',
    es: 'Documento de identificación',
  },
  fileUploadHint: {
    en: 'Use Documents screen to delete or re-upload the document.',
    es: 'Utilice la pantalla Documentos para eliminar o volver a cargar el documento.',
  },
  selectFileToUpload: {
    en: 'Please Select a File to Upload',
    es: 'Seleccione un archivo para cargar',
  },
  docUploadSuccess: {
    en: 'Document Uploaded Successfully',
    es: 'Documento cargado con éxito',
  },
  docUploadProgress: {
    en: 'Document Upload in progress',
    es: 'Carga de documentos en curso',
  },
  docDeleteProgress: {
    en: 'Document Deletion in progress',
    es: 'Eliminación de documentos en curso',
  },
  deleteFailed: {
    en: 'failed to delete',
    es: 'no se pudo eliminar',
  },
  updateFailed: {
    en: 'failed to update',
    es: 'no se pudo actualizar',
  },
  fetchingDocumentsFromBriefcase: {
    en: 'Fetching documents from briefcase...',
    es: 'Obtención de documentos del maletín...',
  },
  uploadingDocumentsFromBriefcase: {
    en: 'Uploading documents from briefcase...',
    es: 'Subiendo documentos del maletín...',
  },
  docUploadFileHint: {
    en: '<b>Valid file types: pdf, xls, xlsx, csv, doc, docx, jpg, jpeg, and png.</b> All uploads must be <span class="text-danger fw-bold">under 35 MB</span>.',
    es: '<b>Tipos de archivos válidos: pdf, xls, xlsx, csv, doc, docx, jpg, jpeg y png.</b> Todas las cargas deben tener <span class="text-danger fw-bold">menos de 35 MB </span>.',
  },
  borrower: {
    en: 'Borrower',
    es: 'Prestatario',
  },
  uploadRequestedDocument: {
    en: 'Upload Requested Documents',
    es: 'Subir documentos solicitados',
  },
  uploadDocument: {
    en: 'Upload Document',
    es: 'Subir documentos',
  },
  reject: {
    en: 'Reject',
    es: 'Rechazar',
  },
  rejectTerms: {
    en: 'Reject Terms',
    es: 'Términos de rechazo',
  },
  accept: {
    en: 'Accept',
    es: 'Aceptar',
  },
  acceptTerms: {
    en: 'Accept Terms',
    es: 'Aceptar los terminos',
  },
  reply: {
    en: 'Reply',
    es: 'Respuesta',
  },
  lenderActionPending: {
    en: 'Lender Action Pending',
    es: 'Acción del prestamista pendiente',
  },
  borrowerActionPending: {
    en: 'Borrower Action Pending',
    es: 'Acción del prestatario pendiente',
  },
  request: {
    en: 'Request',
    es: 'Solicitud',
  },
  context: {
    en: 'Context',
    es: 'Contexto',
  },
  sent: {
    en: 'Sent',
    es: 'Enviada',
  },
  uploadAllDocuments: {
    en: 'Error! Upload all requested documents',
    es: '¡Error! Sube todos los documentos solicitados',
  },
  resolveConflicts: {
    en: 'Error!  Failed to update Resolved Conflicts',
    es: '¡Error! Error al actualizar Conflictos Resueltos',
  },
  sendingMessage: {
    en: 'Sending Message',
    es: 'Enviando mensaje',
  },
  sendMessageError: {
    en: 'Error! Cannot send message',
    es: '¡Error! No se puede enviar el mensaje',
  },
  loadingMessage: {
    en: 'Loading Message',
    es: 'Mensaje de carga',
  },
  fetchConditionError: {
    en: 'Error! Cannot fetch Conditions',
    es: '¡Error! No se pueden obtener las condiciones',
  },
  fetchCounterOfferError: {
    en: 'Error! Cannot fetch Counter Offers',
    es: '¡Error! No se pueden obtener contraofertas',
  },
  markReadError: {
    en: 'Error! Cannot mark message as read',
    es: '¡Error! No se puede marcar el mensaje como leído',
  },
  lockMessageError: {
    en: 'Error! Cannot lock message',
    es: '¡Error! No se puede bloquear el mensaje',
  },
  unlockMessageError: {
    en: 'Error! Cannot unlock message',
    es: '¡Error! No se puede desbloquear el mensaje',
  },
  fetchDocumentsError: {
    en: 'Error! Cannot fetch documents',
    es: '¡Error! No se pueden obtener documentos',
  },
  genericError: {
    en: 'Error! Something went wrong',
    es: '¡Error! Algo salió mal',
  },
  docDeleteSuccess: {
    en: 'Document deleted successfully',
    es: 'Documento eliminado con éxito',
  },
  docDeleteFailure: {
    en: 'Error! Unable to delete document',
    es: '¡Error! No se puede eliminar el documento',
  },
  searchMessages: {
    en: 'Search Messages',
    es: 'Mensajes de búsqueda',
  },
  switchMessageLbl: {
    en: 'View messages pending your action',
    es: 'Ver mensajes pendientes de su acción',
  },
  add: {
    en: 'Add',
    es: 'Agregar',
  },
  addBorrower: {
    en: 'Add Borrower',
    es: 'Agregar prestatario',
  },
  primaryBorrower: {
    en: 'Primary Borrower',
    es: 'Prestataria Primaria',
  },
  owner: {
    en: 'Owner',
    es: 'Dueño',
  },
  director: {
    en: 'Director',
    es: 'Directora/Director',
  },
  managing_member: {
    en: 'Managing Member',
    es: 'Miembro administrador',
  },
  officer: {
    en: 'Officer',
    es: 'Oficial',
  },
  key_employee: {
    en: 'Key Employee',
    es: 'Empleada/Empleado clave',
  },
  representative: {
    en: 'Authorized Signer',
    es: 'Firmante autorizada',
  },
  systemCreated: {
    en: 'System Created',
    es: 'Sistema creado',
  },
  guarantor: {
    en: 'Guarantor',
    es: 'Garante',
  },
  nonOwnerGuarantor: {
    en: 'Non-Owner Guarantor',
    es: 'Garante no propietaria',
  },
  collateralOwner: {
    en: 'Collateral Owner',
    es: 'Propietario de la garantía',
  },
  addOwner: {
    en: 'Add Owner',
    es: 'Añadir propietario',
  },
  addRelation: {
    en: 'Add Relation',
    es: 'Agregar relación',
  },
  delete: {
    en: 'Delete',
    es: 'Eliminar',
  },
  deletePartyDetails: {
    en: 'Delete Party Details',
    es: 'Eliminar detalles de la fiesta',
  },
  editPartyDetails: {
    en: 'Edit Party Details',
    es: 'Editar detalles de la fiesta',
  },
  missingInformation: {
    en: 'Missing Information',
    es: 'Información faltante',
  },
  new: {
    en: 'New',
    es: 'Nueva',
  },
  details: {
    en: 'Details',
    es: 'Detalles',
  },
  for: {
    en: 'for',
    es: 'por',
  },
  cancelConfirmText: {
    en: 'Are you sure you want to cancel?',
    es: 'Estas seguro que quieres cancelar?',
  },
  deleteConfirmText: {
    en: 'Are you sure you want to delete this data?',
    es: '¿Está seguro de que desea eliminar estos datos?',
  },
  partyType: {
    en: 'Party Type',
    es: 'Tipo de parte relacionada',
  },
  beneficialOwnerText: {
    en: 'Does this individual have significant responsibility for managing the entity?',
    es: '¿Tiene esta persona una responsabilidad significativa en la gestión de la entidad?',
  },
  beneficialOwnerToolTip: {
    en: 'Federal regulation requires certain financial institutions to obtain, verify, and record information about the beneficial owners of legal entities, including the identification of one individual with significant responsibility for managing the entity. This is also known as a <b>"Beneficial Owner by Control."</b>',
    es: 'La regulación federal requiere que ciertas instituciones financieras obtengan, verifiquen y registren información sobre los beneficiarios reales de las entidades legales, incluida la identificación de una persona con responsabilidad significativa en la administración de la entidad. Esto también se conoce como <b>"Propietario real por control".</b>',
  },
  controllingEntityHint: {
    en: '<b>Note: </b> There can be only one controlling entity for a business/borrower. Examples of significant responsibility for managing the entity include  Chief Executive Officer, Chief Financial Officer, Chief Operating Officer, Managing Member, General Partner, President, Vice President, Treasurer or any other individual who regularly performs similar functions.',
    es: '<b>Nota: </b> Solo puede haber una entidad de control para una empresa/prestatario. Ejemplos de responsabilidad significativa para administrar la entidad incluyen Director Ejecutivo, Director Financiero, Director de Operaciones, Miembro Gerente, Socio General, Presidente, Vicepresidente, Tesorero o cualquier otra persona que desempeñe funciones similares regularmente.',
  },
  ownershipPercentage: {
    en: 'Ownership Percentage',
    es: 'Porcentaje de participación',
  },
  physicalAddress: {
    en: 'Physical Address',
    es: 'Dirección física',
  },
  mailingAddress: {
    en: 'Mailing Address',
    es: 'Dirección de envio',
  },
  projectAddress: {
    en: 'Project Address',
    es: 'Dirección del proyecto',
  },
  alterAddressCheckBoxLabel: {
    en: 'Check if the mailing address is the same as the physical address',
    es: 'Compruebe si la dirección postal es la misma que la dirección física',
  },
  identificationApplicableText: {
    en: 'Identification Information is applicable only for Individuals',
    es: 'La información de identificación es aplicable solo para individuos',
  },
  fetchVerifyEmailError: {
    en: 'Error! Could not fetch Verification status',
    es: '¡Error! No se pudo obtener el estado de verificación',
  },
  verificationSentSuccess: {
    en: 'Email verification link has been sent to requested email.',
    es: 'El enlace de verificación de correo electrónico se ha enviado al correo electrónico solicitado.',
  },
  verifyEmailToolTip: {
    en: 'An email will be sent to all unverified email addresses below. Please click the link within the email to confirm successful delivery. The following emails will be used in the electronic signature process.',
    es: 'Se enviará un correo electrónico a todas las direcciones de correo electrónico no verificadas a continuación. Haga clic en el enlace dentro del correo electrónico para confirmar la entrega exitosa. Los siguientes correos electrónicos se utilizarán en el proceso de firma electrónica.',
  },
  noBorrowersProfile: {
    en: 'No borrowers added',
    es: 'No hay prestatarios añadidos',
  },
  noBorrowersApplication: {
    en: 'No borrowers added for this loan application',
    es: 'No se agregaron prestatarios para esta solicitud de préstamo',
  },
  maxOwnersReached: {
    en: 'Error! Cannot add any more owners',
    es: '¡Error! No se pueden agregar más propietarios',
  },
  correctErrorOnScreen: {
    en: 'Error! Please correct the errors on screen',
    es: '¡Error! Corrija los errores en pantalla',
  },
  deletePartyError: {
    en: 'Error! Unable to delete at this moment, save data and go back to dashboard and try again',
    es: '¡Error! No se puede eliminar en este momento, guarde los datos y vuelva al panel e intente nuevamente',
  },
  identificationSaveError: {
    en: 'Error! Identification Information was not saved',
    es: '¡Error! La información de identificación no se guardó',
  },
  addressSaveError: {
    en: 'Error! Relation Address was not saved',
    es: '¡Error! La dirección de relación no se guardó',
  },
  addressSaveSuccess: {
    en: 'Address was saved',
    es: 'La dirección fue guardada',
  },
  identificationSaveSuccess: {
    en: 'Identification was saved',
    es: 'Se guardó la identificación',
  },
  docUploadConsent: {
    en: 'Please accept the required consent',
    es: 'Por favor acepte el consentimiento requerido',
  },
  actions: {
    en: 'Actions',
    es: 'Comportamiento',
  },
  action: {
    en: 'Action',
    es: 'Comportamiento',
  },
  entity: {
    en: 'Entity',
    es: 'Entidad',
  },
  individual: {
    en: 'Individual',
    es: 'Individual',
  },
  drivers_license: {
    en: "Driver's License",
    es: 'Licencia de conducir',
  },
  state_federal_issued: {
    en: 'State or Federal Government Issued ID',
    es: 'Identificación emitida por el gobierno estatal o federal',
  },
  us_passport: {
    en: 'US Passport',
    es: 'US Pasaporte',
  },
  us_green_card: {
    en: 'US Permanent Resident Card (Green Card)',
    es: 'Tarjeta de residente permanente de US (Tarjeta verde)',
  },
  military_id: {
    en: 'US Armed Forces or Military ID',
    es: 'Identificación militar o de las Fuerzas Armadas de US',
  },
  local_employment: {
    en: 'Known Local Employment ID Card',
    es: 'Tarjeta de Identificación de Empleo Local Conocida',
  },
  student: {
    en: 'Student ID',
    es: 'Identificación del Estudiante',
  },
  birth: {
    en: 'Birth Certificate',
    es: 'Certificado de nacimiento',
  },
  vehicle_registration: {
    en: 'Vehicle Registration Card',
    es: 'Tarjeta de registro de vehículos',
  },
  medical_insurance: {
    en: 'Medical Insurance Card',
    es: 'Tarjeta de seguro médico',
  },
  voter_registration: {
    en: 'Voter Registration Card',
    es: 'Credencial de elector',
  },
  social: {
    en: 'Social Security Card',
    es: 'Tarjeta de seguro Social',
  },
  arms_license: {
    en: 'License to Carry Arms',
    es: 'Licencia para portar armas',
  },
  bill: {
    en: 'Property Tax Bill or Utility Bill',
    es: 'Factura de impuestos a la propiedad o factura de servicios públicos',
  },
  'Chief Executive Officer': {
    en: 'Chief Executive Officer',
    es: 'Director Ejecutivo',
  },
  'Chief Financial Officer': {
    en: 'Chief Financial Officer',
    es: 'Director financiero',
  },
  'Chief Operating Officer': {
    en: 'Chief Operating Officer',
    es: 'Director de operaciones',
  },
  'General Partner': {
    en: 'General Partner',
    es: 'Socio general',
  },
  'Limited Partner': {
    en: 'Limited Partner',
    es: 'Compañero limitado',
  },
  Manager: {
    en: 'Manager',
    es: 'Gerente',
  },
  'Managing Member': {
    en: 'Managing Member',
    es: 'Miembro administrador',
  },
  Member: {
    en: 'Member',
    es: 'Miembro',
  },
  Officer: {
    en: 'Officer',
    es: 'Oficial',
  },
  Owner: {
    en: 'Owner',
    es: 'Dueña',
  },
  Partner: {
    en: 'Partner',
    es: 'Compañero',
  },
  President: {
    en: 'President',
    es: 'Presidente',
  },
  Secretary: {
    en: 'Secretary',
    es: 'Secretario',
  },
  Treasurer: {
    en: 'Treasurer',
    es: 'Tesorero',
  },
  'Vice President': {
    en: 'Vice President',
    es: 'Vicepresidente',
  },
  typeOfDamage: {
    en: 'Type Of Damage',
    es: 'Tipo de daño',
  },
  realEstate: {
    en: 'Real Estate',
    es: 'Bienes raíces',
  },
  personalProperty: {
    en: 'Personal Property',
    es: 'Propiedad personal',
  },
  automobile: {
    en: 'Automobile',
    es: 'Automóvil',
  },
  propertyOwnership: {
    en: 'Do you own or rent this property?',
    es: '¿Eres dueña o alquila esta propiedad?',
  },
  isPrimaryResidence: {
    en: 'Is this property your Primary Residence?',
    es: '¿Es esta propiedad su residencia principal?',
  },
  propertyResidenceDetail: {
    en: 'If No, please select from the list below:',
    es: 'Si la respuesta es No, seleccione de la lista a continuación:',
  },
  vacation_secondary_home: {
    en: 'Vacation/secondary home',
    es: 'Vacation/secondary home',
  },
  family_home: {
    en: 'I own the property but a family member/friend lives in the property',
    es: 'I own the property but a family member/friend lives in the property',
  },
  rental_home: {
    en: 'Rental/Business Property',
    es: 'Alquiler/propiedad comercial',
  },
  own: {
    en: 'Own',
    es: 'Propia',
  },
  rent: {
    en: 'Rent',
    es: 'Renta',
  },
  addDamagedAddress: {
    en: 'Add Another Damaged Property Address',
    es: 'Agregar otra dirección de propiedad dañada',
  },
  insuranceDetails: {
    en: 'Insurance Details',
    es: 'Detalles del seguro',
  },
  insuranceTypesLabel: {
    en: 'Please check all insurance in force for the damaged property',
    es: 'Por favor verifique todos los seguros vigentes para la propiedad dañada',
  },
  homeowners: {
    en: "Homeowner's",
    es: 'Del propietario',
  },
  flood: {
    en: 'Flood',
    es: 'Inundación',
  },
  renters: {
    en: "Renter's",
    es: 'Inquilina',
  },
  no_insurance: {
    en: 'No Insurance',
    es: 'En un mundo turbulento',
  },
  other: {
    en: 'Other',
    es: 'Otra',
  },
  describeOther: {
    en: '(describe)',
    es: '(describir)',
  },
  policyType: {
    en: 'Policy Type',
    es: 'Tipo de póliza',
  },
  insuranceCompanyName: {
    en: 'Insurance Company Name',
    es: 'Nombre de la compañía de seguros',
  },
  policyNumber: {
    en: 'Policy Number',
    es: 'Número de póliza',
  },
  amountReceived: {
    en: 'Amount Received',
    es: 'Cantidad recibida',
  },
  businessStructure: {
    en: 'Business Structure',
    es: 'Estructura de negocio',
  },
  atleastOneBorrower: {
    en: 'Enter at least one borrower',
    es: 'Introduzca al menos un prestatario',
  },
  onlyOneBeneficialOwner: {
    en: 'There can only be one Beneficial Owner by control',
    es: 'Solo puede haber un Beneficiario Efectivo por control',
  },
  atleastOneBeneficialOwnerControl: {
    en: 'At least one owner has to be marked as Beneficial Owner by control',
    es: 'Al menos un propietario debe estar marcado como beneficiario final por control',
  },
  atleastPrimaryBorrower: {
    en: 'At least one borrower has to be marked as primary',
    es: 'Al menos un prestatario debe estar marcado como principal',
  },
  onlyOnePrimaryBorrower: {
    en: 'There can only be one primary borrower',
    es: 'Solo puede haber un prestatario principal',
  },
  businessValidationErrors: {
    en: 'Business Validation Errors',
    es: 'Errores de validación comercial',
  },
  sbaID: {
    en: 'SBA ID',
    es: 'SBA ID',
  },
  agentName: {
    en: 'Agent Name',
    es: 'Nombre del agente',
  },
  loanType: {
    en: 'Loan Type',
    es: 'Tipo de préstamo',
  },
  pendingLoans: {
    en: 'Pending Loans',
    es: 'Préstamos Pendientes',
  },
  pendingRequests: {
    en: 'Pending Requests',
    es: 'Solicitudes Pendientes',
  },
  viewRequests: {
    en: 'View Requests',
    es: 'Ver solicitudes',
  },
  messages: {
    en: 'Messages',
    es: 'Mensajes',
  },
  allMessages: {
    en: 'All Messages',
    es: 'Todos los mensajes',
  },
  read: {
    en: 'Read',
    es: 'Leer',
  },
  taxId: {
    en: 'Tax ID',
    es: 'Identificación del impuesto',
  },
  taxForms: {
    en: 'Tax Forms',
    es: 'Formularios de impuesto',
  },
  address: {
    en: 'Address',
    es: 'Habla a',
  },
  applyForNewLoan: {
    en: 'Apply For New Loan',
    es: 'Solicitar nuevo préstamo',
  },
  currentLoans: {
    en: 'Current Loans',
    es: 'Préstamos Actuales',
  },
  currentLoansPaid: {
    en: 'Current Loans Paid',
    es: 'Préstamos actuales pagados',
  },
  viewAll: {
    en: 'View All',
    es: 'Ver todo',
  },
  viewStatements: {
    en: 'View Statements & Payment History',
    es: 'Ver estados de cuenta e historial de pagos',
  },
  viewStatement: {
    en: 'View Statements',
    es: 'Ver estados de cuenta',
  },
  viewPayments: {
    en: 'View Payments',
    es: 'Ver pagos',
  },
  nextPaymentDue: {
    en: 'Next Payment Due',
    es: 'Próximo pago adeudado',
  },
  loanNumber: {
    en: 'Loan Number',
    es: 'Número de préstamo',
  },
  outstandingBalance: {
    en: 'Outstanding Balance',
    es: 'Saldo pendiente',
  },
  payments: {
    en: 'Payments',
    es: 'Pagos',
  },
  viewSummary: {
    en: 'View Summary',
    es: 'Ver resumen',
  },
  makePayment: {
    en: 'Make Payment',
    es: 'Hacer el pago',
  },
  makeAPayment: {
    en: 'Make a Payment',
    es: 'Hacer el pago',
  },
  applicationGuide: {
    en: 'Application Guide',
    es: 'Guia de aplicación',
  },
  accountSummary: {
    en: 'Account Summary',
    es: 'Resumen de la cuenta',
  },
  totalLoansFromSBA: {
    en: 'Total Loans from SBA',
    es: 'Préstamos totales de la SBA',
  },
  overDuePayment: {
    en: 'Overdue Payment',
    es: 'Pago vencido',
  },
  paymentPastDue: {
    en: 'Payment Past Due',
    es: 'Pago atrasado',
  },
  month: {
    en: 'Month',
    es: 'Mes',
  },
  payoffAmount: {
    en: 'Payoff Amount',
    es: 'Monto de pago',
  },
  dueDate: {
    en: 'Appointment Date',
    es: 'Fecha de vencimiento',
  },
  amountDue: {
    en: 'Amount Due',
    es: 'Importe adeudado',
  },
  allStatements: {
    en: 'All Statements',
    es: 'Todas las declaraciones',
  },
  jan_month: {
    en: 'Jan',
    es: 'Ene',
  },
  feb_month: {
    en: 'Feb',
    es: 'Feb',
  },
  mar_month: {
    en: 'Mar',
    es: 'Mar',
  },
  apr_month: {
    en: 'Apr',
    es: 'Abr',
  },
  may_month: {
    en: 'May',
    es: 'May',
  },
  jun_month: {
    en: 'Jun',
    es: 'Jun',
  },
  jul_month: {
    en: 'Jul',
    es: 'Jul',
  },
  aug_month: {
    en: 'Aug',
    es: 'Ago',
  },
  sep_month: {
    en: 'Sep',
    es: 'Sep',
  },
  oct_month: {
    en: 'Oct',
    es: 'Oct',
  },
  nov_month: {
    en: 'Nov',
    es: 'Nov',
  },
  dec_month: {
    en: 'Dec',
    es: 'Dic',
  },
  yourLoans: {
    en: 'Your Loans',
    es: 'Tus Préstamos',
  },
  loanInformation: {
    en: 'Loan Information',
    es: 'Información del préstamo',
  },
  borrowerInformation: {
    en: 'Borrower Information',
    es: 'Información del prestatario',
  },
  moreInfoAbout: {
    en: 'More info about',
    es: 'Más información sobre',
  },
  closeSummary: {
    en: 'Close Summary',
    es: 'Cerrar resumen',
  },
  openSummary: {
    en: 'Open Summary',
    es: 'Resumen abierto',
  },
  borrowerName: {
    en: 'Borrower Name',
    es: 'Nombre del prestatario',
  },
  amount: {
    en: 'Amount',
    es: 'Cantidad',
  },
  borrowerAddress: {
    en: 'Borrower Address',
    es: 'Dirección del prestatario',
  },
  interestAmount: {
    en: 'Interest Amount',
    es: 'Cantidad de interés',
  },
  principalAmount: {
    en: 'Principal Amount',
    es: 'Cantidad principal',
  },
  appliedToInterest: {
    en: 'Applied to Interest',
    es: 'Aplicado al Interés',
  },
  appliedToPrincipal: {
    en: 'Applied to Principal',
    es: 'Aplicada a la directora',
  },
  selectYear: {
    en: 'Select Year',
    es: 'Seleccionar año',
  },
  currentPrincipalAmount: {
    en: 'Current Principal Amount',
    es: 'Monto de capital actual',
  },
  noticeDate: {
    en: 'Notice Date',
    es: 'Fecha de notificacion',
  },
  payoffDate: {
    en: 'Payoff Date',
    es: 'Fecha de pago',
  },
  statementDate: {
    en: 'Statement Date',
    es: 'Fecha de declaración',
  },
  loanMaturityDate: {
    en: 'Loan Maturity Date',
    es: 'Fecha de vencimiento del préstamo',
  },
  fromDueDate: {
    en: 'From Due Date',
    es: 'Desde la fecha de vencimiento',
  },
  reportDueDate: {
    en: 'Report Due Date',
    es: 'Fecha de vencimiento del informe',
  },
  lastPaymentDate: {
    en: 'Last Payment Date',
    es: 'Última Fecha de Pago',
  },
  lastPaymentAmount: {
    en: 'Last Payment Amount',
    es: 'Importe del último pago',
  },
  approvedAmount: {
    en: 'Approved Amount',
    es: 'Monto aprobado',
  },
  businessDescription: {
    en: 'Business Description',
    es: 'Descripción del negocio',
  },
  paymentAmount: {
    en: 'Payment Amount',
    es: 'Monto del pago',
  },
  paidAmount: {
    en: 'Paid Amount',
    es: 'Monto de pago',
  },
  paymentDueDate: {
    en: 'Payment Due Date',
    es: 'Fecha de vencimiento del pago',
  },
  loansAndMessages: {
    en: 'Loans and Messages',
    es: 'Préstamos y Mensajes',
  },
  loans: {
    en: 'Loans',
    es: 'Préstamos',
  },
  dashboardProfileMessage: {
    en: "It's important our loan representative can reach you. Ensure your profile is up to date.",
    es: 'Es importante que nuestro representante de préstamos pueda comunicarse con usted. Asegúrese de que su perfil esté actualizado.',
  },
  documents: {
    en: 'Documents',
    es: 'Documentos',
  },
  briefcase: {
    en: 'Briefcase',
    es: 'Maletín',
  },
  notifications: {
    en: 'Notifications',
    es: 'Notificaciones',
  },
  notices: {
    en: 'Notices',
    es: 'avisos',
  },
  username: {
    en: 'Username',
    es: 'Nombre de usuario',
  },
  replyToMessage: {
    en: 'Reply To Message',
    es: 'responder al mensaje',
  },
  estimatedCost: {
    en: 'Estimated Loss',
    es: 'Pérdida estimada',
  },
  insuranceCoverage: {
    en: 'Insurance Coverage',
    es: 'Cobertura del seguro',
  },
  disclosureConfirmation: {
    en: 'I have read and understand the disclosures above',
    es: 'He leído y entiendo las divulgaciones anteriores',
  },
  menu: {
    en: 'Menu',
    es: 'Menú',
  },
  requiredActions: {
    en: 'Required Actions',
    es: 'Acciones requeridas',
  },
  sentMessages: {
    en: 'Sent Messages',
    es: 'Mensajes enviados',
  },
  DLAPHomeLoan: {
    en: 'Disaster Home Loan',
    es: 'Préstamo hipotecario por desastre',
  },
  DLAPBusinessLoan: {
    en: 'Disaster Business Loan',
    es: 'Préstamo comercial por desastre',
  },
  damageAmount: {
    en: 'Estimated Damage Amount',
    es: 'Cantidad estimada de daño',
  },
  hasInsurance: {
    en: 'Is the property insured?',
    es: '¿Está asegurada la propiedad?',
  },
  industryCode: {
    en: 'Industry Code',
    es: 'Código de la industria',
  },
  messageNumber: {
    en: 'Message #',
    es: 'Mensajes # ',
  },
  initiatedByYou: {
    en: 'Initiated by You.',
    es: 'Iniciado por usted.',
  },
  incomplete: {
    en: 'Incomplete',
    es: 'Incompleta',
  },
  verified: {
    en: 'Verified',
    es: 'Verificada',
  },
  pending: {
    en: 'Pending',
    es: 'Pendiente',
  },
  errorInAddressRequest: {
    en: 'Error! There was an error processing the address request.',
    es: 'Error! Hubo un error al procesar la solicitud de dirección.',
  },
  learnMoreAboutAvailableLoans: {
    en: 'Apply below to see what loan options are available.',
    es: 'Solicite a continuación para ver qué opciones de préstamo están disponibles.',
  },
  editProfile: {
    en: 'Edit Profile',
    es: 'Editar perfil',
  },
  viewMessages: {
    en: 'View Messages',
    es: 'Ver mensajes',
  },
  viewLoans: {
    en: 'View Loans',
    es: 'Ver préstamos',
  },
  viewAllLoans: {
    en: 'View All Loans',
    es: 'Ver todos los préstamos',
  },
  viewApplication: {
    en: 'View Application',
    es: 'Ver solicitud',
  },
  viewNewApplication: {
    en: 'View New Application',
    es: 'Ver nueva aplicación',
  },
  file: {
    en: 'File',
    es: 'Archivo',
  },
  uploadFile: {
    en: 'Upload File',
    es: 'Subir archivo',
  },
  saveProgress: {
    en: 'Save Progress',
    es: 'Guardar progreso',
  },
  done: {
    en: 'Done',
    es: 'Hecho',
  },
  borrowerAddSuccess: {
    en: 'You have successfully added',
    es: 'Ha agregado con éxito',
  },
  borrowerAddOwner: {
    en: 'Add An Owner',
    es: 'Agregar una dueña',
  },
  borrowerAddRelation: {
    en: 'Add A Relation',
    es: 'Agregar una relación',
  },
  borrowerAddAnotherOwner: {
    en: 'Add Another Owner',
    es: 'Agregar otro propietario',
  },
  borrowerAddAnotherRelation: {
    en: 'Add Another Relation',
    es: 'Agregar otra relación',
  },
  borrowerAddBorrower: {
    en: 'Add Another Borrower',
    es: 'Agregar otro prestatario',
  },
  addOwnerSubText: {
    en: 'Enter all the available details an entity or individual owner who has a stake of your listed business.',
    es: 'Ingrese todos los detalles disponibles de una entidad o propietario individual que tenga una participación en su negocio listado.',
  },
  addBorrowerSubText: {
    en: 'Enter all the available details for an additional borrower on this loan.',
    es: 'Ingrese todos los detalles disponibles para un prestatario adicional en este préstamo.',
  },
  borrowerAddDone: {
    en: 'Done Adding Business Details?',
    es: '¿Terminó de agregar detalles comerciales?',
  },
  doneSubText: {
    en: "If you have finished entering owner and borrower information, you're done and can move to the next step.",
    es: 'Si ha terminado de ingresar la información del propietario y del prestatario, ha terminado y puede pasar al siguiente paso.',
  },
  addPrimaryBorrowerSuccess: {
    en: 'as the Primary Borrower',
    es: 'como el prestatario principal',
  },
  addBorrowerSuccess: {
    en: 'as a Borrower',
    es: 'como Prestataria',
  },
  as: {
    en: 'as',
    es: 'como',
  },
  addOwnerSuccess: {
    en: 'owner of',
    es: 'dueña de',
  },
  nextStep: {
    en: 'Next Step',
    es: 'Próximo paso',
  },
  nextSteps: {
    en: 'Next Steps',
    es: 'Próximos pasos',
  },
  atleastOneOwner: {
    en: 'At least one owner required.',
    es: 'Se requiere al menos un propietario.',
  },
  lookupSBAID: {
    en: 'Look Up SBA Number',
    es: 'Buscar número SBA',
  },
  linkSBAAccount: {
    en: 'Link Your SBA Account',
    es: 'Vincule su cuenta SBA',
  },
  lookupSBAIDHelp: {
    en: 'We can help find existing SBA numbers based on your Last Name and SSN.',
    es: 'Podemos ayudarlo a encontrar los números de SBA existentes según su apellido y SSN.',
  },
  noSBAIDResult: {
    en: 'No matching SBA Number found for the given combination of SSN and Last Name',
    es: 'No se encontró un número SBA coincidente para la combinación dada de SSN y apellido',
  },
  searchId: {
    en: 'Search for ID',
    es: 'Buscar ID',
  },
  useId: {
    en: 'Use this ID',
    es: 'Utilice este ID',
  },
  businessAddress: {
    en: 'Business Address',
    es: 'Dirección de Negocios',
  },
  projectAddress: {
    en: 'Project Address',
    es: 'Dirección del proyecto',
  },
  alterAddressCheckBoxLabelExpressLoan: {
    en: 'Is the Project address is the same as the Business address?',
    es: '¿La dirección del proyecto es la misma que la dirección negocios?',
  },
  validationErrors: {
    en: 'Validation Errors',
    es: 'Errores de validación',
  },
  documentSigningEmails: {
    en: 'Document Signing Emails',
    es: 'Correos electrónicos de firma de documentos',
  },
  documentSigningEmailsSubText: {
    en: 'Please verify each email for all the signers so we may prepare your closing documents.',
    es: 'Verifique cada correo electrónico para todos los firmantes para que podamos preparar sus documentos de cierre.',
  },
  sendVerification: {
    en: 'Send Verification',
    es: 'Enviar verificación',
  },
  resendVerification: {
    en: 'Re-send Verification',
    es: 'Reenviar verificación',
  },
  notSent: {
    en: 'Not Sent',
    es: 'No enviado',
  },
  requestedLoanAmount: {
    en: 'Requested Loan Amount',
    es: 'Monto del préstamo solicitado',
  },
  requestedLoanTerm: {
    en: 'Requested Loan Term in Months',
    es: 'Plazo del préstamo solicitado en meses',
  },
  requestedProduct: {
    en: 'Requested Product',
    es: 'Producto solicitado',
  },
  feeChargedorAgreedUpon: {
    en: 'Fee Charged or Agreed Upon',
    es: 'Tarifa cobrada o acordada',
  },
  agentLabel: {
    en: 'Have you taken any assistance from a representative(packager, attorney, accountant etc) in completing this application ? ',
    es: '¿Ha recibido ayuda de un representante (empaquetador, abogado, contador, etc.) para completar esta solicitud?',
  },
  seeAllDeclarations: {
    en: 'See All Declarations for United States and Terriorties',
    es: 'Ver todas las declaraciones de Estados Unidos y territorios',
  },
  sbaDisasterAssistance: {
    en: 'The SBA offers disaster assistance in the form of low interest loans to businesses, nonprofit organizations, homeowners, and renters located in regions affected by declared disasters.',
    es: ' La SBA ofrece asistencia por desastre en forma de bajo interés préstamos a empresas, organizaciones sin fines de lucro, propietarios de viviendas y arrendatarios ubicados en regiones afectadas por desastres declarados.',
  },
  disasterDetailsLabel: {
    en: 'Once we know a few details about you, we can find the correct disaster declartion',
    es: 'Una vez que sepamos algunos detalles sobre usted, podremos encontrar la declaración de desastre correcta',
  },
  disastersByZipcodeLabel: {
    en: 'We found these declarations that are in your area that apply to your assistance needs. Please select the disaster that affected you and continue to apply for a loan',
    es: ' Encontramos estas declaraciones que están en su área que se aplican a sus necesidades de asistencia. Seleccione el desastre que afectó usted y continuar solicitando un préstamo',
  },
  affectedPropertyZipLabel: {
    en: 'Zip Code of Affected Property',
    es: 'Código postal de la propiedad afectada',
  },
  searchDisastersByZip: {
    en: 'Search declarations by Zip Code',
    es: 'Buscar declaraciones por código postal',
  },
  disasterLossType: {
    en: 'What Type of Loss Have You Incurred?',
    es: ' ¿Qué tipo de pérdida ha ocurrido?',
  },
  viewAllDisasters: {
    en: 'View All Declarations',
    es: 'Ver todas las declaraciones',
  },
  findYourDisasters: {
    en: 'Find Your Disaster Location',
    es: 'Encuentre la ubicación de su desastre',
  },
  findDisastersLabel: {
    en: 'Find Disaster Declarations to Apply for Assistance',
    es: 'Encuentre declaraciones de desastre para solicitar asistencia',
  },
  useCurrentLocation: {
    en: 'Use My Current Location',
    es: 'Usar mi ubicación actual',
  },
  disasterLoanApplierType: {
    en: 'What are you applying as?',
    es: '¿A qué te postulas?',
  },
  county: {
    en: 'County',
    es: 'Condado',
  },
  copy_mailing_address: {
    en: 'Copy from Mailing Address',
    es: 'Copiar de la dirección postal',
  },
  copy_permanent_address: {
    en: 'Copy from Permanent Address',
    es: 'Copia de dirección permanente',
  },
  title: {
    en: 'Title',
    es: 'Título',
  },
  legal_name_suffix: {
    en: 'Legal Name Suffix',
    es: 'Sufijo del nombre legal',
  },
  home_phone: {
    en: 'Home Phone',
    es: 'Teléfono de casa',
  },
  cell_phone: {
    en: 'Cell Phone',
    es: 'Teléfono móvil',
  },
  preferred_contact_method: {
    en: 'Preferred Contact Method',
    es: 'Método de Contacto Preferido',
  },
  property_type: {
    en: 'Property Type',
    es: 'Tipo de Propiedad',
  },
  mailing_address_not_found: {
    en: 'Mailing Address not found!',
    es: 'Dirección postal no encontrada!',
  },
  damage_property_address: {
    en: 'Damage Property Address',
    es: 'Dirección de la propiedad dañada',
  },
  selectedDisaster: {
    en: 'The declaration you are applying is for',
    es: 'La declaración que está solicitando es para',
  },
  selectedDisasterValWarning: {
    en: 'The declaration you have selected does not apply to the county of your selected Damaged Property Address.',
    es: 'La declaración que ha seleccionado no se aplica al condado de su dirección de propiedad dañada seleccionada.',
  },
  selectDisaster: {
    en: 'Select a Disaster Declaration To Apply For',
    es: 'Seleccione una declaración de desastre para solicitar',
  },
  femaRegistrationNumber: {
    en: 'FEMA Registration Number (if known)',
    es: 'Número de registro de FEMA (si se conoce)',
  },
  viewDocuments: {
    en: 'View Documents',
    es: 'Ver documentos',
  },
  primary_counties: {
    en: 'Primary Counties',
    es: 'Condados Primarios',
  },
  contiguous_counties: {
    en: 'Contiguous Counties',
    es: 'Condados Contiguos',
  },
  stateAffected: {
    en: 'States Affected',
    es: 'Estados afectados',
  },
  incidentStartDate: {
    en: 'Incident Begin Date',
    es: 'Fecha de inicio del incidente',
  },
  incidentEndDate: {
    en: 'Incident End Date',
    es: 'Fecha de finalización del incidente',
  },
  physicalDamageDeadline: {
    en: 'Physical Damage Deadline',
    es: 'Plazo de daño físico',
  },
  eidlDamageDeadline: {
    en: 'EIDL Damage Deadline',
    es: 'Plazo de daños EIDL',
  },
  maritalStatus: {
    en: 'Marital Status',
    es: 'Estado civil',
  },
  married: {
    en: 'Married',
    es: 'Casada/Casado',
  },
  single: {
    en: 'Not Married',
    es: 'No Casada/Casado',
  },
  non_us_person: {
    en: 'Are you a U.S. Citizen?',
    es: 'Eres ciudadana/ciudadano de los Estados Unidos?',
  },
  disaster: {
    en: 'Disaster',
    es: 'Desastre',
  },
  address_type: {
    en: 'Address Type',
    es: 'Tipo de dirección',
  },
  addressLabel: {
    en: 'Address',
    es: 'Dirección',
  },
  businessPropertyIs: {
    en: 'Business Property is:',
    es: 'La propiedad comercial es:',
  },
  owned: {
    en: 'Owned',
    es: 'propiedad',
  },
  leased: {
    en: 'Leased',
    es: 'Arrendada/Arrendado',
  },
  manageProgressInspection: {
    en: 'Manage Progress Inspection',
    es: 'Administrar inspección de progreso',
  },
  progressInspections: {
    en: 'Progress Inspections',
    es: 'Inspección de progreso',
  },
  newProgressInspections: {
    en: 'New Progress Inspections',
    es: 'Inspecciones de Nuevo Progreso',
  },
  inspectionNumber: {
    en: 'Inspection #',
    es: 'Inspección #',
  },
  createdDate: {
    en: 'Created Date',
    es: 'Fecha de creación',
  },
  attachedDocuments: {
    en: 'Attached Documents',
    es: 'Documentos adjuntos',
  },
  totalAmount: {
    en: 'Total Amount',
    es: 'Cantidad total',
  },
  fileName: {
    en: 'File Name',
    es: 'Nombre del archivo',
  },
  fileSize: {
    en: 'File Size',
    es: 'Tamaño del archivo',
  },
  uploadDate: {
    en: 'Upload Date',
    es: 'Fecha de carga',
  },
  addNewInspection: {
    en: 'Add New Inspection',
    es: 'Agregar nueva inspección',
  },
  serviceRequests: {
    en: 'Service Requests',
    es: 'Solicitudes de servicio',
  },
  startNewRequest: {
    en: 'Start a New Request',
    es: 'Iniciar una Nueva Solicitud',
  },
  requestNumber: {
    en: 'Request Number',
    es: 'Número de Solicitud',
  },
  requestDate: {
    en: 'Request Date',
    es: 'Fecha de solicitud',
  },
  viewRequest: {
    en: 'View Request',
    es: 'Ver solicitud',
  },
  cancelRequest: {
    en: 'Cancel Request',
    es: 'Cancelar petición',
  },
  changeBusinessEntity1: {
    en: 'You have specified business entity type as Sole Proprietorship. If this is not correct, please click ',
    es: 'Ha especificado el tipo de entidad comercial como Empresa unipersonal. Si esto no es correcto, haga clic ',
  },
  changeBusinessEntity2: {
    en: 'here',
    es: 'aquí',
  },
  changeBusinessEntity3: {
    en: ' to change your selection.',
    es: ' para cambiar su selección.',
  },
  tertiaryAddressToolTip: {
    en: "This is the physical address where loan proceeds will be used, or where equipment or property subject to this application request will be located.  Enter an address if it is different from the borrower's business address",
    es: 'Esta es la dirección física donde se utilizarán los fondos del préstamo, o donde se ubicarán los equipos o bienes sujetos a esta solicitud. Ingrese una dirección si es diferente de la dirección comercial del prestatario',
  },
  viewFile: {
    en: 'View File',
    es: 'Ver archivo',
  },
  incidentPeriod: {
    en: 'Incident Period',
    es: 'Período del incidente',
  },
  continuing: {
    en: 'Continuing',
    es: 'Continua',
  },

  totalReceiptsAdded: {
    en: 'Total Receipts Added',
    es: 'Total de recibos agregados',
  },
  addNewExpense: {
    en: 'Add New Expense',
    es: 'Agregar nuevo gasto',
  },
  reevaluationRequest: {
    en: 'Request Reevaluation',
    es: 'Solicitar Reevaluación',
  },
  submitReevaluation: {
    en: 'Submit Reevaluation',
    es: 'Enviar reevaluación',
  },
  reason: {
    en: 'Reason',
    es: 'Razón',
  },
  reevaluationInitiated: {
    en: 'Reevaluation Initiated!',
    es: 'Reevaluación iniciada',
  },
  reevaluationInitiatedSuccess: {
    en: 'Your Loan Reevaluation is initiated succesfully!',
    es: 'Su reevaluación de préstamo se inicia con éxito',
  },
  lossverificationStep1Question: {
    en: 'Do you agree to participate in a self-assessment of damage to your property?',
    es: '¿Está de acuerdo en participar en una autoevaluación de los daños a su propiedad?',
  },
  lossverificationStep1QuestionRenters: {
    en: 'Are you responsible for damage to the real-estate property you are renting?',
    es: '¿Es usted responsable de los daños a la propiedad inmobiliaria que está alquilando?',
  },
  lossverificationStep1Rule: {
    en: 'Select Yes to continue',
    es: 'Seleccione Sí para continuar',
  },
  lossverificationStep2Question: {
    en: 'Tell us about your property loss?',
    es: '¿Cuéntanos sobre la pérdida de tu propiedad?',
  },
  lossverificationStep2ToolTip1: {
    en: 'Property Type and square footage should indicate all livable space excluding basements.',
    es: 'El tipo de propiedad y los pies cuadrados deben indicar todo el espacio habitable, excepto los sótanos.',
  },
  lossverificationStep2ToolTip2: {
    en: 'Choose Property Type',
    es: 'Elija el tipo de propiedad',
  },
  lossverificationStep2SubQuestion1: {
    en: 'Please select your damagad property type.',
    es: 'Seleccione su tipo de propiedad dañada.',
  },
  lossverificationStep2SubQuestion2: {
    en: 'Approximate Total Living Square Footage',
    es: 'Pies cuadrados totales aproximados',
  },
  single_Story: {
    en: '1 Story',
    es: '1 historia',
  },
  multiLevel: {
    en: 'Multi-Level',
    es: 'Multi nivel',
  },
  multiFamilyHome: {
    en: 'Multi Family Home (MFH)',
    es: 'Casa multifamiliar',
  },
  condominium: {
    en: 'Condominium',
    es: 'condominio',
  },
  lossverificationStep3Question: {
    en: 'What is the level of damage to your property?',
    es: '¿Cuál es el nivel de daño a su propiedad?',
  },
  low: {
    en: 'Low',
    es: 'Baja',
  },
  medium: {
    en: 'Medium',
    es: 'Medio',
  },
  high: {
    en: 'High',
    es: 'Alta',
  },
  destroyed: {
    en: 'Destroyed',
    es: 'Destruida',
  },
  none: {
    en: 'None',
    es: 'ninguna',
  },
  noDamage: {
    en: 'No Damage',
    es: 'Sin daños',
  },
  lowLevelDamage: {
    en: 'Low Level of Damage',
    es: 'Bajo nivel de daño',
  },
  mediumLevelDamage: {
    en: 'Medium Level of Damage',
    es: 'Nivel medio de daño',
  },
  highLevelDamage: {
    en: 'High Level of Damage',
    es: 'Alto nivel de daño',
  },
  destroyedLevelDamage: {
    en: 'Destroyed Level of Damage',
    es: 'Nivel de daño destruido',
  },
  lossverificationStep3Option1: {
    en: 'Up to 25% damage to exterior and/or interior of home, landscape, fencing and/or other structures on property.',
    es: 'Hasta un 25 % de daños en el exterior y/o el interior de la casa, el paisaje, las cercas y/u otras estructuras en la propiedad.',
  },
  lossverificationStep3Option2: {
    en: 'Up to 50% damage to exterior and/or interior of home, landscape, fencing and/or other structures on property.',
    es: 'Hasta un 50% de daño al exterior y/o interior de la casa, paisaje, cercas y/u otras estructuras en la propiedad.',
  },
  lossverificationStep3Option3: {
    en: 'Up to 75% damage to exterior and/or interior of home, landscape, fencing and/or other structures on property.',
    es: 'Hasta un 75 % de daños en el exterior y/o el interior de la casa, el paisaje, las cercas y/u otras estructuras en la propiedad.',
  },
  lossverificationStep3Option4: {
    en: 'Total loss of home which will require complete reconstruction.',
    es: 'Pérdida total de la vivienda que requerirá una reconstrucción completa.',
  },
  lossverificationStep4Question: {
    en: 'Was there flood damage to your property?',
    es: '¿Hubo daños por inundación en su propiedad?',
  },
  lossverificationStep4ToolTip1: {
    en: 'If so, please select the type of flood waters that made contact with or entered either your home or garage',
    es: 'Si es así, seleccione el tipo de agua de la inundación que entró en contacto o entró en su casa o garaje',
  },
  lossverificationStep4ToolTip2: {
    en: 'Choose Type',
    es: 'Elija Tipo',
  },
  coastalFlooding: {
    en: 'Coastal Flooding',
    es: 'Inundación costera',
  },
  riverFlooding: {
    en: 'River Flooding',
    es: 'inundación del río',
  },
  groundwaterFlood: {
    en: 'Groundwater Flood',
    es: 'Inundación de agua subterránea',
  },
  lossverificationStep5Question: {
    en: 'Do you have damage to your Personal Contents?',
    es: 'Cuéntanos sobre tus contenidos personales.',
  },
  lossverificationStep5ToolTip: {
    en: 'Personal Contents include any items that are kept within this property that are not structurally part of it.',
    es: 'Los Contenidos Personales incluyen cualquier elemento que se mantenga dentro de esta propiedad que no sea parte estructural de la misma.',
  },
  lossverificationStep5OptionNone: {
    en: 'No damage to personal content',
    es: 'Sin daños al contenido personal',
  },
  lossverificationStep5Option1: {
    en: 'Up to 25% damage to personal property/contents of home. Personal property is any ordinary household contents such as furniture, appliances, clothing, etc., which is not affixed to the real estate and would normally be taken if you were to move. ',
    es: 'Hasta un 25% de daños a la propiedad personal/contenido de la casa. La propiedad personal es cualquier contenido doméstico ordinario, como muebles, electrodomésticos, ropa, etc., que no está adherido a los bienes inmuebles y que normalmente se tomaría si usted se mudara.',
  },
  lossverificationStep5Option2: {
    en: 'Up to 50% damage to personal property/contents of home. Personal property is any ordinary household content such as furniture, appliances, clothing, etc., which is not affixed to the real estate and would normally be taken if you were to move.',
    es: 'Hasta un 50% de daños a la propiedad personal/contenido de la casa. La propiedad personal es cualquier contenido doméstico ordinario, como muebles, electrodomésticos, ropa, etc., que no está adherido a los bienes inmuebles y que normalmente se tomaría si usted se mudara.',
  },
  lossverificationStep5Option3: {
    en: 'Up to 75% damage to personal property/contents of home. Personal property is any ordinary household content such as furniture, appliances, clothing, etc., which is not affixed to the real estate and would normally be taken if you were to move.',
    es: 'Hasta un 75% de daños a la propiedad personal/contenido del hogar. La propiedad personal es cualquier contenido doméstico ordinario, como muebles, electrodomésticos, ropa, etc., que no está adherido a los bienes inmuebles y que normalmente se tomaría si usted se mudara.',
  },
  lossverificationStep5Option4: {
    en: '100% damage to personal property/contents of home. Personal property is any ordinary household content such as furniture, appliances, clothing, etc., which is not affixed to the real estate and would normally be taken if you were to move.',
    es: '100% daños a la propiedad personal/contenido de la casa. La propiedad personal es cualquier contenido doméstico ordinario, como muebles, electrodomésticos, ropa, etc., que no está adherido a los bienes inmuebles y que normalmente se tomaría si usted se mudara.',
  },
  lossverificationStep6Question: {
    en: 'Tell us about damage to your automobiles.',
    es: 'Cuéntenos sobre los daños a sus automóviles.',
  },
  lossverificationStep6SubQuestion1: {
    en: 'Do you have damage to an automobile?',
    es: '¿Tiene daños en un automóvil?',
  },
  lossverificationStep6SubQuestion2: {
    en: 'How many automobiles were damage?',
    es: '¿Cuántos automóviles resultaron dañados?',
  },
  lossVerificationCloseMessage: {
    en: 'Your data will not be saved if you exit without completing the assesment. Are you sure you want to exit?',
    es: 'Sus datos no se guardarán si sale sin completar la evaluación. ¿Seguro que quieres salir?',
  },
  year: {
    en: 'Year',
    es: 'Año',
  },
  make: {
    en: 'Make',
    es: 'Hacer',
  },
  model: {
    en: 'Model',
    es: 'Modelo',
  },
  vinNumber: {
    en: 'Enter the VIN Number',
    es: 'Número VIN',
  },
  lossverificationVINquestion: {
    en: 'Do you have the VIN number?',
    es: '¿Tienes el número VIN?',
  },
  vinNumberUnavailable: {
    en: 'I do not have a VIN number',
    es: 'No tengo un número de VIN',
  },
  lossverificationStepCustomQuestion: {
    en: 'Would the damages to your automobile require a repair or replacement?',
    es: '¿Los daños a su automóvil requerirían una reparación o reemplazo?',
  },
  repair: {
    en: 'Repair',
    es: 'Reparar',
  },
  replacement: {
    en: 'Replacement',
    es: 'Reemplazo',
  },
  property: {
    en: 'Property',
    es: 'Propiedad',
  },
  completeLater: {
    en: 'Complete Later',
    es: 'Completar más tarde',
  },
  continue: {
    en: 'Continue',
    es: 'Continuar',
  },
  lossverificationStep7Question1: {
    en: 'Please select your damage property type.',
    es: 'Seleccione su tipo de propiedad dañada.',
  },
  lossverificationStep7Question2: {
    en: 'What is the approximate Total Square Footage of your damaged property?',
    es: '¿Cuál es el total aproximado de pies cuadrados de su propiedad dañada?',
  },
  lossverificationStep7Question3: {
    en: 'Do you consider property as "Totally Destroyed"',
    es: '¿Considera la propiedad como "Totalmente destruida"',
  },
  lossverificationStep7Question4: {
    en: 'Please select the level of damage to the property.',
    es: 'Seleccione el nivel de daño a la propiedad.',
  },
  lossverificationStep7Question5: {
    en: 'Do you have damage to your personal contents',
    es: '¿Tienes daños en tus contenidos personales?',
  },
  lossverificationStep7Question5_1: {
    en: 'Please select the level of damage to your personal contents.',
    es: 'Seleccione el nivel de daño a sus contenidos personales.',
  },
  lossverificationStep7Question6: {
    en: 'Do you have damage to automobile?',
    es: '¿Tiene daños en el automóvil?',
  },
  lossverificationStep7Question7: {
    en: 'What is the level of damage to automobile',
    es: '¿Tiene daños en el automóvil?',
  },
  lossverificationStep7Question9: {
    en: 'If applicable please select the type of flood waters that made contact with  or entered either your home or garage.',
    es: 'Si corresponde, seleccione el tipo de agua de la inundación que entró en contacto o entró en su casa o garaje.',
  },
  lossverificationSubmittedMsg: {
    en: 'Your loss verification assessment has been submitted!',
    es: '¡Se ha enviado su evaluación de verificación de pérdida!',
  },
  for_loss_at: {
    en: 'For loss at',
    es: 'por pérdida en',
  },
  countiesDoNotMatch: {
    en: 'Counties do not match',
    es: 'Los condados no coinciden',
  },
  declarationOptions: {
    en: 'Declaration Options',
    es: 'Opciones de declaración',
  },
  receivedDate: {
    en: 'Received Date',
    es: 'Fecha de recepción',
  },
  processedDate: {
    en: 'Processed Date',
    es: 'Fecha de procesamiento',
  },
  paymentChannel: {
    en: 'Payment Channel',
    es: 'Canal de pago',
  },
  transactionType: {
    en: 'Transaction Type',
    es: 'Tipo de transacción',
  },
  balance: {
    en: 'Balance',
    es: 'Balance',
  },
  sbaNumber: {
    en: 'SBA Number',
    es: 'Número SBA',
  },
  risingWaterTooltip: {
    en: 'Water that rises and accumulates above the ground, may remain for an extended period of time and makes contact with either the home, foundation or garage and includes entry into basement or crawlspace areas.',
    es: 'El agua que sube y se acumula por encima del suelo, puede permanecer durante un período de tiempo prolongado y hace contacto con la casa, los cimientos o el garaje e incluye la entrada al sótano o las áreas de sótano.',
  },
  risingWater: {
    en: 'Rising Water',
    es: 'agua creciente',
  },
  sewerTooltip: {
    en: 'The back flow of sanitary or storm sewer drains located within the home, basement, foundation or crawlspace area and may include sump pump drain lines.',
    es: 'El contraflujo de desagües sanitarios o de alcantarillado pluvial ubicados dentro de la casa, el sótano, los cimientos o el espacio angosto y puede incluir líneas de drenaje de bombas de sumidero.',
  },
  sewer: {
    en: 'Sewer',
    es: 'Alcantarillado',
  },
  seepageTooltip: {
    en: 'Water pushed into the home typically below ground level, through exterior basement walls or cracks in the basement floor, and is generally the result of ground saturation.',
    es: 'El agua ingresa a la casa por lo general por debajo del nivel del suelo, a través de las paredes exteriores del sótano o grietas en el piso del sótano, y generalmente es el resultado de la saturación del suelo.',
  },
  seepage: {
    en: 'Seepage',
    es: 'Filtración',
  },
  runoffTooltip: {
    en: 'Water that usually travels through the property at various depths but does not accumulate or remain for an extended duration. Commonly occurs due to the properties geographic terrain and may come from mountain, hillside, road or driveway.',
    es: 'Agua que generalmente viaja a través de la propiedad a varias profundidades pero que no se acumula ni permanece por un período prolongado. Ocurre comúnmente debido a las propiedades del terreno geográfico y puede provenir de una montaña, una ladera, una carretera o un camino de entrada.',
  },
  runoff: {
    en: 'Run-off',
    es: 'Escapada',
  },
  closeMessage: {
    en: 'Close Message',
    es: 'Cerrar mensaje',
  },
  viewCounterOffers: {
    en: 'View Counter Offers',
    es: 'Ver ofertas de mostrador',
  },
  interestRate: {
    en: 'Interest Rate',
    es: 'Tasa de interés',
  },
  loanTerm: {
    en: 'Loan Term',
    es: 'plazo del préstamo',
  },
  secondaryAlterAddressCheckBoxLabel: {
    en: 'Check if the alternate address is the same as the permanent address',
    es: 'Compruebe si la dirección postal es la misma que la dirección física',
  },
  mailingAddressCheckboxLabel: {
    en: 'Check if this address should be the primary mailing address',
    es: 'Compruebe si la dirección alternativa debe ser la dirección postal principal',
  },
  permanentAddressLabel: {
    en: 'Permanent Address',
    es: 'Dirección permanente',
  },
  demographicsLabel: {
    en: 'Demographic Information',
    es: 'Información demográfica',
  },
  demographicsSubtext: {
    en: 'Veteran/Gender/Race/Ethnicity data is collected for program reporting purposes only.  Disclosure is voluntary and has no bearing on the credit decision.',
    es: 'Los datos de Veterano/Género/La raza/Etnicidad se recopilan solo con fines de informes del programa.  La divulgación es voluntaria y no influye en la decisión crediticia.',
  },
  veteranLabel: {
    en: 'Veteran',
    es: 'Veterano',
  },
  genderLabel: {
    en: 'Gender',
    es: 'Género',
  },
  raceLabel: {
    en: 'Race (more than 1 can be selected)',
    es: 'La raza (se puede seleccionar mas de 1)',
  },
  ethnicityLabel: {
    en: 'Ethnicity',
    es: 'Etnicidad',
  },
  male: {
    en: 'Male',
    es: 'Masculino',
  },
  female: {
    en: 'Female',
    es: 'Femenina',
  },
  notDisclosed: {
    en: 'Not Disclosed',
    es: 'No divulgado',
  },
  paymentInformation: {
    en: 'Payment Information',
    es: 'Información del pago',
  },
  statements: {
    en: 'Statements',
    es: 'Declaraciones',
  },
  loanStatus: {
    en: 'Loan Status',
    es: 'Estado del préstamo',
  },
  statementAmount: {
    en: 'Statement Amount',
    es: 'Importe del estado de cuenta',
  },
  paymentDue: {
    en: 'Payment Due',
    es: 'Fecha de pago',
  },
  oustandingBalance: {
    en: 'Oustanding Balance',
    es: 'Saldo pendiente',
  },
  confirmationId: {
    en: 'Confirmation ID',
    es: 'Identificación de confirmación',
  },
  reoccuringPayment: {
    en: 'Reoccuring Payment',
    es: 'Pago Recurrente',
  },
  manageRecurringPayments: {
    en: 'Manage Recurring Payments',
    es: 'Administrar Pagos Recurrentes',
  },
  paymentMethod: {
    en: 'Payment Method',
    es: 'Método de pago',
  },
  routingNumber: {
    en: 'Routing Number',
    es: 'Número de ruta',
  },
  accountNumber: {
    en: 'Account Number',
    es: 'Número de cuenta',
  },
  paymentDate: {
    en: 'Payment Date',
    es: 'Fecha de pago',
  },
  confirmationIdTooltip: {
    en: 'Confirmation ID Tooltip',
    es: 'Confirmation ID Tooltip',
  },
  paymentStatusTooltip: {
    en: 'Payment Status Tooltip',
    es: 'Payment Status Tooltip',
  },
  businessTypeDescription: {
    en: 'Business Type Description',
    es: 'Tipo de negocio Descripción',
  },
  businessType: {
    en: 'Business Type',
    es: 'Tipo de negocio',
  },
  businessPhone: {
    en: 'Business Phone',
    es: 'Teléfono de negocios',
  },
  currentApprovedLoanAmount: {
    en: 'Current Approved Loan Amount',
    es: 'Monto actual del préstamo aprobado',
  },
  totalUndisbursedAmount: {
    en: 'Total Undisbursed Amount',
    es: 'Monto total no desembolsado',
  },
  initialApprovedAmount: {
    en: 'Initial Approved Amount',
    es: 'Monto inicial aprobado',
  },
  accountPastDueIndicator: {
    en: 'Account Past Due Indicator',
    es: 'Indicador de cuenta vencida',
  },
  accountPastDueSince: {
    en: 'Account Past Due Since',
    es: 'Cuenta vencida desde',
  },
  paperlessStatements: {
    en: 'Paperless Statements',
    es: 'Estados de cuenta sin papel',
  },
  loanPaymentAmount: {
    en: 'Loan Payment Amount',
    es: 'Importe del pago del préstamo',
  },
  connectAccount: {
    en: 'Connect Account',
    es: 'Conectar cuenta',
  },
  searchForAccount: {
    en: 'Search For Account',
    es: 'Buscar cuenta',
  },
  yourProfileIsIncomplete: {
    en: 'Your profile is incomplete',
    es: 'Tu perfil está incompleto',
  },
  connectSBANumber: {
    en: 'Connect SBA Number',
    es: 'Conectar número Sba',
  },
  loanSummary: {
    en: 'Loan Summary',
    es: 'todo',
  },
  paymentHistory: {
    en: 'Payment History',
    es: 'Historial de Pagos',
  },
  paymentActivity: {
    en: 'Payment Activity',
    es: 'Actividad de pago',
  },
  recurringPayments: {
    en: 'Recurring Payments',
    es: 'Pagos Recurrentes',
  },
  payment: {
    en: 'payment',
    es: 'pago',
  },
  paymentSchedule: {
    en: 'Payment Schedule',
    es: 'Calendario de Pago',
  },
  billingAddress: {
    en: 'Billing Address',
    es: 'Dirección de Envio',
  },
  numberOfInstallments: {
    en: 'Number of Installments',
    es: 'Número de Plazos',
  },
  type: {
    en: 'Type',
    es: 'Escribe',
  },
  related_to: {
    en: 'Related to',
    es: 'Relacionado con',
  },
  service_id: {
    en: 'Service Id',
    es: 'identificación del servicio',
  },
  date_received: {
    en: 'Date Received',
    es: 'Fecha de recepción',
  },
  openMessages: {
    en: 'Open Messages',
    es: 'Mensajes abiertos',
  },
  closedMessages: {
    en: 'Closed Messages',
    es: 'Mensajes cerrados',
  },
  unread: {
    en: 'Unread',
    es: 'No leído',
  },
  more: {
    en: 'More',
    es: 'Más',
  },
  loanDocuments: {
    en: 'Loan Documents',
    es: 'Documentos de préstamo',
  },
  viewTaxForms: {
    en: 'View Tax Forms',
    es: 'Ver formularios de impuestos',
  },
  manageRecurringPayment: {
    en: 'Manage Recurring Payment',
    es: 'Administrar pagos recurrentes',
  },
  tax1099Forms: {
    en: 'Tax 1099 Forms',
    es: 'Formularios de impuestos 1099',
  },
  download1099: {
    en: 'Download 1099',
    es: 'Descargar 1099',
  },
  cancelPayments: {
    en: 'Cancel Payments',
    es: 'Cancelar pagos',
  },
  schedulePayments: {
    en: 'Schedule Payments',
    es: 'Programar Pagos',
  },
  account: {
    en: 'Account',
    es: 'Cuenta',
  },
  firstPaymentDate: {
    en: 'First Payment Date',
    es: 'Primera fecha de pago',
  },
  frequency: {
    en: 'Frequency',
    es: 'Frecuencia',
  },
  recurringPaymentsNote: {
    en: 'If you wish to change the <b>frequency</b> or <b>number of installments</b> of a recurring payment, please <b>cancel</b> your payment and <b>schedule a new payment</b>.',
    es: 'Si desea cambiar la <b>frecuencia</b> o el <b>número de cuotas</b> de un pago recurrente, <b>cancele</b> su pago y <b>programe un nuevo pago< /b>.',
  },
  noRecurringPaymentsNote: {
    en: 'There is no current <b>payment schedule</b> setup for this account.',
    es: 'No hay una configuración actual de <b>programa de pago</b> para esta cuenta.',
  },
  recurringPayment: {
    en: 'Recurring Payment',
    es: 'Pagos Recurrente',
  },
  cancelRecurringPaymentWarning: {
    en: 'You are about to cancel your current',
    es: 'Está a punto de cancelar su actual',
  },
  recurringPaymentsWillNoLongerBeProcessed: {
    en: 'All future payments for the following loan will no longer be processed',
    es: 'Todos los pagos futuros del siguiente préstamo ya no se procesarán',
  },
  recurringPaymentsCanBeStartedAgain: {
    en: 'You can always start recurring payments again by scheduling a new payment',
    es: 'Siempre puede volver a iniciar pagos recurrentes programando un nuevo pago',
  },
  confirmCancelation: {
    en: 'Confirm Cancelation',
    es: 'Confirmar cancelación',
  },
  cardNumber: {
    en: 'Card Number',
    es: 'Número de tarjeta',
  },
  billingInfo: {
    en: 'Billing Info',
    es: 'Información de facturación',
  },
  paymentDetails: {
    en: 'Payment Details',
    es: 'Detalles del pago',
  },
  paymentConfirmation: {
    en: 'Payment Confirmation',
    es: 'Confirmación de pago',
  },
  due: {
    en: 'Due',
    es: 'adeudado',
  },
  choosePaymentAmount: {
    en: 'Choose Payment Amount',
    es: 'Elija el monto del pago',
  },
  debitCardImageAlt: {
    en: 'Image of Debit Card showing location of card number, expiration date, and CVV',
    es: 'Imagen de la tarjeta de débito que muestra la ubicación del número de tarjeta, la fecha de vencimiento y el CVV',
  },
  checkImageAlt: {
    en: 'Image of Check showing routing number and account number location',
    es: 'Imagen de un cheque que muestra la ubicación del número de ruta y del número de cuenta',
  },
  paypalLogoAlt: {
    en: 'PayPal Logo',
    es: 'Logotipo de Paypal',
  },
  accountHolderName: {
    en: 'Account Holder Name',
    es: 'Nombre del titular de la cuenta',
  },
  accountType: {
    en: 'Account Type',
    es: 'Tipo de cuenta',
  },
  confirmAccountNumber: {
    en: 'Confirm Account Number',
    es: 'Confirmar número de cuenta',
  },
  expiryMonth: {
    en: 'Expiry Month',
    es: 'Meses de vencimiento',
  },
  expiryYear: {
    en: 'Expiry Year',
    es: 'Año de caducidad',
  },
  paypalCompleteText: {
    en: 'You will be required to complete payment at PayPal.com',
    es: 'Se le pedirá que complete el pago en PayPal.com',
  },
  saveAndContinue: {
    en: 'Save and Continue',
    es: 'Guardar y continuar',
  },
  addPaymentDetails: {
    en: 'Add Payment Details',
    es: 'Agregar detalles de pago',
  },
  recurringPaymentText: {
    en: 'Would you like this to be a recurring payment?',
    es: '¿Le gustaría que esto fuera un pago recurrente?',
  },
  oneTimePayment: {
    en: 'One-Time Payment',
    es: 'Pago único',
  },
  paymentFrequency: {
    en: 'Payment Frequency',
    es: 'Frecuencia de pago',
  },
  reviewYourPayment: {
    en: 'Review Your Payment',
    es: 'Revise su pago',
  },
  bankAccount: {
    en: 'Bank Account',
    es: 'Cuenta bancaria',
  },
  expiry: {
    en: 'Expiry',
    es: 'Expiración',
  },
  debitCard: {
    en: 'Debit Card',
    es: 'Tarjeta de débito',
  },
  firstPayment: {
    en: 'First Payment',
    es: 'Primer pago',
  },
  disclosuresAndTerms: {
    en: 'Disclosures and Terms',
    es: 'Divulgaciones y Términos',
  },
  agreeToDisclosureText: {
    en: 'I agree to the MySBA authorization and disclosure statement',
    es: 'Acepto la declaración de divulgación y autorización de MySBA',
  },
  confirmPayment: {
    en: 'Confirm Payment',
    es: 'Confirmar pago',
  },
  navigateToPayDotGov: {
    en: 'You will be navigated to Pay.gov to finish adding your payment information.',
    es: 'Se le dirigirá a Pay.gov para terminar de agregar su información de pago.',
  },
  finishAtPayDotGov: {
    en: 'Finish Payment at Pay.gov',
    es: 'Terminar el pago en Pay.gov',
  },
  communicatingWithPayDotGov: {
    en: 'Communicating with Pay.gov',
    es: 'Comunicarse con Pay.gov',
  },
  redirectedMomentarily: {
    en: 'You will be redirected momentarily...',
    es: 'Será redirigido momentáneamente...',
  },
  yourPaymentFor: {
    en: 'Your payment for ',
    es: 'Su pago por ',
  },
  isNowPending: {
    en: ' is now pending.',
    es: ' ahora está pendiente.',
  },
  paymentHistoryText: {
    en: 'It might take a few days to show within your payment history',
    es: 'Es posible que tarde unos días en mostrarse en su historial de pagos',
  },
  backToStatements: {
    en: 'Back to Statements',
    es: 'Volver a Estados de cuenta',
  },
  hasFailed: {
    en: ' has failed.',
    es: ' ha fallado.',
  },
  error: {
    en: 'Error',
    es: 'Error',
  },
  paymentCommunicationIssue: {
    en: 'Payment system communication issue',
    es: 'Problema de comunicación del sistema de pago',
  },
  tryPaymentAgain: {
    en: 'Try Payment Again',
    es: 'Intente pagar de nuevo',
  },
  navigatingAwayFromPayment: {
    en: 'You are navigating away from your payment',
    es: 'Está navegando fuera de su pago',
  },
  paymentInfoWillBeLost: {
    en: 'Your Payment Information Will Be Lost',
    es: 'Su información de pago se perderá',
  },
  confirmLeavePayment: {
    en: 'Confirm Leaving Payment',
    es: 'Confirmar Pago Saliente',
  },
  continueWithPayment: {
    en: 'Continue With Payment',
    es: 'Continuar con el pago',
  },
  personalChecking: {
    en: 'Personal Checking',
    es: 'Cuenta corriente personal',
  },
  personalSavings: {
    en: 'Personal Savings',
    es: 'Ahorros personales',
  },
  businessChecking: {
    en: 'Business Checking',
    es: 'Cuenta corriente comercial',
  },
  businessSavings: {
    en: 'Business Savings',
    es: 'Ahorros Empresariales',
  },
  accountNumberDoesNotMatch: {
    en: 'Account Number does not match',
    es: 'Número de cuenta no coincide',
  },
  required: {
    en: 'required',
    es: 'requerida',
  },
  termsAgreeErrorMessage: {
    en: 'You must agree to the authorization and disclosure statement to make a payment',
    es: 'Debe aceptar la declaración de autorización y divulgación para realizar un pago',
  },
  invalidCVV: {
    en: 'CVV invalid',
    es: 'CVV inválida',
  },
  inputYY: {
    en: 'Please input as YY',
    es: 'Por favor ingrese como YY',
  },
  inputMM: {
    en: 'Please input as MM',
    es: 'Por favor ingrese como MM',
  },
  cardExpirationError: {
    en: 'Card Expiration Error',
    es: 'Error de caducidad de la tarjeta',
  },
  cardValidationError: {
    en: 'Credit Card validation failed',
    es: 'La validación de la tarjeta de crédito falló',
  },
  accountValidationErrorMessage: {
    en: 'Account number must be between 3 and 17 characters. And can contain only [A-Z, a-z, 0-9, *, - , #, $, (comma) and (space)] characters',
    es: 'El número de cuenta debe tener entre 3 y 17 caracteres. Y solo puede contener caracteres [A-Z, a-z, 0-9, *, - , #, $, (coma) y (espacio)]',
  },
  routingValidationErrorMessage: {
    en: 'Routing Number should be 9 digits',
    es: 'El número de ruta debe tener 9 dígitos',
  },
  paymentMonthsErrorMessage: {
    en: 'Months is required if choosing recurring payment/After Number of Months',
    es: 'Se requieren meses si elige pago recurrente/después del número de meses',
  },
  paymentEndDateErrorMessage: {
    en: 'End Date is required if choosing recurring payment/After End Date',
    es: 'La fecha de finalización es obligatoria si elige un pago recurrente/después de la fecha de finalización',
  },
  minPaymentAmount: {
    en: 'Minimum Payment Amount is',
    es: 'El monto mínimo de pago es',
  },
  maxPaymentAmount: {
    en: 'Maximum Payment Amount is',
    es: 'El monto máximo de pago es',
  },
  greaterThanOne: {
    en: 'Should be greater than 1',
    es: 'Debe ser mayor que 1',
  },
  maxInstallments: {
    en: 'Maximum # of Installments is ',
    es: 'El número máximo de cuotas es ',
  },
  routingInvalidErrorMessage: {
    en: 'Invalid Routing Number',
    es: 'Número de ruta no válido',
  },
  cancellationDate: {
    en: 'Cancellation Date',
    es: 'Fecha de cancelación',
  },
  bankName: {
    en: 'Bank Name',
    es: 'Nombre del banco',
  },
  documentType: {
    en: 'Document Type',
    es: 'Tipo de Documento',
  },
  download: {
    en: 'Download',
    es: 'Descargar',
  },
  oneTime: {
    en: 'One Time',
    es: 'Una Vez',
  },
  recurring: {
    en: 'Recurring',
    es: 'Recurrentes',
  },
  weekly: {
    en: 'Weekly',
    es: 'Semanalmente',
  },
  biWeekly: {
    en: 'Biweekly',
    es: 'Quincenal',
  },
  monthly: {
    en: 'Monthly',
    es: 'Mensual',
  },
  quarterly: {
    en: 'Quarterly',
    es: 'Trimestral',
  },
  expirationDate: {
    en: 'Expiration Date',
    es: 'Fecha de caducidad',
  },
  paymentType: {
    en: 'Payment Type',
    es: 'Tipo de pago',
  },
  verifyIdentity: {
    en: 'Please provide the documents listed below to verify your identity',
    es: 'Proporcione los documentos que se enumeran a continuación para verificar su identidad',
  },
  accountNotVerified: {
    en: 'Your account has not been verified',
    es: 'Tu cuenta no ha sido verificada',
  },
  accountLockText: {
    en: 'Not providing requested documents promptly may impact your ability to login and access MySBA User Accounts.',
    es: 'No proporcionar los documentos solicitados de inmediato puede afectar su capacidad para iniciar sesión y acceder a las cuentas de usuario de MySBA.',
  },
  docVerificationPending: {
    en: 'Verification of the documents you provided is pending.',
    es: 'La verificación de los documentos que proporcionó está pendiente.',
  },
  skipAndContinue: {
    en: 'Skip and Continue',
    es: 'Saltar y continuar',
  },
  accountVerification: {
    en: 'Account Verification',
    es: 'Verificación de la cuenta',
  },
  safeInfo: {
    en: 'The safekeeping of your personal and business information is important to us!',
    es: '¡El cuidado seguro de su información personal y comercial es importante para nosotros!',
  },
  sbaProtectData: {
    en: 'The SBA is dedicated to protecting your data. The steps required to register and sign in are necessary to ensure your information will stay safe with us.',
    es: 'La SBA se dedica a proteger sus datos. Los pasos requeridos para registrarse e iniciar sesión son necesarios para garantizar que su información permanezca segura con nosotros.',
  },
  accountNeedstoBeVerified: {
    en: 'Your Account Must Be Verified',
    es: 'Su cuenta debe ser verificada',
  },
  noRequiredActions: {
    en: 'There no required actions for your account',
    es: 'No hay acciones requeridas para su cuenta',
  },
  signOut: {
    en: 'Sign Out',
    es: 'Cerrar sesión',
  },
};

const messages = {};
langs.forEach((lang) => {
  messages[lang] = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(literals)) {
    if (value[lang] && value[lang] !== '') messages[lang][key] = value[lang];
  }
});
export default messages;

import Vue from 'vue';
import VueRouter from 'vue-router';
import DocumentsPage from '../vue-components/pages/DocumentsPage.vue';
import EddStartingPage from '../vue-components/pages/EddStartingPage.vue';
import EddLandingPage from '../vue-components/pages/EddLandingPage.vue';
import EddSchedulingPage from '../vue-components/pages/EddSchedulingPage.vue';
import EddMeetingPage from '../vue-components/pages/EddMeetingPage.vue';
import EddCompletePage from '../vue-components/pages/EddCompletePage.vue';
import VerificationPage from '../vue-components/pages/VerificationPage.vue'

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    { path: '/', component: EddStartingPage, name: 'EddStartingPage' },
    { path: '/case/:id', component: EddLandingPage, name: 'EddLandingPage', props: true },
    { path: '/case/:id/schedule', component: EddSchedulingPage, name: 'EddSchedulingPage', props: true },
    { path: '/case/:id/meeting', component: EddMeetingPage, name: 'EddMeetingPage', props: true },
    { path: '/case/:id/complete', component: EddCompletePage, name: 'EddCompletePage', props: true },
    { path: '/case/:id/verification', component: VerificationPage, name: 'VerificationPage', props: true },
  ],
});

export default router;

<template>
     <v-card class="thread-messages overflow-auto p-3 p-md-5 pt-0">
         <div id="inbox-item-los" v-if="firstScreen">
            <ul class="message-thread">
              <li v-for="(message, index) in requestDetails.messages" :key="index" class="rounded p-2 px-3" :class="me === message.sender ? 'my-response' : ''">
                <div class="d-flex justify-content-between gap-3 align-top">
                  <div>
                    <!-- <i class="fa-lg brand-text-primary" :class="index === 0 ? 'far fa-comment-alt' : 'far fa-comments-alt'"></i> -->
                    <i class="fa-regular fa-comments-alt fa-lg brand-text-primary"></i>
                  </div>
                  <div class="flex-grow-1">
                    <div class="d-flex justify-content-between flex-wrap">
                      <span class="brand-text-primary fs-9">
                        {{message.sender}}
                      </span>
                      <div>
                        <i class="fa-regular fa-clock fa-sm"></i>
                        <span class="timestamp">{{message.created | dateTime}}</span>
                      </div>
                    </div>
                    <span class="pt-2 fs-9" v-html="message.message"></span>
                  </div>

                </div>
                <div class="p-4">
                  <div v-for="(attachment, index) in message.attachments" :key="index">
                    <a class="btn btn-primary" href='javascript:void(0)' @click="viewItemInbox(attachment.url)">View Notice</a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
           <v-dialog v-model="closeConfirmMessage" persistent max-width="350">
            <v-card class="confirm-dialog">
              <v-card-title class="justify-content-center"><i class="fal fa-exclamation-circle fa-2x text-warning"></i></v-card-title>
              <v-card-text class="text-center">Your data will not be saved if you exit without completing. Are you sure you want to exit?"</v-card-text>
              <v-card-actions class="justify-content-center gap-3">
                <button text @click="closeConfirmMessage = false" class="btn btn-white"> {{$t('cancel')}} </button>
                <button text @click="confirmLossVerificationClose(requestDetails)" class="btn btn-primary">{{$t('confirm')}}</button>
              </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="isSuccess" persistent max-width="880">
            <v-card class="d-flex flex-column align-items-center p-5 gap-2">
               <v-card-title>
                    <i class="fa-regular fa-check-circle fa-lg text-success"></i>
                </v-card-title>
                 <v-card-text class="text-center">
                  <span>You have successfully accept the counter offer for </span>
                  <p class="brand-text-primary">{{requestDetails.borrower}}</p>
                  <p>A new loan application is has been created with the following terms</p>
                  <v-container class="card-bg-light text-left" v-if="isSuccessSelectedOffer.product">
                    <p class="brand-text-primary data-val" >{{isSuccessSelectedOffer.product.name}}</p>
                    <div class="d-flex flex-row flex-wrap justify-content-start">
                      <div class="info-box col-md-3"  v-if="isSuccessSelectedOffer.loan_amount">
                        <label class="pb-1 mandatory">{{$t('loanAmount')}}</label>
                        <div class="pb-1 brand-text-primary">${{isSuccessSelectedOffer.loan_amount}}</div>
                      </div>
                      <div class="info-box col-md-3" v-if="isSuccessSelectedOffer.loan_tenure">
                        <label class="pb-1 mandatory">{{$t('loanTerm')}}</label>
                        <div class="pb-1 text-primary" >{{isSuccessSelectedOffer.loan_tenure}} Months</div>
                      </div>
                      <div class="info-box col-md-3" v-if="isSuccessSelectedOffer.rate">
                        <label class="pb-1 mandatory">{{$t('interestRate')}}</label>
                        <div class="pb-1 text-primary">{{isSuccessSelectedOffer.rate}}%</div>
                      </div>
                    </div>
                    </v-container>
                    <p class="brand-text-primary mt-3">We need you to provide some additional information before 
                      submitting your new application</p>
                    <div>
                      <button class="btn btn-primary">
                        <span class="fw-semi-bold">Add Information</span>
                      </button>
                    </div>
                    <div>
                      <button class="btn btn-white pt-2"  @click="confirmLossVerificationClose(requestDetails)"><b>Complete Later</b></button>
                    </div>
                </v-card-text>
          </v-card>
        </v-dialog>
          <v-card-text class="px-0 pt-0">
              <counter-offer-stepper v-on="$listeners" @onFirstScreen="onFirstScreen" @closeMessage="closeMessage"
              :counter_offer_details="counterOfferDetail"></counter-offer-stepper>
          </v-card-text>
     </v-card>
</template>

<script>
import CounterOfferStepper from './CounterOfferSteps/CounterOfferStepper.vue';
import FilterMixin from '../mixins/FilterMixin';
export default {
    components: {
        "counter-offer-stepper" : CounterOfferStepper
    },
    mixins: [FilterMixin],
    props: {
        thread: Object,
        requestDetails:Object,
        closeInboxDialog:Object,
        loan_id:String,
        successCallback:Object
    },
   
    data() {
        return {
            sentSuccess: false,
            closeConfirmMessage:false,
            firstScreen:true,
            counterOfferDetail:[],
            isSuccess:false,
            isSuccessSelectedOffer : {}
        }
    },
    methods:{
        onFirstScreen(value){
            this.firstScreen = value;
        },
        viewItemInbox(url) {
            window.open(url, '_blank');
        },
        closeMessage(){
            this.closeConfirmMessage = true;
        },
        confirmLossVerificationClose(item = null) {
            this.closeConfirmMessage = false;
            if (item.id) this.$emit('unLockMessage',item.id);
            this.$emit('closemessage');
        },
        getCounterOffers() {
            const url = '/api/counter-offers';
            const params = {
                loan: this.loan_id,
                status: 'active',
            };
            if (this.requestDetails.is_complete) {
                delete params.status;
                params.inbox_thread = this.requestDetails.thread;
            }
            if (((this.loan_id !== undefined) && (this.loan_id != null) && (this.loan_id !== ''))) {
                // eslint-disable-next-line no-undef
                axios.get(url, { params }).then(
                (response) => {
                    Object.keys(response.data).forEach((key) => {
                    this.counterOfferDetail.push(
                        response.data[key].counter_offer_details,
                    );
                    });
                },
                (err) => {
                    this.loading = false;
                    const snackObj = {
                    err,
                    snackMsg: this.$t('fetchCounterOfferError'),
                    };
                    this.$emit('snack', snackObj);
                },
                );
            } else this.$emit('snack', { snackMsg: this.$t('genericError') });
        },

    },
    computed:{
        me() {
            return this.$store.state?.me?.username ?? '';
        },
    },
    watch: {
        closeInboxDialog: function () {
            this.closeConfirmMessage = this.closeInboxDialog?.closeConfirmMessage;
        },
        successCallback: function () {
            this.isSuccess = this.successCallback?.isSuccess;
            this.isSuccessSelectedOffer = this.successCallback?.detail
        },
    },
    created(){
         this.getCounterOffers();
    }
}
</script>
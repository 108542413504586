<template>
  <div>
    <label for="dateField" class="text-muted small" :class="mandatory_star ? 'mandatory' : ''" v-if="label">{{ label }}</label>
    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field class="mt-1" name="dateField" v-model="date" :rules="rules" outlined clearable readonly dense v-bind="attrs" v-on="on" :disabled="disabled" :placeholder="placeholder" @click:clear="clearDate">
          <template v-slot:prepend-inner>
            <v-icon style="z-index:1">mdi-calendar</v-icon>
          </template>
        </v-text-field>
      </template>
      <v-date-picker v-model="date" :active-picker.sync="activePicker" @change="save" :max="max_date" :min="min_date" :disabled="disabled" :allowed-dates="allowedDates"></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activePicker: null,
      menu: false,
      date: this.inpDate ? new Date(this.inpDate).toISOString().slice(0, 10) : null,
    };
  },
  props: {
    label: String,
    inpDate: String,
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
    min_date: String,
    max_date: String,
    mandatory_star: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    disallowWeekends: Boolean,
    placeholder: String,
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'DATE'));
    },
    inpDate(val) {
      this.date = this.inpDate ? new Date(this.inpDate).toISOString().slice(0, 10) : null;
    },
  },
  methods: {
    clearDate() {
      const date = null;
      this.$refs.menu.save(date);
      this.$emit('date-field-selected', date);
    },
    save(date) {
      this.$refs.menu.save(date);
      this.$emit('date-field-selected', date);
    },
    allowedDates(val) {
      if (!this.disallowWeekends) return true;
      const day = new Date(val).getUTCDay();
      if (day === 6 || day === 0) return false;
      return true;
    },
  },
};
</script>

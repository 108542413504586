<template>
  <v-form ref="step4form">
    <div class="p-2">
      <div><label class="pb-1">{{$t('lossverificationStep4Question')}} </label> </div>
      <div> <label class="pb-1"><small>
            {{$t('lossverificationStep4ToolTip1')}}</small></label></div>
        <v-radio-group v-model="step4Data.flood_type" row>
           <v-radio v-for="(type,i) in flood_types" :key=i :value="type">
            <template v-slot:label>
              <div>{{type}} 
                <v-tooltip top :open-on-hover="false">
                  <template v-slot:activator="{ on, attrs }">
                   <v-btn  @click="on.click" @blur="on.blur" retain-focus-on-click icon v-bind="attrs" v-on="on" class="ml-n2 " v-bind:style="{ visibility: true  }">
                        <i class="fa-regular fa-question-circle brand-text-secondary"></i>
                      </v-btn>
                  </template>
                   <div class="loss-tooltip-radio">
                      <rising-water v-if="type == 'Rising Water'"></rising-water>
                      <seepage v-if="type == 'Seepage'"></seepage>
                      <runoff v-if="type == 'Run-off'"></runoff>
                      <sewer v-if="type == 'Sewer'"></sewer>
                      <span>{{getTooltipTitle(type)}}</span>
                      <div>{{getTooltipText(type)}}</div>
                    </div>
                </v-tooltip>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
    </div>
  </v-form>
</template>

<script>
import RisingWater from '../../svg/LossRisingWater.vue';
import Seepage from '../../svg/LossSeepage.vue';
import Runoff from '../../svg/LossRunoff.vue';
import Sewer from '../../svg/LossSewer.vue';
export default {

  mixins: [],
  components: {
    RisingWater, Seepage, Runoff, Sewer
  },
  props: {
     step4Obj: Object,
  },
  data() {
    return {
      step4Data:{
        flood_type : null
      },
      flood_types: [this.$t('risingWater'), this.$t('sewer'), this.$t('seepage'),this.$t('runoff')],
      tooltipData: 
        {
           'Rising Water': {
            title: this.$t('risingWater'),
            text: this.$t('risingWaterTooltip')
          },
          'Sewer' : {
            title: this.$t('sewer'),
            text: this.$t('sewerTooltip')
          },
          'Seepage' :{
            title: this.$t('seepage'),
            text: this.$t('seepageTooltip')
          },
          'Run-off': {
            title: this.$t('runoff'),
            text: this.$t('runoffTooltip')
          }
        }, 
    };
  },
  methods: {
    getTooltipTitle(val) {
      return this.tooltipData[val].title;
    },
    getTooltipText(val) {
      return this.tooltipData[val].text;
    }
  },
  watch: {
    step4Data: {
      handler() {
        this.$emit('update:step4Obj', this.step4Data);
      },
      deep: true,
    },
  },
};
</script>

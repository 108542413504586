<template>
  <v-dialog v-model="dialog" max-width="900px">
    <v-card :loading="loading" class="p-5">
      <v-card-title class="text-h6 justify-center brand-text-primary">{{$t('documentUpload')}}</v-card-title>
      <div class="m-4">
        <div class="d-flex flex-column align-center">
          <p class="mb-1 attachment-text" v-html="$t('attachmentTextDocAlt')"></p>
          <p class="mb-1" v-html="$t('doNotEncrypt')"></p>
          <p class="mb-1" >{{$t('validFileTypesText')}} <strong> pdf, xls, xlsx, csv, doc, docx, jpg, jpeg, png</strong></p>
          <v-divider></v-divider>
        </div>
        <v-form ref="uploadForm">
          <div class="row">
            <div class="col-md-12 py-0">
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-md-12 py-0">
              <label :for="filename" class="pb-1 mandatory"><small>{{$t('docName')}}</small></label>
              <v-text-field outlined solo dense clearable v-model="filename" :rules="mandatory"></v-text-field>
            </div>
          </div> -->
          <div class="row">
            <div class="col-md-12 py-0">
              <v-file-input :label="$t('selectFile')" v-model="file" class="documentInput" show-size outlined dense solo clearable :rules="extnCheck">
                <template v-slot:prepend-inner>
                  <v-icon style="z-index:1">mdi-folder-open</v-icon>
                </template>
              </v-file-input>
            </div>
          </div>
        </v-form>
      </div>
      <v-card-actions>
        <div class="w-100 d-flex justify-content-end align-center gap-3">
          <button class="btn btn-white d-block" @click.prevent="$emit('doc-uploaded',$event,'cancel')" :disabled="asyncDisable">
            {{$t('cancel')}}
          </button>
          <button class="btn btn-primary text-white" @click.prevent="uploadDocumentDialog" :disabled="asyncDisable">
            {{$t('upload')}}
          </button>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      asyncDisable: false,
      filename: undefined,
      file: undefined,
      validFileTypes: [
        'pdf',
        'xls',
        'xlsx',
        'csv',
        'doc',
        'docx',
        'jpg',
        'jpeg',
        'png',
      ],
      formData: new FormData(),
    };
  },
  props: {
    dialog: Boolean,
    docType: Object,
    loanId: String,
    details: {
      type: Object,
      default() { return {}; },
    },
    isServiceRequest: {
      type: Boolean,
      default: false,
    },
    srId: String,
  },
  methods: {
    uploadDocumentDialog() {
      if (!this.$refs.uploadForm.validate() || this.file === null) return;
      if (this.file.size > 35 * 1024 * 1024) {
        alert(this.$t('fileLimitExceeded'));
        return;
      }
      const self = this;
      const regex = new RegExp(
        `([a-zA-Z0-9s_\\.-:])+(${this.validFileTypes.join('|')})$`,
      );
      if (!regex.test(this.file.name.toLowerCase())) {
        const snackObj = {
          snackType: 'error',
          snackMsg: `${this.$t('fileExtensionError').replace('{}', this.validFileTypes.join(', '))}`,
        };
        this.$bubble('snack', snackObj);
        return;
      }
      this.loading = true;
      this.asyncDisable = true;

      const fileObj = {};
      fileObj.size = this.file.size;
      fileObj.name = this.file.name;
      fileObj.details = {};
      fileObj.details.requirement_level ='mandatory';
      fileObj.details.filename = fileObj.name;
      fileObj.details.size = fileObj.size;
      this.details.filename = fileObj.name;
      this.details.size = fileObj.size;
      fileObj.uploaded_doc_name = this.docType.name;
      fileObj.tags = [];
      fileObj.uploaded_doc_type = this.docType.name;
      fileObj.uploaded_doc_type_name = this.docType.name;
      this.formData.append('document', this.file);
      this.formData.append('name', this.docType.name);
      this.formData.append(
        'description',
        fileObj.documentLabel,
      );
      this.formData.append('requirement_level','mandatory');
      this.formData.append('document_type', this.docType.name);
      const status = 'Draft';
      const source = 'borrower';
      this.formData.append('status', status.toLowerCase());
      this.formData.append('source', source);
      if (Object.keys(this.details).length) this.formData.append('details', JSON.stringify(this.details));
      const config = {
        onUploadProgress: (progressEvent) => {
          self.progressValue = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      let url = `/api/tasks/${this.loanId}/documents`;
      if (this.isServiceRequest && this.srId !== '') {
        url = `/api/workflows/v1/service-requests/${this.srId}/documents`;
      }
      axios.post(url, this.formData, config).then((res) => {
        this.$emit('doc-uploaded', {
          ...fileObj, id: res.data.id, document_file: res.data.document, source, created: new Date(),
        });
        this.loading = false;
        this.asyncDisable = false;
      })
        .catch((err) => {
          const msg = err?.response?.data?.document ? err?.response?.data?.document.join('\n') : this.$t('uploadDocumentFailed');
          const snackObj = {
            err,
            snackType: 'error',
            snackMsg: msg,
          };
          this.$bubble('snack', snackObj);
          this.loading = false;
          this.asyncDisable = false;
        });
    },
  },
  computed: {
    extnCheck() {
      const rules = [];
      const rule2 = (v) => {
        if (v && v.name !== '') {
          if (!this.validFileTypes.includes(v.name.toLowerCase().split('.').at(-1))) {
            return this.$t('fileExtensionError').replace(
              '{}',
              this.validFileTypes.join(', '),
            );
          }
        }
        return true;
      };
      rules.push(rule2);
      return rules;
    },
    mandatory() {
      const rules = [];
      const rule = (v) => (v !== undefined && v != null && v !== '') || this.$t('requiredField');
      rules.push(rule);
      return rules;
    },
  },
};
</script>

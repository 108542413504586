<template>
  <v-app>
    <div class="v2-card-wrapper d-flex flex-column" style="flex: 100%;">
      <div class="header-text-wrapper mb-5">
        <div class="d-flex my-3">
          <i class="fa-regular fa-user-circle me-2 fa-3x brand-text-primary d-none d-md-block"></i>
          <div class="d-flex flex-column">
            <h4 class="brand-text-primary mb-0" v-if="me">
              {{ `${me.first_name} ${me.last_name}` }}</h4>
            <small>{{ me.username_display }}</small>
          </div>
        </div>
      </div>
      <div>
        <div v-if="!loading">
          <h4 class="brand-text-primary" v-if="messages.length > 0">{{ $t('accountNotVerified') }}</h4>
          <div v-else class="d-flex flex-column">
            <span class="w-100 justify-content-center justify-content-lg-start h4 mb-5">{{ $t('noRequiredActions')
            }}</span>
            <a :href="continueBtnLink" class="btn btn-white"><span>{{ $t('continue') }}</span></a>
          </div>
          <alt-inbox-message :postInterceptUrl="continueBtnLink" :thread="message" :show-dialog="false"
            :request-dialog="requestDialog" @closemessage="closeMessage(index)" @snack="handleSnack"
            :showVerificationSkip="showVerificationSkip" :messages="messages"></alt-inbox-message>
        </div>
        <!-- <div v-else>
        <v-skeleton-loader class="v2-card-light" type="list-item-avatar-three-line@3"></v-skeleton-loader>
      </div> -->
      </div>
      <v-snackbar v-model="snackbar" :multi-line="multiLine" :color="snackColor">
        {{ this.snackMsg }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-app>
</template>

<script>
import FilterMixin from '../mixins/FilterMixin';
import AccountViewMixin from '../mixins/AccountViewMixin';
import AlternateInboxMessage from './AlternateInboxMessage.vue';
import SnackMixin from '../mixins/SnackMixin';

export default {
  components: {
    'alt-inbox-message': AlternateInboxMessage,
  },
  mixins: [FilterMixin, AccountViewMixin, SnackMixin],
  props: {
    newMessageId: String,
    from: String,
    type: String,
    acceptingLoans: Boolean,
    userSBAId: String,
  },
  data() {
    return {
      requestDialog: false,
      dialogUpload: false,
      messages: [],
      messageNoFilter: [],
      loading: false,
      threadIndex: 0,
      message: {},
      doc_type_rule: [(v) => !!v || 'required field'],
      selectedFile: null,
      selectedDocName: null,
      selectedDocType: null,
      messageDetails: {},
      docReq: {},
      uploadedFiles: [],
      disableSave: true,
      taskUser: {},
      postInterceptUrl: '',
      showVerificationSkip: true,
    };
  },
  watch: {
    uploadedFiles() {
      if (this.uploadedFiles.length >= this.docReq?.document_types.length) {
        this.disableSave = false;
      } else if (!this.disableSave) {
        this.disableSave = true;
      }
    }
  },
  computed: {
    allowedIDTypes() {
      /* FIXME: this should come from the list of doc types from the backend */
      const types = [
        {
          text: this.$t('drivers_license'),
          name: 'drivers_license',
        },
        {
          text: this.$t('state_federal_issued'),
          name: 'state_gov_id',
        },
        {
          text: this.$t('us_passport'),
          name: 'passport',
        },
        {
          text: this.$t('us_green_card'),
          name: 'green_card',
        },
        {
          text: this.$t('social'),
          name: 'social',
        },
      ]
      const filteredTypes = types.filter((type) => this.docReq?.document_types?.includes(type?.name));
      return filteredTypes
    },
    uploadedDocsByType() {
      const uploadedDocs = {};
      this.uploadedFiles.forEach(({ type, doc, name, uploadedDocName }) => {
        uploadedDocs[`${type}`] = { uploadedDocName, name, doc };
      });
      return uploadedDocs;
    },
    continueBtnLink() {
      if (!this.postInterceptUrl) {
        return '/home'
      } else {
        return this.postInterceptUrl ?? '/home'
      }
    },
    me() {
      return this.$store.state.me
    }
  },
  methods: {
    emitSnack(snackObj) {
      this.$emit('snack', snackObj);
    },


    async getInboxItems() {
      this.selectedFilters = [];
      this.loading = true;
      let res = {};
      switch (this.type) {
        case 'open':
          res = await axios.get('/api/message_center/v1/inbox?is_closed=false&mine=true');
          break;
        case 'pending':
          res = await axios.get('/api/message_center/v1/inbox?is_open=true&mine=true');
          break;
        case 'closed':
          res = await axios.get('/api/message_center/v1/inbox?is_closed=true&mine=true');
          break;
        default:
          res = await axios.get('/api/message_center/v1/inbox?mine=true');
      }
      // if (res.data.length > 0) {
      //   this.$emit('setNoMessages');
      // }
      this.messages = res.data
      this.message = res.data[0]
      this.loading = false
      if (this.messages.length === 0) {
        this.$emit('set-no-messages')
      }
      this.$nextTick(() => {
        if (this.messages.length > 0) {
          this.requestDialog = true
        }
      })
    },
    async closeMessage() {
      this.$set(this.requestDialog, false);
      await this.getInboxItems();
    },
  },
  async created() {
    const postIntUrl = post_intercept_url;
    this.showVerificationSkip = show_verification_skip;
    const formattedURL = postIntUrl.replace(/amp;/g, '');
    if (formattedURL && formattedURL !== 'None') {
      this.postInterceptUrl = formattedURL;
    }
    this.type = 'pending';
    await this.getInboxItems();
  },
};
</script>

<style scoped>
.v-badge__badge {
  background: var(--lc-primary) !important;
  color: var(--lc-text-light) !important;
}

.badge {
  border-radius: 12px !important;
}

.msg-sizing {
  font-size: 0.8rem !important;
}

.new-message-container>>>.theme--light:not(.v-expansion-panels).v-application {
  background-color: transparent !important;
  background: transparent !important
}

.header-text-wrapper {
  margin-top: -1.5rem;
}

.attachment-text {
  max-width: 525px;
  margin: 0 auto;
}
</style>

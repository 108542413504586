<template>
  <v-app>
    <div class="page-container d-md-flex mt-4">
      <div class="inner-container d-flex justify-content-center gap-lg-2 align-items-center flex-xl-row">
        <div class="info-pane col-12 col-md-5">
          <EddLeftInfo :textDisplay="textDisplay" />
        </div>
        <div class="login-pane col-12 col-md-7 d-flex flex-column">
          <div class="d-md-none text-center">
            <img alt="Treasury Logo" :src="treasuryLogo" class="treasury-logo" />
            <div class="h4 mt-4">
              Access account with <img alt="Socure Logo" :src="socureLogo" class="socure-logo" />
            </div>
          </div>
          <div class="page-content">
            <div class="m-4 d-none d-md-flex">
              <div class="d-flex align-items-center flex-wrap justify-content-start">
                <a class="btn-socure btn-socure-primary d-none d-md-block" @click="backToIrs">Back to IRS.gov</a>
              </div>
            </div>
            <div class="m-4">
              <h1 class="fw-bold brand-text-primary">Verify Your Identity with a Live Agent</h1>
              <div class="text-start">
                To continue with your account you will be required to verify your identity with a live agent on a video
                appointment.
              </div>
              <div class="h3 pt-3 brand-text-primary">You will need the following:</div>
            </div>
            <div class="d-flex flex-column flex-md-row">
              <div class="icon-div">
                <i aria-hidden="true" class="far fa-circle-video fa-2x pb-3 text-info"></i>
                <div>A device with a working video camera</div>
              </div>
              <div class="icon-div">
                <i aria-hidden="true" class="far fa-id-card fa-2x pb-3 text-info"></i>
                <div>A government issued ID</div>
              </div>
            </div>
            <div class="mx-4 text-start">
              You can join the waiting room now or schedule an appointment to verify your identity.
            </div>
            <div class="mx-4">
              <v-btn class="btn-socure my-3 large btn-socure-secondary" @click="confirmDialog = true"
                >Join Waiting Room</v-btn
              >
              <v-btn class="btn-socure large btn-socure-secondary" @click="goScheduling">Schedule Appointment</v-btn>
            </div>
          </div>
        </div>
      </div>
      <v-dialog v-model="confirmDialog" width="500px">
        <div class="dialog-header">
          <h1 class="fs-3">Join the Waiting Room</h1>
        </div>
        <div class="dialog-text">
          <div>
            You will be directed to a video waiting room to verify your ID. The avg wait time is <b>20 mins</b>.
          </div>
        </div>
        <div class="dialog-buttons text-center">
          <v-btn class="my-3 btn-socure large btn-socure-secondary" @click="goMeeting">Join Waiting Room Now</v-btn>
          <v-btn class="btn-socure btn-socure-primary" @click="confirmDialog = false"
            >No, I do not wish to join now</v-btn
          >
        </div>
      </v-dialog>
    </div>
  </v-app>
</template>
<script>
import EddLeftInfo from './EddLeftInfo.vue';

export default {
  props: {
    id: String,
  },
  components: { EddLeftInfo },
  data() {
    return {
      socureLogo: require('../../../img/socure-logo.png'),
      treasuryLogo: require('../../../img/USDOT-Crest-Dark.png'),
      confirmDialog: false,
      email: '',
    };
  },
  methods: {
    goMeeting() {
      this.$router.push({ name: 'EddMeetingPage', params: { id: this.id } });
    },
    goScheduling() {
      this.$router.push({ name: 'EddSchedulingPage', params: { id: this.id } });
    },
    backToIrs() {
      window.open('/');
    },
  },
  computed: {
    textDisplay() {
      return `Your account for <b>${this.email}</b> is pending identity verification.`;
    },
  },
  async created() {
    try {
      const res = await axios.get(`/api/id-cases/${this.id}`);
      this.caseData = res.data;
      console.log(res.data);
      this.email = res.data.user_email;
    } catch (err) {
      console.error(err);
    }
  },
};
</script>
<style>
body {
  --primary-font-color: #383838;
  --primary-text-color: #383838;
  --primary-font-family: Bree Serif, Public Sans, Helvetica, Arial, sans-serif;
  --primary: #002e6d;
  --v-primary-base: #296d97;
  --secondary: #fdb71a;
  --v-secondary-base: #fdb71a;
  --tertiary: #66b3ff;
  --light: #f4f8fa;
  --grey: #e9f1f5;
  --text-light: #f0f0f0;
  --success: #1be632;
  width: 100%;
  height: 100%;
  background: #002e6d;
  border-bottom: none;
}
</style>
<style scoped>
@import '../../../css/stepper.css';
.page-container {
  min-height: calc(100vh - 20px);
  justify-content: center !important;
  align-items: flex-end;
  width: 100%;
}
.inner-container {
  max-width: 1400px;
}
:deep(.v-toolbar__content) {
  align-items: end;
}
.page-content {
  flex: 100;
  background-color: #f4f8fa;
  text-align: center;
  min-height: 800px;
}
.treasury-logo {
  width: 70%;
}
.dialog-header {
  display: flex;
  background-color: #fafafa;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
}
.dialog-header > div {
  font-weight: 600;
}
.dialog-text {
  display: flex;
  background-color: #fff;
  padding: 8px 16px;
  border-bottom: 1px solid #e8e8e8;
}
.dialog-buttons {
  padding: 10px 16px;
  background-color: #fafafa;
}
.icon-div {
  background-color: var(--lc-background-color);
  margin: 0rem 1rem 1rem 1rem;
  padding: 2rem 2rem 2rem 2rem;
  text-align: center !important;
}
.btn-socure-primary {
  box-shadow: none;
  border-radius: 0px;
  background: none !important;
}
.login-pane {
  min-height: 800px;
  margin-left: auto;
}

.btn-socure {
  text-transform: none !important;
}

@media screen and (min-width: 769px) {
  .page-content {
    text-align: left;
    padding: 2rem;
  }
  .icon-div {
    width: 50%;
  }
  .page-container {
    margin: -12px;
  }
  .login-pane {
    max-width: 700px;
  }
}
@media screen and (max-width: 768px) {
  .info-pane {
    display: none;
  }
}
</style>

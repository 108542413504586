<template>
  <v-app>
    <div class="page-container d-md-flex mt-4">
      <div class="inner-container d-flex justify-content-center gap-lg-2 align-items-center flex-xl-row">
        <div v-show="loading" class="info-pane col-12 col-md-5">
          <EddLeftInfo />
        </div>
        <div class="login-pane col-12 col-md-7 d-flex flex-column">
          <div class="d-md-none text-center">
            <img alt="Treasury Logo" :src="treasuryLogo" class="treasury-logo" />
            <div class="h4 mt-4">
              Access account with <img alt="Socure Logo" :src="socureLogo" class="socure-logo" />
            </div>
          </div>
          <div v-show="loading" class="page-content">
            <div class="app d-flex justify-content-center">
              <div class="w-85 d-flex flex-column gap-3">
                <div class="fs-3 fw-bold w-85">Please wait, the meeting is being setup now</div>
                <div class="d-flex justify-content-center">
                  <v-progress-circular indeterminate color="primary" />
                </div>
              </div>
            </div>
          </div>
          <div id="zmmtg-root" v-show="!loading"></div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import EddLeftInfo from './EddLeftInfo.vue';
import { ZoomMtg } from '@zoom/meetingsdk';

export default {
  props: {
    id: String,
  },
  components: { EddLeftInfo },
  data() {
    return {
      loading: true,
      meetingDetails: null,
      signature: null,
      authEndpoint: 'http://localhost:4000/',
      sdkKey: 'W9Fu1_GjTq6texj8TqR0g',
      userName: 'Guest',
      leaveUrl: `/signup/registration#/case/${this.id}/complete`,
      socureLogo: require('../../../img/socure-logo.png'),
      treasuryLogo: require('../../../img/USDOT-Crest-Dark.png'),
    };
  },
  methods: {
    async getCaseDetails() {
      try {
        const res = await axios.get(`/api/id-cases/${this.id}?role=0`);
        this.meetingDetails = res.data.meeting_details;
        this.signature = res.data.signature;
        console.log(res.data.user_name_display);
        this.userName = res.data.user_name_display;
        res.data.case_details.status = 'queued';
        await axios.patch(`/api/id-cases/${this.id}/`, res.data);
        await this.startMeeting();
      } catch (err) {
        console.error(err);
      }
    },
    startMeeting() {
      ZoomMtg.init({
        leaveUrl: this.leaveUrl,
        patchJsMedia: true,
        success: (success) => {
          this.loading = false;
          ZoomMtg.join({
            signature: this.signature,
            sdkKey: this.sdkKey,
            meetingNumber: this.meetingDetails.meeting_id,
            passWord: this.meetingDetails.join_password,
            userName: this.userName,
            success: (success) => {
              console.log('Join meeting success:', success);
            },
            error: (error) => {
              console.error('Zoom error:', error);
            },
          });
        },
        error: (error) => {
          console.error('Frontend error:', error);
        },
      });
    },
  },
  mounted() {
    this.getCaseDetails();
  },
};
</script>
<style>
body {
  --primary-font-color: #383838;
  --primary-text-color: #383838;
  --primary-font-family: Bree Serif, Public Sans, Helvetica, Arial, sans-serif;
  --primary: #002e6d;
  --v-primary-base: #296d97;
  --secondary: #fdb71a;
  --v-secondary-base: #fdb71a;
  --tertiary: #66b3ff;
  --light: #f4f8fa;
  --grey: #e9f1f5;
  --text-light: #f0f0f0;
  --success: #1be632;
  width: 100%;
  height: 100%;
  background: #002e6d;
  border-bottom: none;
}
</style>
<style scoped>
.page-container {
  min-height: calc(100vh - 20px);
  justify-content: center !important;
  align-items: flex-end;
  width: 100%;
}
:deep(.v-toolbar__content) {
  align-items: end;
}
.w-85 {
  width: 85%;
}
.treasury-logo {
  width: 70%;
}
.page-content {
  flex: 100;
  background-color: #f4f8fa;
  text-align: center;
  min-height: 800px;
}
@media screen and (min-width: 769px) {
  .page-content {
    text-align: left;
    padding: 2rem;
  }
  .page-container {
    margin: -12px;
  }
  .login-pane {
    max-width: 700px;
  }
}
@media screen and (max-width: 768px) {
  .info-pane {
    display: none;
  }
}
</style>

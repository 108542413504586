import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const applicationModes = Object.freeze({
  CREATE_MODE: 0,
  EDIT_MODE: 1,
  READ_ONLY: 2,
});

export default new Vuex.Store({
  state: {
    applicationMode: applicationModes.CREATE_MODE,
    applicationDataObj: { uploadedDocuments: [], owners: [] },
    applicationErrorObj: [],
    me: [],
    is_sba: false,
    ebill_loan_number: '',
    locale: 'en',
    constance: [],
    orgConfigData: {},
  },
  mutations: {
    updateApplicationDataObj(state, value) {
      state.applicationDataObj = { ...state.applicationDataObj, ...value };
    },
    updateApplicationErrorObj(state, value) {
      state.applicationErrorObj.push(value);
    },
    setInitialValueToStore(state, initData) {
      state.applicationDataObj = { ...initData };
    },
    deleteApplicationDataObjKey(state, deleteFields) {
      deleteFields.forEach((fld) => {
        if (Object.hasOwn(state.applicationDataObj, fld.name)) { delete state.applicationDataObj[fld.name]; }
        if (fld.field_type === 'address') {
          try {
            delete state.applicationDataObj[fld.name].street_line;
            delete state.applicationDataObj[fld.name].street_line;
            delete state.applicationDataObj[fld.name].secondary;
            delete state.applicationDataObj[fld.name].city;
            delete state.applicationDataObj[fld.name].state;
            delete state.applicationDataObj[fld.name].zipcode;
            delete state.applicationDataObj[fld.name].country;
            delete state.applicationDataObj[fld.name].zip_plus4;
          } catch (err) {
            // console.log('deletion error:', err);
          }
        }
        if (fld.field_type === 'naics') {
          try {
            delete state.applicationDataObj[fld.name];
            delete state.applicationDataObj[`${fld.name}_code`];
          } catch (err) {
            // console.log('deletion error:', err);
          }
        }
        if (fld.field_type === 'tin' || fld.field_type === 'ssn') {
          try {
            delete state.applicationDataObj[fld.name];
            delete state.applicationDataObj[`${fld.name}_type`];
          } catch (err) {
            // console.log('deletion error:', err);
          }
        }
        if (fld.field_type === 'entity_type') {
          try {
            delete state.applicationDataObj[fld.name];
            delete state.applicationDataObj[`${fld.name}_type`];
            delete state.applicationDataObj[`${fld.name}_entity_type`];
          } catch (err) {
            // console.log('deletion error:', err);
          }
        }
        if (fld.field_type === 'address') {
          try {
            delete state.applicationDataObj[fld.name];
            delete state.applicationDataObj[`${fld.name}_alternate_address`];
          } catch (err) {
            // console.log('deletion error:', err);
          }
        }
      });
    },
    resetApplicationErrorObj(state) {
      state.applicationErrorObj = [];
    },
    resetRuleValErrors(state) {
      state.applicationErrorObj = state.applicationErrorObj.filter((e) => e.type != 'rule_validations');
    },
    resetNonRuleValErrors(state) {
      state.applicationErrorObj = state.applicationErrorObj.filter((e) => e.type == 'rule_validations');
    },
    resetDocumentErrors(state) {
      state.applicationErrorObj = state.applicationErrorObj.filter((e) => e.type != 'document_validations');
    },
    resetBackEndValErrors(state) {
      state.applicationErrorObj = state.applicationErrorObj.filter((e) => e.type != 'backend_validations');
    },
    updateApplicationMode(state, value) {
      state.applicationMode = value;
    },
    saveLocale(state, value) {
      state.locale = value;
    },
    addAboutMe(state, value) {
      state.me = { ...state.me, ...value };
    },
    addConstance(state, value) {
      state.constance = value;
    },
    setOrg(state, value) {
      if (value === 'True') state.is_sba = true;
      else state.is_sba = false;
    },
    setTotalSBALoans(state, value) {
      state.me = { ...state.me, ...value };
    },
    setUnreadMessageCount(state, value) {
      state.me = { ...state.me, ...value };
    },
    updateOrgConfigObj(state, value) {
      state.orgConfigData = { ...value };
    },
    updateebillLoan_number(state, value) {
      state.ebill_loan_number = value;
    },
  },
  actions: {
    deleteApplicationDataObjKey(context, deleteFields) {
      context.commit('deleteApplicationDataObjKey', deleteFields);
    },
    deleteebillloan_number(context, deleteFields) {
      context.commit('ebill_loan_number', deleteFields);
    },
  },
});

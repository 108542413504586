var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-form", { ref: "step4form" }, [
    _c(
      "div",
      { staticClass: "p-2" },
      [
        _c("div", [
          _c("label", { staticClass: "pb-1" }, [
            _vm._v(_vm._s(_vm.$t("lossverificationStep4Question")) + " "),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("label", { staticClass: "pb-1" }, [
            _c("small", [
              _vm._v(
                "\n          " + _vm._s(_vm.$t("lossverificationStep4ToolTip1"))
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "v-radio-group",
          {
            attrs: { row: "" },
            model: {
              value: _vm.step4Data.flood_type,
              callback: function ($$v) {
                _vm.$set(_vm.step4Data, "flood_type", $$v)
              },
              expression: "step4Data.flood_type",
            },
          },
          _vm._l(_vm.flood_types, function (type, i) {
            return _c("v-radio", {
              key: i,
              attrs: { value: type },
              scopedSlots: _vm._u(
                [
                  {
                    key: "label",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          [
                            _vm._v(_vm._s(type) + " \n              "),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "", "open-on-hover": false },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "ml-n2",
                                                  style: { visibility: true },
                                                  attrs: {
                                                    "retain-focus-on-click": "",
                                                    icon: "",
                                                  },
                                                  on: {
                                                    click: on.click,
                                                    blur: on.blur,
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa-regular fa-question-circle brand-text-secondary",
                                              }),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "loss-tooltip-radio" },
                                  [
                                    type == "Rising Water"
                                      ? _c("rising-water")
                                      : _vm._e(),
                                    _vm._v(" "),
                                    type == "Seepage"
                                      ? _c("seepage")
                                      : _vm._e(),
                                    _vm._v(" "),
                                    type == "Run-off" ? _c("runoff") : _vm._e(),
                                    _vm._v(" "),
                                    type == "Sewer" ? _c("sewer") : _vm._e(),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.getTooltipTitle(type))),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v(_vm._s(_vm.getTooltipText(type))),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            })
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
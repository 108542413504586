var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-form", { ref: "step2form" }, [
    _c("span", { staticClass: "brand-text-primary data-val" }, [
      _vm._v("Explore Your Options"),
    ]),
    _vm._v(" "),
    _c("div", [
      _c("label", { staticClass: "pb-1 mandatory" }, [
        _c("small", [
          _vm._v(
            "If you are interested in one of the below options, please select and follow the required prompts."
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "p-2" },
      [
        _c(
          "v-radio-group",
          {
            attrs: { rules: _vm.mandatoryRule },
            model: {
              value: _vm.step2Data.selectedCounterOffer,
              callback: function ($$v) {
                _vm.$set(_vm.step2Data, "selectedCounterOffer", $$v)
              },
              expression: "step2Data.selectedCounterOffer",
            },
          },
          _vm._l(_vm.counter_offers, function (item, index) {
            return _c("v-radio", {
              key: index,
              staticClass: "card-bg-light pl-4 py-3",
              attrs: { value: item },
              scopedSlots: _vm._u(
                [
                  {
                    key: "label",
                    fn: function () {
                      return [
                        _c("v-container", { staticClass: "card-bg-light" }, [
                          _vm._v(
                            "\n           Option " +
                              _vm._s(index + 1) +
                              "\n           "
                          ),
                          _c("div", [
                            _c(
                              "span",
                              { staticClass: "brand-text-primary data-val" },
                              [_vm._v(_vm._s(item.product.name))]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-row flex-wrap justify-content-start",
                            },
                            [
                              _c("div", { staticClass: "info-box col-md-3" }, [
                                _c("label", { staticClass: "pb-1 mandatory" }, [
                                  _vm._v(_vm._s(_vm.$t("loanAmount"))),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "pb-1 brand-text-primary" },
                                  [_vm._v("$" + _vm._s(item.loan_amount))]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "info-box col-md-3" }, [
                                _c("label", { staticClass: "pb-1 mandatory" }, [
                                  _vm._v(_vm._s(_vm.$t("loanTerm"))),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "pb-1 text-primary" },
                                  [_vm._v(_vm._s(item.loan_tenure) + " Months")]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "info-box col-md-3" }, [
                                _c("label", { staticClass: "pb-1 mandatory" }, [
                                  _vm._v(_vm._s(_vm.$t("interestRate"))),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "pb-1 text-primary" },
                                  [_vm._v(_vm._s(item.rate) + "%")]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            })
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
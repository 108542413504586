export default {
  data() {
    return {
      snackbar: false,
      snackColor: undefined,
      snackMsg: undefined,
      multiLine: true,
      apiErrors: [],
    };
  },
  methods: {
    getApiErrors(element, labelPath) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(element)) {
        if (['string', 'boolean', 'number'].includes(typeof element[key])) {
          let label = labelPath;
          if (label !== '') { label += ':'; }
          this.apiErrors.push(`${label} ${value}`);
        } else {
          let labelTmp = labelPath;
          // eslint-disable-next-line no-nested-ternary
          labelTmp += (labelPath === '') ? key : (Array.isArray(element) ? '' : `.${key}`);
          this.getApiErrors(element[key], labelTmp);
        }
      }
    },
    handleSnack(e) {
      let snackMsg = e?.err?.response?.data.detail ? e.err.response.data.detail : e.snackMsg;

      if (e.snackType !== 'success') {
        this.apiErrors = [];
        if (e?.err?.response?.data) {
          if (e.err.response.status !== 500) {
            const errors = (Array.isArray(e.err.response.data)) ? e.err.response.data : [e.err.response.data];
            errors.forEach((err) => {
              if (typeof (err) === 'string') {
                this.apiErrors.push(`${err}`);
              } else {
                this.getApiErrors(err, '');
              }
            });
          }
        }
        if (this.apiErrors.length) {
          snackMsg = this.apiErrors.join(',');
        }
      }
      switch (e.snackType) {
        case 'error':
          this.snackMsg = snackMsg || this.$t('operationFailure');
          this.snackColor = 'red darken-2';
          break;
        case 'success':
          this.snackMsg = snackMsg || this.$t('operationSuccess');
          this.snackColor = 'green lighten-1';
          break;
        default:
          this.snackMsg = snackMsg || this.$t('operationFailure');
          this.snackColor = 'red darken-2';
      }
      this.snackbar = true;
    },
  },
};

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.label
        ? _c(
            "label",
            {
              staticClass: "text-muted small",
              class: _vm.mandatory_star ? "mandatory" : "",
              attrs: { for: "dateField" },
            },
            [_vm._v(_vm._s(_vm.label))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-menu",
        {
          ref: "menu",
          attrs: {
            "close-on-content-click": false,
            transition: "scale-transition",
            "offset-y": "",
            "min-width": "auto",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-text-field",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "mt-1",
                          attrs: {
                            name: "dateField",
                            rules: _vm.rules,
                            outlined: "",
                            clearable: "",
                            readonly: "",
                            dense: "",
                            disabled: _vm.disabled,
                            placeholder: _vm.placeholder,
                          },
                          on: { "click:clear": _vm.clearDate },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "prepend-inner",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-icon",
                                      { staticStyle: { "z-index": "1" } },
                                      [_vm._v("mdi-calendar")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: _vm.date,
                            callback: function ($$v) {
                              _vm.date = $$v
                            },
                            expression: "date",
                          },
                        },
                        "v-text-field",
                        attrs,
                        false
                      ),
                      on
                    )
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.menu,
            callback: function ($$v) {
              _vm.menu = $$v
            },
            expression: "menu",
          },
        },
        [
          _vm._v(" "),
          _c("v-date-picker", {
            attrs: {
              "active-picker": _vm.activePicker,
              max: _vm.max_date,
              min: _vm.min_date,
              disabled: _vm.disabled,
              "allowed-dates": _vm.allowedDates,
            },
            on: {
              "update:activePicker": function ($event) {
                _vm.activePicker = $event
              },
              "update:active-picker": function ($event) {
                _vm.activePicker = $event
              },
              change: _vm.save,
            },
            model: {
              value: _vm.date,
              callback: function ($$v) {
                _vm.date = $$v
              },
              expression: "date",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-form", { ref: _vm.ref_id }, [
    _c(
      "div",
      { staticClass: "p-2" },
      [
        _c("span", { staticClass: "text-primary text-capitalize" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("automobile")) +
              " " +
              _vm._s(_vm.stepCustomData.autoMobileNumberStr)
          ),
        ]),
        _vm._v(" "),
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { staticClass: "col-12 col-md-6" },
              [
                _c("label", { staticClass: "pb-1 mandatory" }, [
                  _c("small", [
                    _vm._v(_vm._s(_vm.$t("lossverificationVINquestion"))),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "v-radio-group",
                  {
                    attrs: { rules: _vm.mandatoryRuleVINRadio, row: "" },
                    on: {
                      change: function ($event) {
                        return _vm.resetValues()
                      },
                    },
                    model: {
                      value: _vm.stepCustomData.isVinNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.stepCustomData, "isVinNumber", $$v)
                      },
                      expression: "stepCustomData.isVinNumber",
                    },
                  },
                  [
                    _c("v-radio", {
                      attrs: { label: _vm.$t("yes"), value: true },
                    }),
                    _vm._v(" "),
                    _c("v-radio", {
                      attrs: { label: _vm.$t("no"), value: false },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.stepCustomData.isVinNumber
          ? _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "col-12 col-md-6" },
                  [
                    _c("label", { staticClass: "pb-1 mandatory" }, [
                      _c("small", [_vm._v(_vm._s(_vm.$t("vinNumber")))]),
                    ]),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        solo: "",
                        outlined: "",
                        dense: "",
                        rules: _vm.mandatoryRuleVIN,
                      },
                      model: {
                        value: _vm.stepCustomData.vinNumber,
                        callback: function ($$v) {
                          _vm.$set(_vm.stepCustomData, "vinNumber", $$v)
                        },
                        expression: "stepCustomData.vinNumber",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.stepCustomData.isVinNumber
          ? _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "col-12 col-md-3" },
                  [
                    _c("label", { staticClass: "pb-1 mandatory" }, [
                      _c("small", [_vm._v(_vm._s(_vm.$t("year")))]),
                    ]),
                    _vm._v(" "),
                    _c("v-select", {
                      attrs: {
                        items: _vm.vehicle_data["years_list"],
                        solo: "",
                        outlined: "",
                        dense: "",
                        rules: _vm.mandatoryRule,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.getMakeList()
                        },
                      },
                      model: {
                        value: _vm.stepCustomData.selectedYear,
                        callback: function ($$v) {
                          _vm.$set(_vm.stepCustomData, "selectedYear", $$v)
                        },
                        expression: "stepCustomData.selectedYear",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-col",
                  { staticClass: "col-12 col-md-3" },
                  [
                    _c("label", { staticClass: "pb-1 mandatory" }, [
                      _c("small", [_vm._v(_vm._s(_vm.$t("make")))]),
                    ]),
                    _vm._v(" "),
                    _c("v-select", {
                      attrs: {
                        items: _vm.getMakeList(),
                        solo: "",
                        outlined: "",
                        dense: "",
                        rules: _vm.mandatoryRule,
                        disabled: _vm.stepCustomData.selectedYear == "",
                      },
                      model: {
                        value: _vm.stepCustomData.selectedMake,
                        callback: function ($$v) {
                          _vm.$set(_vm.stepCustomData, "selectedMake", $$v)
                        },
                        expression: "stepCustomData.selectedMake",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-col",
                  { staticClass: "col-12 col-md-3" },
                  [
                    _c("label", { staticClass: "pb-1 mandatory" }, [
                      _c("small", [_vm._v(_vm._s(_vm.$t("model")))]),
                    ]),
                    _vm._v(" "),
                    _c("v-select", {
                      attrs: {
                        items: _vm.getModelList(),
                        solo: "",
                        outlined: "",
                        dense: "",
                        rules: _vm.mandatoryRule,
                        disabled: _vm.stepCustomData.selectedMake == "",
                      },
                      model: {
                        value: _vm.stepCustomData.selectedModel,
                        callback: function ($$v) {
                          _vm.$set(_vm.stepCustomData, "selectedModel", $$v)
                        },
                        expression: "stepCustomData.selectedModel",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { staticClass: "col-12 col-md-12" },
              [
                _c("label", { staticClass: "pb-1 mandatory" }, [
                  _vm._v(_vm._s(_vm.$t("lossverificationStepCustomQuestion"))),
                ]),
                _vm._v(" "),
                _c(
                  "v-radio-group",
                  {
                    attrs: { row: "", rules: _vm.mandatoryRadioRule },
                    model: {
                      value: _vm.stepCustomData.damageRepair,
                      callback: function ($$v) {
                        _vm.$set(_vm.stepCustomData, "damageRepair", $$v)
                      },
                      expression: "stepCustomData.damageRepair",
                    },
                  },
                  [
                    _c("v-radio", {
                      attrs: { label: _vm.$t("repair"), value: true },
                    }),
                    _vm._v(" "),
                    _c("v-radio", {
                      attrs: { label: _vm.$t("replacement"), value: false },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "thread-messages overflow-auto p-3 p-md-5 pt-0" },
    [
      _vm.firstScreen
        ? _c("div", { attrs: { id: "inbox-item-los" } }, [
            _c(
              "ul",
              { staticClass: "message-thread" },
              _vm._l(_vm.requestDetails.messages, function (message, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    staticClass: "rounded p-2 px-3",
                    class: _vm.me === message.sender ? "my-response" : "",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-between gap-3 align-top",
                      },
                      [
                        _c("div", [
                          _c("i", {
                            staticClass:
                              "fa-regular fa-comments-alt fa-lg brand-text-primary",
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex-grow-1" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between flex-wrap",
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "brand-text-primary fs-9" },
                                [
                                  _vm._v(
                                    "\n                   " +
                                      _vm._s(message.sender) +
                                      "\n                 "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", [
                                _c("i", {
                                  staticClass: "fa-regular fa-clock fa-sm",
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "timestamp" }, [
                                  _vm._v(
                                    _vm._s(_vm._f("dateTime")(message.created))
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "pt-2 fs-9",
                            domProps: { innerHTML: _vm._s(message.message) },
                          }),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "p-4" },
                      _vm._l(message.attachments, function (attachment, index) {
                        return _c("div", { key: index }, [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-primary",
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function ($event) {
                                  return _vm.viewItemInbox(attachment.url)
                                },
                              },
                            },
                            [_vm._v("View Notice")]
                          ),
                        ])
                      }),
                      0
                    ),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "350" },
          model: {
            value: _vm.closeConfirmMessage,
            callback: function ($$v) {
              _vm.closeConfirmMessage = $$v
            },
            expression: "closeConfirmMessage",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "confirm-dialog" },
            [
              _c("v-card-title", { staticClass: "justify-content-center" }, [
                _c("i", {
                  staticClass: "fal fa-exclamation-circle fa-2x text-warning",
                }),
              ]),
              _vm._v(" "),
              _c("v-card-text", { staticClass: "text-center" }, [
                _vm._v(
                  'Your data will not be saved if you exit without completing. Are you sure you want to exit?"'
                ),
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                { staticClass: "justify-content-center gap-3" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-white",
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.closeConfirmMessage = false
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmLossVerificationClose(
                            _vm.requestDetails
                          )
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("confirm")))]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "880" },
          model: {
            value: _vm.isSuccess,
            callback: function ($$v) {
              _vm.isSuccess = $$v
            },
            expression: "isSuccess",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "d-flex flex-column align-items-center p-5 gap-2" },
            [
              _c("v-card-title", [
                _c("i", {
                  staticClass: "fa-regular fa-check-circle fa-lg text-success",
                }),
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "text-center" },
                [
                  _c("span", [
                    _vm._v(
                      "You have successfully accept the counter offer for "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "brand-text-primary" }, [
                    _vm._v(_vm._s(_vm.requestDetails.borrower)),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "A new loan application is has been created with the following terms"
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.isSuccessSelectedOffer.product
                    ? _c(
                        "v-container",
                        { staticClass: "card-bg-light text-left" },
                        [
                          _c(
                            "p",
                            { staticClass: "brand-text-primary data-val" },
                            [
                              _vm._v(
                                _vm._s(_vm.isSuccessSelectedOffer.product.name)
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-row flex-wrap justify-content-start",
                            },
                            [
                              _vm.isSuccessSelectedOffer.loan_amount
                                ? _c(
                                    "div",
                                    { staticClass: "info-box col-md-3" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "pb-1 mandatory" },
                                        [_vm._v(_vm._s(_vm.$t("loanAmount")))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pb-1 brand-text-primary",
                                        },
                                        [
                                          _vm._v(
                                            "$" +
                                              _vm._s(
                                                _vm.isSuccessSelectedOffer
                                                  .loan_amount
                                              )
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isSuccessSelectedOffer.loan_tenure
                                ? _c(
                                    "div",
                                    { staticClass: "info-box col-md-3" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "pb-1 mandatory" },
                                        [_vm._v(_vm._s(_vm.$t("loanTerm")))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "pb-1 text-primary" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.isSuccessSelectedOffer
                                                .loan_tenure
                                            ) + " Months"
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isSuccessSelectedOffer.rate
                                ? _c(
                                    "div",
                                    { staticClass: "info-box col-md-3" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "pb-1 mandatory" },
                                        [_vm._v(_vm._s(_vm.$t("interestRate")))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "pb-1 text-primary" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.isSuccessSelectedOffer.rate
                                            ) + "%"
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", { staticClass: "brand-text-primary mt-3" }, [
                    _vm._v(
                      "We need you to provide some additional information before \n                 submitting your new application"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("button", { staticClass: "btn btn-primary" }, [
                      _c("span", { staticClass: "fw-semi-bold" }, [
                        _vm._v("Add Information"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-white pt-2",
                        on: {
                          click: function ($event) {
                            return _vm.confirmLossVerificationClose(
                              _vm.requestDetails
                            )
                          },
                        },
                      },
                      [_c("b", [_vm._v("Complete Later")])]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        { staticClass: "px-0 pt-0" },
        [
          _c(
            "counter-offer-stepper",
            _vm._g(
              {
                attrs: { counter_offer_details: _vm.counterOfferDetail },
                on: {
                  onFirstScreen: _vm.onFirstScreen,
                  closeMessage: _vm.closeMessage,
                },
              },
              _vm.$listeners
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-form", { ref: "step2form" }, [
    _c(
      "div",
      { staticClass: "p-2" },
      [
        _c("div", [
          _c("label", { staticClass: "pb-1" }, [
            _vm._v(_vm._s(_vm.$t("lossverificationStep2Question"))),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("label", { staticClass: "pb-1 mandatory" }, [
            _c("small", [
              _c("i", { staticClass: "fal fa-info-circle" }),
              _vm._v(_vm._s(_vm.$t("lossverificationStep2ToolTip1"))),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { staticClass: "col-12 col-md-6" },
              [
                _c("label", { staticClass: "pb-1 mandatory" }, [
                  _c("small", [
                    _vm._v(_vm._s(_vm.$t("lossverificationStep2SubQuestion1"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("v-select", {
                  attrs: {
                    items: _vm.property_types,
                    solo: "",
                    outlined: "",
                    dense: "",
                    placeholder: _vm.$t("lossverificationStep2ToolTip2"),
                    rules: _vm.mandatoryRule,
                  },
                  model: {
                    value: _vm.step2Data.property_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.step2Data, "property_type", $$v)
                    },
                    expression: "step2Data.property_type",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-col",
              { staticClass: "col-12 col-md-6" },
              [
                _c("label", { staticClass: "pb-1 mandatory" }, [
                  _c("small", [
                    _vm._v(_vm._s(_vm.$t("lossverificationStep2SubQuestion2"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("v-text-field", {
                  attrs: {
                    type: "number",
                    solo: "",
                    outlined: "",
                    dense: "",
                    rules: _vm.mandatoryRule,
                  },
                  model: {
                    value: _vm.step2Data.property_sq_footage,
                    callback: function ($$v) {
                      _vm.$set(_vm.step2Data, "property_sq_footage", $$v)
                    },
                    expression: "step2Data.property_sq_footage",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
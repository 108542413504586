var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-stepper",
        {
          model: {
            value: _vm.currentStep,
            callback: function ($$v) {
              _vm.currentStep = $$v
            },
            expression: "currentStep",
          },
        },
        [
          _c(
            "v-stepper-items",
            [
              _c(
                "v-form",
                { ref: "counterOfferForm" },
                [
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "2" } },
                    [
                      _c("counter-offer-step2", {
                        attrs: {
                          step2Obj: _vm.step2Obj,
                          counter_offer_details: _vm.counter_offer_details,
                        },
                        on: {
                          "update:step2Obj": function ($event) {
                            _vm.step2Obj = $event
                          },
                          "update:step2-obj": function ($event) {
                            _vm.step2Obj = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex justify-content-end gap-3" }, [
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "btn btn-white pt-2",
                  on: {
                    click: function ($event) {
                      return _vm.goToBackStep()
                    },
                  },
                },
                [_c("b", [_vm._v(_vm._s(_vm.backButtonLabel))])]
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function ($event) {
                      return _vm.goToNextStep()
                    },
                  },
                },
                [
                  _c("span", { staticClass: "fw-semi-bold" }, [
                    _vm._v(_vm._s(_vm.nextButtonLabel)),
                  ]),
                ]
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
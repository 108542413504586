<template>
  <v-form ref="step5form">
    <div class="loss-slider-container">
      <div><label class="pb-1">{{$t('lossverificationStep5Question')}}</label> </div>
      <div><small class="pb-1"><i class="fal fa-info-circle"></i>{{$t('lossverificationStep5ToolTip')}}</small> </div>
      <v-slider v-model="step5Data.personalDamageLevel" class="loss-slider" :tick-labels="ticksLabels" :max="4" step="1" ticks="always" tick-size="5">
         <template v-slot:thumb-label="props">
          <div class="loss-tooltip">
            <span>{{getTooltipTitle(props.value)}}</span>
            <div>{{getTooltipText(props.value)}}</div>
          </div>
        </template>
      </v-slider>
    </div>
  </v-form>
</template>

<script>

export default {

  mixins: [],
  components: {
  },
  props: {
     step5Obj: Object,
  },
  data() {
    return {
      step5Data:{
        personalDamageLevel: ''
      },
      ticksLabels: [this.$t('none'),this.$t('low'), this.$t('medium'), this.$t('high'), this.$t('destroyed')],
      tooltipData: [
        {
          title: this.$t('noDamage'),
          text: this.$t('lossverificationStep5OptionNone')
        },
        {
          title: this.$t('lowLevelDamage'),
          text: this.$t('lossverificationStep5Option1')
        },
        {
          title: this.$t('mediumLevelDamage'),
          text: this.$t('lossverificationStep5Option2')
        },
        {
          title: this.$t('highLevelDamage'),
          text: this.$t('lossverificationStep5Option3')
        },
        {
          title: this.$t('destroyedLevelDamage'),
          text: this.$t('lossverificationStep5Option4')
        }
      ]
    };
  },
  methods: {
    getTooltipTitle(val) {
      return this.tooltipData[val].title;
    },
    getTooltipText(val) {
      return this.tooltipData[val].text;
    }
  },
  watch: {
    step5Data: {
      handler() {
        this.$emit('update:step5Obj', this.step5Data);
      },
      deep: true,
    },
  },
};
</script>


export default {
  data() {
    return {
      mandatoryVHtml: [
        (v) => {
          if (typeof v === 'string' && v) v = v.trim();
          return (!this.checkEmpty(v) && v !== '<p></p>') || 'required field';
        },
      ],
      mandatory: [
        (v) => {
          if (typeof v === 'string' && v) v = v.trim();
          return !this.checkEmpty(v) || 'required field';
        },
      ],
      accountNumberVal: [
        (v) => {
          if (this.checkEmpty(v)) return true;
          if (typeof v === 'string' && v) v = v.trim();
          if (v.length < 2) return 'Should have at least 2 digits';
          if (v.length > 17) return 'Should not exceed 17 digits';
          return true;
        },
      ],
      routingNumberVal: [
        (v) => {
          if (this.checkEmpty(v)) return true;
          if (typeof v === 'string' && v) v = v.trim();
          if (v.length !== 9) return 'Requires exactly 9 digits';
          return true;
        },
      ],
      phoneFormatRule: [
        (value) => {
          if (value && value !== '') {
            return /^\([0-9]{3}\)[ ][0-9]{3}-[0-9]{4}$/.test(value) || 'invalid phone number format';
          }
          return true;
        },
      ],
      emailRules: [
        (v) =>
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v,
          ) || 'Invalid Email Address Format',
        (v) => {
          if (!v) return true;
          const splitV = v.split('@');
          return (splitV[0] && splitV[0].length <= 64) || 'Invalid Email Address Format';
        },
      ],
    };
  },
  methods: {
    checkEmpty(value) {
      if (value === undefined || value === null || value === '') return true;
      if (Array.isArray(value)) {
        return !value.length;
      }
      if (typeof value === 'object') {
        return !Object.keys(value).length;
      }
      return false;
    },
  },
};
